import React, { useMemo } from 'react'
import {
  Form, Row, Col, DatePicker,
} from 'antd'
import moment from 'moment'

const TimeConfig = ({ value, onChange, disabled }) => {
  const makeMoment = (time) => time?.length === 10 ? moment(time, 'MM/DD/YYYY') : moment(time)

  const timeSale = useMemo(() => ([makeMoment(value?.open), makeMoment(value?.close)]), [value])
  const timeInvest = useMemo(() => ([makeMoment(value?.close), makeMoment(value?.close).add(value?.invest_duration || 0, 'milliseconds')]), [value])

  const onChangeTimeSale = ([start, end]) => {
    const config = {
      ...value,
      open: start.format('MM/DD/YYYY'),
      close: end.format('MM/DD/YYYY'),
    }
    onChange(config)
  }
  const onChangeTimeInvest = ([start, end]) => {
    const config = {
      ...value,
      invest_duration: end.diff(start),
      close: start.format('MM/DD/YYYY'),
    }
    if (timeSale[1].isAfter(start)) config.open = start.format('MM/DD/YYYY')
    onChange(config)
  }

  return (
    <Row gutter={32}>
      <Col span={12}>
        <div className="m-b-5">
          <span style={{ color: 'red' }}>*</span>
          {' '}
          Thời gian mở bán cổ phần
        </div>
        <DatePicker.RangePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={disabled}
          value={timeSale}
          onChange={onChangeTimeSale}
        />
      </Col>
      <Col span={12}>
        <div className="m-b-5">
          <span style={{ color: 'red' }}>*</span>
          {' '}
          Thời gian đầu tư
        </div>
        <DatePicker.RangePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={disabled}
          value={timeInvest}
          onChange={onChangeTimeInvest}
        />
      </Col>
    </Row>
  )
}

export default TimeConfig
