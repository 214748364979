/* eslint-disable camelcase */
import axios from 'axios'
import moment from 'moment'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (param) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.PROJECT_GET_LIST, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const search = async (param) => {
  const { KEYS } = storage
  const resp = await axios.post(ENDPOINT.PROJECT_SEARCH, param, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const getDetail = async (projectId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.PROJECT_DETAIL, {
    params: {
      project_id: projectId,
    },
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const createProject = async (project) => {
  const { KEYS } = storage
  const resp = await axios.post(ENDPOINT.PROJECT_CREATE, project, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data?.data
}

const updateAvatar = async (options, queryParams) => {
  const {
    onSuccess, onError, file, onProgress,
  } = options
  const { KEYS } = storage
  const fmData = new FormData()
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      lang: 'vn',
    },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 })
    },
  }
  fmData.append('file', file)
  fmData.append('project_id', projectId)

  try {
    const resp = await axios.put(ENDPOINT.PROJECT_UPDATE_AVATAR, fmData, config)
    onSuccess(resp)
  } catch (err) {
    onError({ err })
  }
}

const updateDoc = async (projectNo, options) => {
  const {
    onSuccess, onError, file, onProgress,
  } = options
  const { KEYS } = storage
  const fmData = new FormData()
  const config = {
    params: {
      no: projectNo,
      type: 'legal-doc',
      name: `${moment().format('YYYMMDDHHmmss')}${options.file?.name}`,
    },
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      lang: 'vn',
    },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 })
    },
  }
  fmData.append('file', file)

  try {
    const resp = await axios.post(ENDPOINT.PROJECT_UPDATE_DOC, fmData, config)
    onSuccess(resp)
  } catch (err) {
    onError({ err })
  }
}

const editProject = async ({
  stock_info, no, classification, type, ...project
}) => {
  const { KEYS } = storage
  await axios.put(ENDPOINT.PROJECT_EDIT_OVERVIEW, project, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  delete stock_info.ido_price
  const resp = await axios.put(ENDPOINT.PROJECT_EDIT_STOCK_INFO, {
    ...stock_info,
    project_id: project.project_id,
  }, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const deleteProject = async (projectId) => {
  const { KEYS } = storage
  const resp = await axios.delete(ENDPOINT.PROJECT_DELETE, {
    data: {
      project_id: projectId,
    },
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const publishProject = async (projectId) => {
  const { KEYS } = storage
  const resp = await axios.post(ENDPOINT.PROJECT_DEPLOY, {
    project_id: projectId,
  }, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data?.data
}

const distributeProject = async (data) => {
  const { KEYS } = storage
  const resp = await axios.put(ENDPOINT.DISTRIBUTE_PROJECT, data, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data?.data
}
export default {
  getList,
  getDetail,
  search,
  createProject,
  updateAvatar,
  updateDoc,
  editProject,
  deleteProject,
  publishProject,
  distributeProject,
}
