import { useMutation, useQuery } from 'react-query'
import { Modal } from 'antd'
import projectApi from '../provider/project'

export const useProjectList = (params = { page: 1, limit: 20 }) => useQuery({
  queryKey: ['getProjectList', params.search, params.page, params.limit, params.keywords, params.type, params.status],
  queryFn: () => (!!params.keywords || !!params.type || !!params.status)
    ? projectApi.search({
      page: params.page,
      limit: params.limit,
      ...(params.keywords ? { key_search: params.keywords } : {}),
      ...(params.type ? { classification: params.type } : {}),
      ...(params.status ? { status: params.status } : {}),
    })
    : projectApi.getList({
      page: params.page,
      limit: params.limit,
    }),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (data) => data?.data,
})

export const useProjectDetail = (projectId, onError = () => {}) => useQuery({
  queryKey: ['getProjectDetail', projectId],
  queryFn: () => projectApi.getDetail(projectId),
  enabled: !!projectId,
  initialData: {},
  select: (data) => data?.data,
  onError: (e) => {
    Modal.error({
      title: 'Không tìm thấy dự án',
      content: e?.message,
      onOk: onError,
    })
  },
})

export const useCreateProject = () => useMutation({
  mutationFn: projectApi.createProject,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không tạo được dự án',
      content: e?.message,
    })
  },
})

export const useEditProject = () => useMutation({
  mutationFn: projectApi.editProject,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không sửa được dự án',
      content: e?.message,
    })
  },
})

export const useDeleteProject = () => useMutation({
  mutationFn: projectApi.deleteProject,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không tạo được dự án',
      content: e?.message,
    })
  },
})

export const usePublishProject = () => useMutation({
  mutationFn: projectApi.publishProject,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không đăng được dự án',
      content: e?.message,
    })
  },
})

export const useDistributeProject = () => useMutation({
  mutationFn: projectApi.distributeProject,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không chia lãi được cho NĐT',
      content: e?.message,
    })
  },
})
