import { useMutation, useQuery } from 'react-query'
import { Modal } from 'antd'
import votingApi from '../provider/voting'

// export const useVotingList = (params = { page: 1, limit: 10 }) => useQuery({
//   queryKey: ['getVotingList', params.page, params.limit],
//   queryFn: () => votingApi.getList(params),
//   enabled: !!params.page && !!params.limit,
//   initialData: [],
//   select: (data) => data?.data,
// })

export const useVotingList = ({ page, limit, symbol }) => useQuery({
  queryKey: ['getVotingList', page, limit, symbol],
  queryFn: () => votingApi.getList(page, limit, symbol),
  enabled: !!page && !!limit,
  initialData: [],
  select: (data) => data?.data,
})

export const useVotingDetail = (votingId, onError = () => {}) => useQuery({
  queryKey: ['getProjectDetail', votingId],
  queryFn: () => votingApi.getDetailVoting(votingId),
  enabled: !!votingId,
  initialData: {},
  select: (data) => data?.data,
  onError: (e) => {
    Modal.error({
      title: 'Không tìm thấy dự án',
      content: e?.message,
      onOk: onError,
    })
  },
})

export const useCreateVoting = () => useMutation({
  mutationFn: votingApi.createVoting,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không tạo được bài vote',
      content: e?.message,
    })
  },
})

export const useCancelVoting = () => useMutation({
  mutationFn: votingApi.cancelVoting,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không hủy được bài vote',
      content: e?.message,
    })
  },
})
