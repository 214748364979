/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_LIST_KYC, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getKycDetail = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_DETAIL_KYC, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getAddress = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_ADDRESS, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const updateInfo = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.UPDATE_INFO, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const uploadDoc = async (param, file) => {
  const { KEYS } = storage
  const formData = new FormData()
  console.log(file.originFileObj)
  formData.append('file', file.originFileObj)
  console.log(formData)
  try {
    const resp = await axios.post(`${ENDPOINT.UPLOAD_DOC}`, formData, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const confirm = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(`${ENDPOINT.KYC_CONFIRM}`, data, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const reject = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(`${ENDPOINT.KYC_REJECT}`, data, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getKycDetail,
  getList,
  updateInfo,
  uploadDoc,
  getAddress,
  confirm,
  reject,
}
