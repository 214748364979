import * as React from 'react'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import { Menu, Input, Avatar } from 'antd'
import logo from '../static/images/logo.png'
import avatar from '../static/images/flat-avatar.png'
import { signout } from '../provider/user'

const { SubMenu } = Menu

const { Search } = Input

const HeaderDiv = () => {
  const onProfileItemClick = ({ key }) => {
    switch (key) {
      case 'logout':
        signout()
        return null
      case 'profile-view':
      default:
        return null
    }
  }

  return (
    <Menu
      mode="horizontal"
      theme="light"
      className="d-flex align-items-center custom-navigation"
    >
      <Menu.Item key="brand-logo" className="brand-logo">
        <Link to="/dashboard">
          <img src={logo} />
        </Link>
      </Menu.Item>
      <SubMenu
        key="profile"
        title={(
          <span>
            <Avatar size={24} src={avatar} />
            <span> Profile</span>
          </span>
          )}
        className="ml-auto"
        onClick={onProfileItemClick}
      >
        <Menu.Item key="profile-view" style={{ pointerEvents: 'auto' }}>
          <Link to="/profile">Profile</Link>
        </Menu.Item>
        <Menu.Item key="logout" style={{ pointerEvents: 'auto' }}>Logout</Menu.Item>
      </SubMenu>

    </Menu>
  )
}

export default HeaderDiv
