export const TRANSACTION_P2P_STATUS = {
  C: 'C',
  PC: 'PC',
  F: 'F',
}

export const TRANSACTION_SWAP_STATUS = {
  FF: 'FF', // hoàn thành
  W: 'W', // đang xử lý, chua day len contract -> chỉ show cho admin
  P: 'P', // đang mở bán -> da dua len contract
  PC: 'PC', // đang mở bán -> da dua len contract
  FN: 'FN', // kết thúc mở bán
  RL: 'RL', // release sau khi het lock time, dang duoc chao ban
  SD: 'SD', // da ban
  DT: 'DT', // da phan phoi cho user lai
  F: 'F', // tạo dự án lỗi
}

export const TRANSACTION_IDO_STATUS = {
  FF: 'FF', // hoàn thành
  W: 'W', // đang xử lý, chua day len contract -> chỉ show cho admin
  P: 'P', // đang mở bán -> da dua len contract
  PC: 'PC', // đang mở bán -> da dua len contract
  FN: 'FN', // kết thúc mở bán
  RL: 'RL', // release sau khi het lock time, dang duoc chao ban
  SD: 'SD', // da ban
  DT: 'DT', // da phan phoi cho user lai
  F: 'F', // tạo dự án lỗi
}

export const TRANSACTION_P2P_STATUS_NAMES = {
  [TRANSACTION_P2P_STATUS.C]: 'Hoạt động',
  [TRANSACTION_P2P_STATUS.PC]: 'Không hoạt động',
  [TRANSACTION_P2P_STATUS.F]: 'Hủy',
}

export const TRANSACTION_IDO_STATUS_NAMES = {
  [TRANSACTION_IDO_STATUS.FF]: 'Hoàn thành',
  [TRANSACTION_IDO_STATUS.W]: 'Đang xử lý',
  [TRANSACTION_IDO_STATUS.P]: 'Đang mở bán',
  [TRANSACTION_IDO_STATUS.PC]: 'Đang mở bán',
  [TRANSACTION_IDO_STATUS.RL]: 'Kết thúc mở bán',
  [TRANSACTION_IDO_STATUS.FN]: 'Hết lock time',
  [TRANSACTION_IDO_STATUS.SD]: 'Đã bán',
  [TRANSACTION_IDO_STATUS.DT]: 'Đã phân phối lại cho user',
  [TRANSACTION_IDO_STATUS.F]: 'Hủy',
}

export const TRANSACTION_SWAP_STATUS_NAMES = {
  [TRANSACTION_SWAP_STATUS.FF]: 'Hoàn thành',
  [TRANSACTION_SWAP_STATUS.W]: 'Đang xử lý',
  [TRANSACTION_SWAP_STATUS.P]: 'Đang mở bán',
  [TRANSACTION_SWAP_STATUS.PC]: 'Đang mở bán',
  [TRANSACTION_SWAP_STATUS.RL]: 'Kết thúc mở bán',
  [TRANSACTION_SWAP_STATUS.FN]: 'Hết lock time',
  [TRANSACTION_SWAP_STATUS.SD]: 'Đã bán',
  [TRANSACTION_SWAP_STATUS.DT]: 'Đã phân phối lại cho user',
  [TRANSACTION_SWAP_STATUS.F]: 'Hủy',
}

export const TRANSACTION_P2P_STATUS_COLORS = {
  [TRANSACTION_IDO_STATUS.W]: 'pending',
  [TRANSACTION_IDO_STATUS.P]: 'success',
  [TRANSACTION_IDO_STATUS.PC]: 'success',
  [TRANSACTION_IDO_STATUS.RL]: 'fail',
  [TRANSACTION_IDO_STATUS.FN]: 'fail',
  [TRANSACTION_IDO_STATUS.SD]: 'fail',
  [TRANSACTION_IDO_STATUS.DT]: 'fail',
  [TRANSACTION_IDO_STATUS.F]: 'fail',
  [TRANSACTION_IDO_STATUS.FF]: 'success',
}

export const TRANSACTION_IDO_STATUS_COLORS = {
  [TRANSACTION_IDO_STATUS.W]: 'pending',
  [TRANSACTION_IDO_STATUS.P]: 'success',
  [TRANSACTION_IDO_STATUS.PC]: 'success',
  [TRANSACTION_IDO_STATUS.RL]: 'fail',
  [TRANSACTION_IDO_STATUS.FN]: 'fail',
  [TRANSACTION_IDO_STATUS.SD]: 'fail',
  [TRANSACTION_IDO_STATUS.DT]: 'fail',
  [TRANSACTION_IDO_STATUS.F]: 'fail',
  [TRANSACTION_IDO_STATUS.FF]: 'success',
}

export const TRANSACTION_SWAP_STATUS_COLORS = {
  [TRANSACTION_SWAP_STATUS.W]: 'pending',
  [TRANSACTION_SWAP_STATUS.P]: 'success',
  [TRANSACTION_SWAP_STATUS.PC]: 'success',
  [TRANSACTION_SWAP_STATUS.RL]: 'fail',
  [TRANSACTION_SWAP_STATUS.FN]: 'fail',
  [TRANSACTION_SWAP_STATUS.SD]: 'fail',
  [TRANSACTION_SWAP_STATUS.DT]: 'fail',
  [TRANSACTION_SWAP_STATUS.F]: 'fail',
  [TRANSACTION_SWAP_STATUS.FF]: 'success',
}
