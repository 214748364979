/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Breadcrumb,
  PageHeader,
  Tabs,
  Radio,
  Table,
  Tag,
  Pagination,
  Select,
} from 'antd'
import {
  EyeOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  Route, Link, BrowserRouter as Router, useHistory,
} from 'react-router-dom'
import moment from 'moment'
import Layouts from '../../components/Layouts'
import './index.css'
import Eye from '../../static/svg/eye.svg'
import ArrowLeft from '../../static/svg/arrow-left.svg'
import ArrowRight from '../../static/svg/arrow-right.svg'
import kyc from '../../provider/kyc'

const { Option } = Select
const { Search } = Input

const TransactionHistory = () => {
  const history = useHistory()

  const gotoDetail = (user) => {
    console.log('user', user)
    history.push(`/kyc/${user}`)
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (id) => (
        <div>
          <span className="text-row">
            {id}
          </span>
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'user',
      render: (user) => (
        <div>
          <span className="text-row">
            {user?.username}
          </span>
        </div>
      ),
    },
    {
      title: 'Địa chỉ email',
      dataIndex: 'user',
      render: (user) => (
        <div>
          <span className="text-row">
            {user?.email}
          </span>
        </div>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      render: (user) => (
        <span className="text-row">
          {user?.phone || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <div>
          <span className="text-row">
            {moment(createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      ),
      sorter: {
        compare: (a, b) => a.time - b.time,
        multiple: 1,
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (text) => text === 'C' ? (
        <div className="text-right">
          <span className="success">
            Đã xác minh
          </span>
        </div>
      ) : text === 'RJ' ? (
        <div className="text-right">
          <span className="fail">
            Đã từ chối
          </span>
        </div>

      ) : text === 'PC' ? (
        <div className="text-right">
          <span className="waiting">
            Chờ phê duyệt
          </span>
        </div>
      ) : text === 'P' ? (
        <div className="text-right">
          <span className="pending">
            Pending
          </span>
        </div>
      ) : (
        { text }
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'user',
      render: (user) => (
        // <img src={Eye} className="icon-button" />
        <Button shape="circle" icon={<EyeOutlined />} onClick={() => gotoDetail(user._id)} />
      ),
    },
  ]

  const [data, setData] = useState([])
  const [statusSelected, setStatusSelected] = useState(null)
  const [total, setTotal] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const getData = async (current, pageSize, status = '', search = '') => {
    setLoading(true)
    try {
      const resp = await kyc.getList({
        page: current, limit: pageSize, status, search,
      })
      setLoading(false)
      setData(resp.data?.kycs)
      setTotal(resp.data?.total)
      setCurrentPage(current)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getData(1, 10)
    return () => {
    }
  }, [])

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="pagination-link">
          <img src={ArrowLeft} />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="pagination-link">
          <img src={ArrowRight} />
        </div>
      )
    }
    return originalElement
  }

  const onSelectStatus = (value) => {
    setStatusSelected(value)
    getData(1, 10, value)
    console.log(value)
    return value
  }

  const onSearch = (value) => {
    getData(1, 10, statusSelected, value)
    console.log(1, 10, statusSelected, value)
  }
  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý KYC
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Search placeholder="Vui lòng nhập từ khóa" onSearch={onSearch} className="mr-5" />
        <Select
          defaultValue=""
          optionFilterProp="children"
          onSelect={(value) => onSelectStatus(value)}
          style={{ width: 300 }}
        >
          <Option value="">Tất cả</Option>
          <Option value="PC">Chờ phê duyệt</Option>
          <Option value="C">Đã xác minh</Option>
          <Option value="RJ">Đã từ chối</Option>
          <Option value="P">Pending</Option>

        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
      />
      <Row className="d-flex justify-content-center align-items-center">
        <Pagination
          showSizeChanger={false}
          onChange={getData}
          defaultCurrent={currentPage}
          total={total}
          itemRender={itemRender}
          className="mt-4 pt-3 pagination"
        />
      </Row>
    </>
  )
}

export default TransactionHistory
