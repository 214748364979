import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import {
  Layout, Menu, Icon, Divider, Avatar, Typography,
} from 'antd'
import {
  MailOutlined,
  CloseCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  GoogleOutlined,
  WalletOutlined,
  HistoryOutlined,
  OrderedListOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import userAvatar from '../static/images/flat-avatar.png'
import HeaderDiv from './HeaderDiv'

const { SubMenu } = Menu
const { Header, Sider, Content } = Layout

const WrapperMenu = styled(Menu).attrs({
  className: 'layout-menu-content',
})`
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 20px;
  font-weight: 500;
`
const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`
const WrapperContent = styled(Content)`
  padding: 24px;
  min-height: 100vh;
`
const WrapperUserContent = styled.div`
  padding: 0 16px;
`
const VerifyItem = styled.div`
  display: flex;
  align-items: center;
  color: #d94046;
  font-weight: 400;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`
const UserEmailItem = styled.div`
  display: flex;
  align-items: center;
  color: #838383;
  font-weight: 400;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const Layouts = ({ children, active, classname }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState('')

  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4', 'sub5', 'sub3', 'sub6']

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const onOpenChange = (openKeysData) => {
    const latestOpenKey = openKeysData.find(
      (key) => openKeys.indexOf(key) === -1,
    )
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysData)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  return (
    <Layout className={`${classname}`}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ background: '#EAEEF9', width: 500 }}
        className="sidebar-left"
        onCollapse={toggle}
      >
        <WrapperMenu
          defaultSelectedKeys={[active]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <WrapperUserContent>
            <Avatar size={78} src={userAvatar} className="mb-3" />
            <h4>Hoang Tay</h4>
            <UserEmailItem>
              <MailOutlined />
              <span> tay.nguyen@gmail.com </span>
            </UserEmailItem>
          </WrapperUserContent>
          <Divider />
          <Menu.Item key="1">
            <Link to="/dashboard">
              <MenuItemContent>
                <DashboardOutlined />
                <span> Dashboard </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/personal-info">
              <MenuItemContent>
                <UserOutlined />
                <span> Thông tin cá nhân </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/kyc">
              <MenuItemContent>
                <CheckCircleOutlined />
                <span>Xác minh tài khoản</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/profile">
              <MenuItemContent>
                <GoogleOutlined />
                <span>Google Authenticator</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Divider />
          <Menu.Item key="10">
            <Link to="/table">
              <MenuItemContent>
                <WalletOutlined />
                <span>Ví tài khoản</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="11">
            <Link to="/history">
              <MenuItemContent>
                <HistoryOutlined />
                <span>Lịch sử giao dịch</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/docs">
              <MenuItemContent>
                <OrderedListOutlined />
                <span>Sản phẩm đang đầu tư</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>

        </WrapperMenu>
        {/* <Icon
          className="trigger"
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggle}
        /> */}
      </Sider>
      <Layout>
        <Header className="headerTop">
          <HeaderDiv />
        </Header>
        <WrapperContent
          className={collapsed ? 'collapsed mainContent ' : 'mainContent'}
        >
          {children}
        </WrapperContent>
      </Layout>
    </Layout>
  )
}

Layouts.propTypes = {
  children: PropTypes.shape(),
  active: PropTypes.bool,
  classname: PropTypes.shape(),
}
export default Layouts
