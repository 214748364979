/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import {
  Row, Col, Input, Select, Button, Table, Image, Modal, Avatar, Typography, Form,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import { useDeleteProject, useProjectList, useEditProject } from 'hooks/project'
import { PROJECT_STATUS, PROJECT_STATUS_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { EyeOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Duration } from 'luxon'
import styled from 'styled-components'
import { addToQueryString, fromQueryString } from '../../utils/url'
import noImage from '../../static/images/no-image.png'

const { Title } = Typography

const STATUS_COLORS = {
  [PROJECT_STATUS.PC]: 'success',
  [PROJECT_STATUS.P]: 'pending',
  [PROJECT_STATUS.FN]: 'fail',
  [PROJECT_STATUS.CL]: 'waiting',
  [PROJECT_STATUS.RF]: 'fail',
  [PROJECT_STATUS.F]: 'fail',
}

const RequestContactList = () => {
  const [form] = Form.useForm()
  const [defaultValuesStep1, setDefaultValuesStep1] = useState({
    name: '',
    birthday: '',
    email: '',
  })
  const [modalRequestContactDetail, setModalRequestContactDetail] = useState(false)
  const [infoDetailContactRequest, setInfoDetailRequestContact] = useState({})
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()

  const { data, isFetching, refetch } = useProjectList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    keywords: parsedQueryString.keywords || '',
    status: parsedQueryString.status || '',
  })

  const mutationEdit = useEditProject()
  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
    ...{ project_id: infoDetailContactRequest?._id },
  })

  const onSubmit = (project) => {
    const mapData = mapSubmitData(project)
    console.log(mapData)
    mutationEdit.mutate(mapData, {
      onSuccess: (p) => {
        Modal.success({
          content: 'Sửa dự án thành công',
          onOk: () => history.go(0),
        })
      },
    })
  }

  const mutationDelete = useDeleteProject()

  const onSearch = (keywords) => {
    history.replace({
      search: addToQueryString(location.search, { keywords }),
    })
  }

  const onChangeStatus = (status) => {
    history.replace({
      status: addToQueryString(location.status, { status }),
    })
  }

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const columns = [
    {
      title: 'ID request',
      dataIndex: '_id',
      render: (id) => (
        <div>
          <span className="text-row">
            1
          </span>
        </div>
      ),
    },
    {
      title: 'ID NĐT',
      dataIndex: 'no',
      render: (id) => (
        <div>
          <span className="text-row">
            1
          </span>
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'classification',
      render: (id) => (
        <div>
          <span className="text-row">
            {PROJECT_TYPE_NAMES[id] || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: ['project_info', 'address'],
      render: (address) => (
        <span className="text-row">
          {[address?.line1, address?.line2, address?.district, address?.city].filter((s) => !!s).join(', ') || '-'}
        </span>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: ['stock_info'],
      render: (stock) => (
        <span className="text-row">
          {stock ? stock?.ido_price * stock?.total_supply / 1000000000 || '-' : '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: ['time_config', 'invest_duration'],
      render: (id) => (
        <div>
          <span className="text-row">
            {Duration.fromMillis(id || 0).toFormat('M', {
              floor: false,
            })}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (id) => (
        <div>
          <span className={`text-row ${STATUS_COLORS[id]}`}>
            {PROJECT_STATUS_NAMES[id] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      render: (row) => (
        <>
          {/* <Button icon={<EyeOutlined />} type="text" onClick={() => onView(id)} /> */}
          <Button icon={<EyeOutlined />} type="text" onClick={() => goToDetailRequestContact(row)} />
          {/* <Button icon={<DeleteFilled />} type="text" onClick={() => onDelete(id)} /> */}
        </>
      ),
    },
  ]

  const goToDetailRequestContact = (currentData) => {
    console.log(currentData)
    setInfoDetailRequestContact(currentData)
    setModalRequestContactDetail(true)
  }

  return (
    <>
      <Modal
        footer={null}
        visible={modalRequestContactDetail}
        onOk={() => setModalRequestContactDetail(false)}
        onCancel={() => setModalRequestContactDetail(false)}
      >
        <>
          <Title level={3} className="font-weight-500 mb-3">
            Chi tiết giao dịch
          </Title>
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
            initialValues={infoDetailContactRequest}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="ID Request:"
              name="_id"
              rules={[{ required: true, message: 'Please input your id request!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="ID NĐT:"
              name="idInvestor"
              rules={[{ required: true, message: 'Please input your id investor!' }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Tên đăng nhập:"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Email:"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Số điện thoại:"
              name="phone"
              rules={[{ required: true, message: 'Please input your phone!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Địa chỉ:"
              name="['project_info', 'address']"
              rules={[{ required: true, message: 'Please input your address!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Nội dung:"
              name="content"
              rules={[{ required: true, message: 'Please input your content!' }]}
            >
              <Input.TextArea showCount maxLength={100} />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Thời gian:"
              name="time"
              rules={[{ required: true, message: 'Please input your time!' }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ flexDirection: 'unset' }}
              label="Trạng thái:"
              name="status"
              rules={[{ required: true, message: 'Please input your status!' }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="mr-2">
                Lưu thay đổi
              </Button>
              <Button
          // type="primary"
                // htmlType="submit"
                // className="btn-block"
                // size="large"
                onClick={() => setModalRequestContactDetail(false)}
              >
                Đóng
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý yêu cầu liên hệ
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5" onSearch={onSearch} />
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(PROJECT_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{PROJECT_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data?.projects || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: data?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetching || mutationDelete.isLoading}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default RequestContactList
