import React, { useEffect, useState } from 'react'

import {
  useHistory, Route, Link, BrowserRouter as Router,
} from 'react-router-dom'

import {
  Menu, Input, Avatar, Layout, Button,
} from 'antd'
import logo from '../../../static/images/logo.png'
import './index.css'

const { SubMenu } = Menu

const { Search } = Input

const Header = () => {
  const history = useHistory()
  useEffect(() => {
  }, [])

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')

  return (
    <Menu
      mode="horizontal"
      className="d-flex align-items-center custom-navigation menu-bre"
    >

      <Menu.Item key="brand-logo" className="brand-logo-bre">
        {/* <Link to="/dashboard"> */}
        <img src={logo} className="m-r-5" />
        {/* </Link> */}
      </Menu.Item>

      <Menu className="auto" mode="horizontal">
        <Menu.Item className="menu-item" key="about">
          Về BRE
        </Menu.Item>

        <Menu.Item key="gp" className="menu-item">
          Giải Pháp
        </Menu.Item>

        <SubMenu
          title={(
            <span className="submenu-title-wrapper">
              Sản Phẩm
            </span>
          )}
          className="menu-item"
        >
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="setting:1">Option 1</Menu.Item>
            <Menu.Item key="setting:2">Option 2</Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Item 2">
            <Menu.Item key="setting:3">Option 3</Menu.Item>
            <Menu.Item key="setting:4">Option 4</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item key="tt" className="menu-item">
          Tin Tức
        </Menu.Item>

        <Menu.Item className="menu-item">
          <Button type="primary" onClick={() => history.push('/sign-up')}>Đăng Ký</Button>
        </Menu.Item>

        <Menu.Item className="menu-item">
          <Button onClick={() => history.push('/sign-in')}>Đăng nhập</Button>
        </Menu.Item>

      </Menu>

      {/* <SubMenu
          title={
            <span className="submenu-title-wrapper">
              Language{' '}
            </span>
          }
          className="custom-dropdown language-list"
        >
          <Menu.Item key="setting:1">English</Menu.Item>
          <Menu.Item key="setting:2">Dutch</Menu.Item>
          <Menu.Item key="setting:3">Hindi</Menu.Item>
          <Menu.Item key="setting:4">Urdu</Menu.Item>
        </SubMenu> */}

      {/* <SubMenu
          key="profile"
          title={
            <span>
              <Avatar size={24} src={avatar} />
              <span> Profile</span>
            </span>
          }
          className=""
        >
          <Menu.Item key="profile-view">
            <Link to="/profile">Profile</Link>
          </Menu.Item>
          <Menu.Item key="logout"><Link to="/">Logout</Link></Menu.Item>
        </SubMenu> */}

    </Menu>
  )
}

export default Header
// class HeaderDiv extends React.Component {
//   constructor (props) {
//     super (props);
//     this.state = {
//       current: 'search',
//       rtl: true,
//     };
//     this.sidebarToggle = this.sidebarToggle.bind (this);
//   }
//   sidebarToggle () {
//     this.setState ({
//       rtl: !this.state.rtl,
//     });
//     var body = document.body;
//     body.classList.toggle ('rtl');
//   }

//   handleClick = e => {
//     console.log ('click ', e);
//     this.setState ({
//       current: e.key,
//     });
//   };

//   render () {
//     const classBox = `primaryBg box`;

//     return (

//     );
//   }
// }
// export default HeaderDiv;
