import qs from 'query-string'

export const fromQueryString = (queryString, opts) => qs.parse(queryString, {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
  ...opts,
})

export const toQueryString = (
  obj,
  opts,
) => qs.stringify(obj, {
  arrayFormat: 'comma',
  ...opts,
})

export const addToQueryString = (
  currentQueryString,
  val,
  stringifyOptions,
  parseOptions,
) => toQueryString(
  {
    ...fromQueryString(currentQueryString, parseOptions),
    ...val,
  },
  stringifyOptions,
)

export const omitFromQueryString = (
  currentQueryString,
  key,
) => {
  const parsed = fromQueryString(currentQueryString)
  if (typeof key === 'string') {
    delete parsed[key]
  } else {
    key.forEach((k) => {
      delete parsed[k]
    })
  }

  return toQueryString(parsed)
}

export const cleanDeep = (
  object,
  options,
) => {
  const {
    cleanKeys = [],
    cleanValues = [],
    emptyArrays = true,
    emptyObjects = true,
    emptyString = true,
    NaNValues = false,
    nullValues = true,
    undefinedValues = true,
  } = options
  const keys = Object.keys(object)
  keys.forEach((item) => {
    if (undefinedValues && isUndefined(object[item])) {
      delete object[item]
    }
    if (emptyArrays && isArray(object[item]) && object[item]?.length === 0) {
      delete object[item]
    }
    if (emptyString && object[item] === '') {
      delete object[item]
    }
    if (NaNValues && Number.isNaN(object[item])) {
      delete object[item]
    }
    if (nullValues && object[item] === null) {
      delete object[item]
    }
    if (cleanKeys?.includes(item)) {
      delete object[item]
    }
    if (cleanValues?.includes(object[item])) {
      delete object[item]
    }
    if (isObject(object[item]) && !isArray(object[item])) {
      if (emptyObjects && Object.keys(object[item]).length === 0) {
        delete object[item]
      } else {
        return cleanDeep(object[item], options)
      }
    }
    return object
  })
}
