import { LOCALES } from './locales'

export const messages = {
  [LOCALES.ENGLISH]: {
    signin_title: 'Sign In',
  },
  [LOCALES.VIETNAMESE]: {
    signin_title: 'Đăng nhập',
  },
}
