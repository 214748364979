/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import {
  Row, Col, Input, Select, Button, Table, Image, Modal, Avatar, Typography,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import {
  useVotingList, useVotingDetail, useCancelVoting,
} from 'hooks/voting'
import {
  useRankList,
} from 'hooks/rank'
import { PROJECT_STATUS, PROJECT_STATUS_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { RANKINGTYPE } from 'constants/ranking'
import { EyeOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Duration } from 'luxon'
import styled from 'styled-components'
import moment from 'moment'
import { addToQueryString, fromQueryString } from '../../utils/url'
import noImage from '../../static/images/no-image.png'

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`
const Voting = styled.span`
  color: rgb(229, 57, 53);
  background-color: rgba(38, 50, 56, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.1);
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 400;
  word-break: break-word;
`

const { Title } = Typography

const STATUS_COLORS = {
  [PROJECT_STATUS.PC]: 'success',
  [PROJECT_STATUS.P]: 'pending',
  [PROJECT_STATUS.FN]: 'fail',
  [PROJECT_STATUS.CL]: 'waiting',
  [PROJECT_STATUS.RF]: 'fail',
  [PROJECT_STATUS.F]: 'fail',
}

const RankList = () => {
  const [modalVotingDetail, setModalVotingDetail] = useState(false)
  const [infoDetailVoting, setInfoDetailVoting] = useState({})
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()
  const mutationCancel = useCancelVoting()
  const { data, isFetching } = useRankList({
    page: parsedQueryString.page || 1,
    limit: parsedQueryString.limit || 10,
    // keywords: parsedQueryString.keywords || '',
    // status: parsedQueryString.status || '',
  })

  const onSearch = (keywords) => {
    history.replace({
      search: addToQueryString(location.search, { keywords }),
    })
  }

  const onChangeStatus = (status) => {
    history.replace({
      status: addToQueryString(location.status, { status }),
    })
  }

  const onCreateRanking = () => history.push('/ranking/create')

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  const onCancelVoting = (voteId) => {
    Modal.confirm({
      content: 'Bạn có muốn hủy bài vote này không?',
      onOk: () => mutationCancel.mutate(voteId, {
        onSuccess: () => {
          Modal.success({
            content: 'Hủy bài vote thành công',
            onOk: () => refetch(),
          })
        },
      }),
    })
  }
  const onDetail = (id) => {
    history.push(`/ranking/${id}`)
  }
  const columns = [
    {
      title: 'Thứ tự',
      dataIndex: 'no',
      render: (row) => (
        <div>
          <span className="text-row">
            {row || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Tên hạng',
      dataIndex: 'name',
      render: (name) => (
        <div>
          <span className="text-row">
            {name || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Loại định mức',
      render: (row) => (
        <div>
          <span className="text-row">
            {row.discount.type}
          </span>
        </div>
      ),
    },
    {
      title: 'Mức giá trị',
      render: (row) => (
        <div>
          <span className="text-row">
            {row.discount.value}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${STATUS_COLORS[status]}`}>
            {PROJECT_STATUS_NAMES[status] || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      render: (row) => (
        <>
          <Button icon={<EditOutlined />} type="text" onClick={() => onDetail(row._id)} />
          <Button icon={<DeleteFilled />} type="text" onClick={() => onCancelVoting(row?.index)} />
        </>
      ),
    },
  ]

  const goToDetailVoting = (currentData) => {
    console.log(currentData)
    setInfoDetailVoting(currentData)
    setModalVotingDetail(true)
  }

  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Cơ chế xếp hạng thành viên
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5 col-md-4" onSearch={onSearch} />
        <Select
          placeholder="Loại định mức"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          className="mr-5 col-md-2"
          allowClear
        >
          {Object.keys(RANKINGTYPE).map((p) => (
            <Select.Option value={p}>{RANKINGTYPE[p]}</Select.Option>
          ))}
        </Select>
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          className="mr-5 col-md-2"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(PROJECT_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{PROJECT_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-row mb-4 mt-3">
        <Button
          className="mr-3 button-section-filter"
          type="primary"
          onClick={onCreateRanking}
        >
          <span>Tạo mới hạng</span>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data?.ranks || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: data?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetching}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default RankList
