import { connect } from 'react-redux'
import Signin from '../pages/authentication/signIn'
import {
  get,
  login,
  register,
  resendVerifyEmail,
  verifyUser,
} from '../redux/action/user'
import { getUserData } from '../redux/selector/user'

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  getInfo: () => dispatch(get()),
  verifyOTP: (data, callback) => dispatch(verifyUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendVerifyEmail(data, callback)),
})

export default connect((state) => ({ user: getUserData(state) }), mapDispatchToProps)(Signin)
