import { Button, Space, Input } from 'antd'
import React, { useMemo, useState } from 'react'
import PreviewLink from '../../../components/previewLink'

const Links = ({ value, onChange, disabled }) => {
  const [url, setUrl] = useState('')

  const isURL = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    const regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
    return url && regex.test(url)
  }, [url])

  const addLink = () => {
    if (!isURL) return
    if (!(value || []).includes(url)) { onChange((value || []).concat(url)) }
    setUrl('')
  }

  const onDelete = (l) => {
    onChange((value || []).filter((u) => u !== l))
  }

  return (
    <>
      {(value || []).map((link, idx) => (
        <PreviewLink key={`${link}${idx}`} url={link} onDelete={onDelete} />
      ))}
      {disabled ? '' : (
        <Space direction="horizontal">
          <Input addonBefore="URL" value={url} onChange={(e) => setUrl(e.target.value)} status={url && !isURL ? 'error' : null} style={{ minWidth: 250 }} />
          <Button type="primary" onClick={addLink}>Thêm Link tham khảo</Button>
        </Space>
      )}
      {url && !isURL ? <div className="m-t-2" style={{ color: 'red', fontSize: 12 }}>Link không hợp lệ!</div> : ''}
      <div className="m-b-5" />
    </>
  )
}

Links.defaultProps = {
  disabled: false,
}

export default Links
