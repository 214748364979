export const REFERRAL_INVESTOR_STATUS = {
  C: 'C',
  PC: 'PC',
}

export const REFERRAL_INVESTOR_STATUS_NAMES = {
  [REFERRAL_INVESTOR_STATUS.C]: 'Hoạt động',
  [REFERRAL_INVESTOR_STATUS.PC]: 'Không hoạt động',
}

export const STATUS_REFERRAL_INVESTOR_COLORS = {
  [REFERRAL_INVESTOR_STATUS.C]: 'success',
  [REFERRAL_INVESTOR_STATUS.PC]: 'fail',
}

export const REFERRAL_KYC_STATUS = {
  C: 'C',
  PC: 'PC',
  RJ: 'RJ',
  P: 'P',
}

export const REFERRAL_KYC_STATUS_NAMES = {
  [REFERRAL_KYC_STATUS.C]: 'Đã xác minh',
  [REFERRAL_KYC_STATUS.PC]: 'Chờ phê duyệt',
  [REFERRAL_KYC_STATUS.RJ]: 'Đã từ chối',
  [REFERRAL_KYC_STATUS.P]: 'Pending',
}

export const STATUS_REFERRAL_KYC_INVESTOR_COLORS = {
  [REFERRAL_KYC_STATUS.C]: 'success',
  [REFERRAL_KYC_STATUS.PC]: 'fail',
  [REFERRAL_KYC_STATUS.RJ]: 'fail',
  [REFERRAL_KYC_STATUS.P]: 'fail',
}

export const REFERRAL_STATUS = {
  C: 'C',
  P: 'P',
}

export const REFERRAL_STATUS_NAMES = {
  [REFERRAL_STATUS.C]: 'Đã cộng',
  [REFERRAL_STATUS.P]: 'Đang chờ cộng',
}

export const STATUS_REFERRAL_COLORS = {
  [REFERRAL_STATUS.C]: 'success',
  [REFERRAL_STATUS.P]: 'waiting',
}
