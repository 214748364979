import AppConfig from '../config'
// import qs from 'query-string'

export const LOGIN = `${AppConfig.endpoint.api}/user/login`
export const GET_USER = `${AppConfig.endpoint.api}/kyc/get`

export const REGISTER = `${AppConfig.endpoint.api}/user/register`
export const VERIFY_USER = `${AppConfig.endpoint.api}/user/verify-user`
export const RESEND_VERIFY_EMAIL = `${AppConfig.endpoint.api}/user/resend-verify-email`

// kyc
export const GET_LIST_KYC = `${AppConfig.endpoint.api}/kyc/admin-list`
export const GET_DETAIL_KYC = `${AppConfig.endpoint.api}/kyc/admin-get`
export const KYC_CONFIRM = `${AppConfig.endpoint.api}/kyc/admin-confirm`
export const KYC_REJECT = `${AppConfig.endpoint.api}/kyc/admin-reject`

export const UPDATE_INFO = `${AppConfig.endpoint.api}/kyc/update-info`
export const UPLOAD_DOC = `${AppConfig.endpoint.api}/kyc/upload-doc`
export const GET_ADDRESS = `${AppConfig.endpoint.api}/kyc/address`

// project
export const PROJECT_GET_LIST = `${AppConfig.endpoint.api}/project/admin/get`
export const PROJECT_SEARCH = `${AppConfig.endpoint.api}/project/search`
export const PROJECT_DETAIL = `${AppConfig.endpoint.api}/project/detail`
export const PROJECT_CREATE = `${AppConfig.endpoint.api}/project/admin/create`
export const PROJECT_UPDATE_AVATAR = `${AppConfig.endpoint.api}/project/admin/update-avatar`
export const PROJECT_UPDATE_DOC = `${AppConfig.endpoint.api}/project/admin/upload-doc`
export const PROJECT_EDIT_STOCK_INFO = `${AppConfig.endpoint.api}/project/admin/edit-stock-info`
export const PROJECT_EDIT_OVERVIEW = `${AppConfig.endpoint.api}/project/admin/edit-overview`
export const PROJECT_DELETE = `${AppConfig.endpoint.api}/project/admin/delete-project`
export const PROJECT_DEPLOY = `${AppConfig.endpoint.api}/project/admin/deploy`
export const DISTRIBUTE_PROJECT = `${AppConfig.endpoint.api}/project/admin/distribute-project`

// investor
export const INVESTOR_GET_LIST = `${AppConfig.endpoint.api}/user/admin/list-user`
export const INVESTOR_DETAIL = `${AppConfig.endpoint.api}/user/admin/user-details`

export const INVESTOR_LIST_WALLET = `${AppConfig.endpoint.api}/wallet/get`
export const INVESTOR_LIST_PRODUCT = `${AppConfig.endpoint.api}/wallet/get-stock`
export const INVESTOR_LIST_BANK_ACCOUNT = `${AppConfig.endpoint.api}/wallet/bank-account`
export const INVESTOR_LIST_TRANSACTION_P2P = `${AppConfig.endpoint.api}/market/p2p/history`
export const INVESTOR_LIST_TRANSACTION_IDO = `${AppConfig.endpoint.api}/market/ido/history`
export const INVESTOR_LIST_TRANSACTION_SWAP = `${AppConfig.endpoint.api}/market/swap/history`
export const INVESTOR_LIST_ACTIVITY = `${AppConfig.endpoint.api}/`
export const INVESTOR_LIST_REFERRAL = `${AppConfig.endpoint.api}/ref/list-referrals`

// referral
export const REFERRAL_GET_LIST = `${AppConfig.endpoint.api}/ref/admin-reward-history`
export const REFERRAL_INFO = `${AppConfig.endpoint.api}/ref/admin-statistic`
export const REFERRAL_CONFIG = `${AppConfig.endpoint.api}/ref/set-reward-config`

// DEPOSIT
export const DEPOSIT_GET_LIST = `${AppConfig.endpoint.api}/wallet/deposit`
export const DEPOSIT_CONFIRM = `${AppConfig.endpoint.api}/wallet/admin-mark-deposit`
export const DEPOSIT_INFO = `${AppConfig.endpoint.api}/wallet/deposit`

// WITHDRAW
export const WITHDRAW_GET_LIST = `${AppConfig.endpoint.api}/wallet/admin-list-withdrawal`
export const WITHDRAW_CONFIRM = `${AppConfig.endpoint.api}/wallet/admin-mark-withdrawal`
export const WITHDRAW_INFO = `${AppConfig.endpoint.api}/wallet/admin-list-withdrawal`
export const WITHDRAW_UPLOAD_FILE = `${AppConfig.endpoint.api}/wallet/admin-mark-withdrawal-upload`

// Voting
export const VOTING_GET_LIST = `${AppConfig.endpoint.api}/project/get-buyer-offer`
export const VOTING_GET_DETAIL = `${AppConfig.endpoint.api}`
export const VOTING_CREATE = `${AppConfig.endpoint.api}/project/admin/add-buyer-offer`
export const VOTING_CANCEL = `${AppConfig.endpoint.api}/project/admin/cancel-buyer-offer`

// Ranking
export const RANKING_GET_LIST = `${AppConfig.endpoint.api}/rank/admin/get`
export const RANKING_GET_DETAIL = `${AppConfig.endpoint.api}/rank/detail`
export const CREATE_RANKING = `${AppConfig.endpoint.api}/rank/admin/create`
export const UPDATE_RANKING = `${AppConfig.endpoint.api}/rank/admin/update`
