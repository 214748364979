import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Steps,
} from 'antd'
import {
  MailOutlined,
} from '@ant-design/icons'
import ReactCodeInput from 'react-verification-code-input'
import successIcon from '../../../static/images/reset-pwd.png'

const { Step } = Steps

const steps = [
  {
    title: 'Nhập email',
    content: 'Xác minh địa chỉ email',
    subcontent: 'Nhập địa chỉ email được dùng để đăng ký tài khoản BRE Real Estate',
  },
  {
    title: 'Xác minh',
    content: 'Xác minh bảo mật',
    subcontent: 'Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau',
  },
  {
    title: 'Đặt lại mật khẩu',
    content: 'Đặt lại mật khẩu',
    subcontent: 'Vui lòng nhập lại mật khẩu mới, yêu cầu 8 ký tự trở lên',
  },
  {
    title: 'Hoàn tất',
    content: 'Xin chúc mừng',
    subcontent: 'Bạn đã đặt lại mật khẩu mới thành công. Vui lòng đăng nhập lại bằng mật khẩu mới',
  },
]

const ResetPassword = () => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [currentStep, setCurrentStep] = useState(0)

  const stepContent = () => {
    console.log(currentStep)
    return (
      <div className="mt-4">
        <div className="text-center">
          <h4>{steps[currentStep].content}</h4>
          <p>{steps[currentStep].subcontent}</p>
        </div>
        {
          (() => {
            switch (currentStep) {
              case 0:
                return (
                  <Form className="login-form">
                    <Form.Item className="item-form" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                        onClick={() => setCurrentStep(1)}
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </Form>
                )

              case 1:
                return (
                  <Form className="login-form">
                    <Form.Item className="item-form" label="Email" rules={[{ required: true }]}>
                      <ReactCodeInput />
                      <div className="text-center">Nhập mã xác minh gồm 6 chữ số</div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                        onClick={() => setCurrentStep(2)}
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                    <div className="text-center">
                      <div>Bạn chưa nhận được mã?</div>
                      <Link href="#">Gửi lại mã xác nhận</Link>
                    </div>
                  </Form>
                )

              case 2:
                return (
                  <Form className="login-form">
                    <Form.Item className="item-form" label="Mật khẩu mới" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        placeholder="Nhập mật khẩu mới"
                      />
                    </Form.Item>
                    <Form.Item className="item-form" label="Xác nhận mật khẩu" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        placeholder="Xác nhận mật khẩu"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                        onClick={() => setCurrentStep(3)}
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </Form>
                )

              case 3:
                return (
                  <div className="text-center">
                    <img src={successIcon} />
                    <Link to="/">
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                      >
                        Đăng nhập
                      </Button>
                    </Link>
                  </div>
                )

              default:
                return null
            }
          })()
        }
      </div>
    )
  }

  return (
    <Layout title="login" className="login">
      {/* Sign-in Form */}
      <Row>
        <Col span={24} className="loginForm">
          <div
            className="d-flex  justify-content-center flex-column"
            style={{ maxWidth: '50vw', margin: 'auto', height: '100vh' }}
          >
            <div className="text-left">
              {/* <img src={logo} /> */}
              <h1 className="my-4 text-center" style={{ color: 'black' }}>Đặt lại mật khẩu</h1>
            </div>
            <Steps current={currentStep}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="w-50 mx-auto">
              {stepContent()}
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default ResetPassword
