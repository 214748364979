import { useMutation, useQuery } from 'react-query'
import { Modal } from 'antd'
import rankingApi from '../provider/rank'

export const useRankList = (params = { page: 1, limit: 10 }, rankingType, statusRanking) => useQuery({
  queryKey: ['getRankList', params.key_search, params.page, params.limit, rankingType, statusRanking],
  queryFn: () => rankingApi.getList(params, rankingType, statusRanking),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (data) => data?.data,
})

export const useRankDetail = (rankId, onError = () => { console.log('AAAAAAAA', rankId) }) => useQuery({
  queryKey: ['getRankDetail', rankId],
  queryFn: () => rankingApi.getDetail(rankId),
  enabled: !!rankId,
  initialData: {},
  select: (data) => data?.data,
  onError: (e) => {
    Modal.error({
      title: 'Không tìm thấy hạng',
      content: e?.message,
      onOk: onError,
    })
  },
})

export const useCreateRanking = () => useMutation({
  mutationFn: rankingApi.createRanking,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không tạo được bài hạng',
      content: e?.message,
    })
  },
})

export const useUpdateRanking = () => useMutation({
  mutationFn: rankingApi.updateRanking,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không cập nhật được hạng',
      content: e?.message,
    })
  },
})
