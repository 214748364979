export const KEYS = {
  TOKEN: 'TOKEN',
}

function supportsLocalStorage() {
  return typeof Storage !== 'undefined'
}

export const set = (key, value) => {
  if (supportsLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const remove = (key) => {
  if (supportsLocalStorage()) {
    localStorage.removeItem(key)
  }
}

export const get = (key) => {
  if (supportsLocalStorage()) {
    try {
      const value = JSON.parse(localStorage.getItem(key))
      return value
    } catch (e) {
      localStorage.setItem(key, null)
      return null
    }
  }
  return undefined
}
