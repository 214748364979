import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import USER from './default/user'
import rootReducer from './reducer'

export const defaultState = {
  user: {
    data: USER,
    loading: false,
    error: null,
  },
}

export default (initialState = defaultState) => createStore(
  combineReducers({
    ...rootReducer,
  }),
  fromJS(initialState),
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
)
