import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router-dom'
import InvestorList from 'pages/Investor/InvestorList'
import InvestorDetail from 'pages/Investor/InvestorDetail'
import ReferralList from 'pages/referral'
import WalletList from 'pages/wallet'
import DepositDetail from 'pages/wallet/detailDeposit'
import WithdrawDetail from 'pages/wallet/detailWithdraw'
import VotingList from 'pages/Voting/VotingList'
import VotingCreate from 'pages/Voting/VotingCreate'
import RequestContactList from 'pages/RequestContact/RequestContactList'
import Dashboard from './container/Dashboard'
import SignIn from './container/Signin'
import ResetPassword from './container/ResetPassword'
import NotFoundPage from './pages/NotFoundPage'
import PrivateLayout from './layout/PrivateLayout'
import PublicLayout from './layout/PublicLayout'
import RouteWithLayout from './layout/RouteWithLayout'
import KYC from './container/Kyc'
import KYCDetail from './container/DetailKyc'
import TransactionHistory from './container/Transaction'
import ProjectList from './pages/Project/ProjectList'
import ProjectDetail from './pages/Project/ProjectDetail'
import ProjectCreate from './pages/Project/ProjectCreate'
import ProfitSharing from './pages/Project/ProfitSharing'
import RankList from './pages/Ranks/RankList'
import RankDetail from './pages/Ranks/RankDetail'

const Routes = () => {
  useEffect(() => {
    // Integrate socket
    console.log('route')
  }, [])

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={PrivateLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={KYC}
        exact
        layout={PrivateLayout}
        path="/kyc"
      />
      <RouteWithLayout
        component={KYCDetail}
        exact
        layout={PrivateLayout}
        path="/kyc/:id"
      />
      <RouteWithLayout
        component={TransactionHistory}
        exact
        layout={PrivateLayout}
        path="/transaction"
      />
      <RouteWithLayout
        component={ProjectList}
        exact
        layout={PrivateLayout}
        path="/project"
      />
      <RouteWithLayout
        component={ProjectDetail}
        layout={PrivateLayout}
        path="/project/view/:projectId"
      />
      <RouteWithLayout
        component={ProjectCreate}
        layout={PrivateLayout}
        path="/project/create"
      />
      <RouteWithLayout
        component={ProfitSharing}
        layout={PrivateLayout}
        path="/project/profit-sharing/:projectId"
      />
      <RouteWithLayout
        component={VotingList}
        exact
        layout={PrivateLayout}
        path="/voting"
      />
      <RouteWithLayout
        component={RequestContactList}
        exact
        layout={PrivateLayout}
        path="/request-contact"
      />
      <RouteWithLayout
        component={VotingCreate}
        layout={PrivateLayout}
        path="/voting/create"
      />
      <RouteWithLayout
        component={InvestorList}
        exact
        layout={PrivateLayout}
        path="/investor"
      />
      <RouteWithLayout
        component={InvestorDetail}
        layout={PrivateLayout}
        exact={false}
        path="/investor/:investorId"
      />
      <RouteWithLayout
        component={ReferralList}
        exact
        layout={PrivateLayout}
        path="/referral"
      />
      <RouteWithLayout
        component={WalletList}
        exact
        layout={PrivateLayout}
        path="/wallet"
      />
      <RouteWithLayout
        component={DepositDetail}
        layout={PrivateLayout}
        path="/wallet/deposit/:depositId"
      />
      <RouteWithLayout
        component={WithdrawDetail}
        layout={PrivateLayout}
        path="/wallet/withdraw/:withdrawId"
      />
      <RouteWithLayout
        component={ResetPassword}
        exact
        layout={PublicLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={SignIn}
        exact
        layout={PublicLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundPage}
        exact
        layout={PrivateLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={RankList}
        exact
        layout={PrivateLayout}
        path="/ranking"
      />
      <RouteWithLayout
        component={RankDetail}
        layout={PrivateLayout}
        path="/ranking/create"
      />
      <RouteWithLayout
        component={RankDetail}
        layout={PrivateLayout}
        exact={false}
        path="/ranking/:rankingId"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapDispatchToProps = (dispatch) => ({
})

Routes.propTypes = {
}

export default connect(false, mapDispatchToProps)(Routes)
