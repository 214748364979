import axios from 'axios'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getListDeposit = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.DEPOSIT_GET_LIST, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getInfoDeposit = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.DEPOSIT_INFO, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const confirmDeposit = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.DEPOSIT_CONFIRM, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getListDeposit, getInfoDeposit, confirmDeposit,
}
