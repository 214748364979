import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getListWithdraw = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.WITHDRAW_GET_LIST, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getInfoWithdraw = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.WITHDRAW_INFO, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const confirmWithdraw = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.WITHDRAW_CONFIRM, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const uploadImage = async (param, file) => {
  const { KEYS } = storage
  const formData = new FormData()
  formData.append('file', file)
  try {
    const resp = await axios.put(`${ENDPOINT.WITHDRAW_UPLOAD_FILE}`, formData, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getListWithdraw, getInfoWithdraw, confirmWithdraw, uploadImage,
}
