import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Layout,
  Menu,
  Divider,
  Avatar,
} from 'antd'
import styled from 'styled-components'
import {
  MailOutlined,
  CloseCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  GoogleOutlined,
  WalletOutlined,
  HistoryOutlined,
  OrderedListOutlined,
  DashboardOutlined,
  ProjectOutlined,
} from '@ant-design/icons'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import userAvatar from '../static/images/flat-avatar.png'
import HeaderDiv from '../components/HeaderDiv'
import * as userAction from '../redux/action/user'
import { getUserData } from '../redux/selector/user'

const { SubMenu } = Menu
const { Header, Sider, Content } = Layout

const WrapperMenu = styled(Menu).attrs({
  className: 'layout-menu-content',
})`
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 20px;
  font-weight: 500;
`
const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`
const WrapperContent = styled(Content)`
  padding: 24px;
  min-height: 100vh;
`
const WrapperUserContent = styled.div`
  padding: 0 16px;
`
const VerifyItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const VerifyStatusItem = styled.div`
  display: flex;
  align-items: center;
  color: #838383;
  font-weight: 400;
  height:5px;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const SuccessStatusItem = styled.div`
  display: flex;
  align-items: center;
  color: #11C55D;
  font-weight: 400;
  height:5px;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const UserEmailItem = styled.div`
  display: flex;
  align-items: center;
  color: #838383;
  font-weight: 400;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const PrivateLayout = ({
  user,
  children,
  active,
  classname,
  getInfo,
}) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState('')
  useEffect(() => {
    if (!user?.data?.username) {
      getInfo()
    }
    return () => { }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!user?.data?.username) {
      history.push('/sign-in')
    }
  }, [user?.data])

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const onOpenChange = (openKeysData) => {
    console.log(openKeysData)
  }

  if (!user) return null

  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: 'red' }} className="sidebar-left" onCollapse={toggle}>
        <WrapperMenu
          defaultSelectedKeys={[active]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <WrapperUserContent>
            <Avatar size={78} src={userAvatar} className="mb-3" />
            <h4>{user?.data?.name || 'noname'}</h4>
            <UserEmailItem>
              <MailOutlined />
              <span>
                {' '}
                {user?.data?.email}
              </span>
            </UserEmailItem>

          </WrapperUserContent>
          <Divider />
          <Menu.Item key="1">
            <Link to="/kyc">
              <MenuItemContent>
                <DashboardOutlined />
                <span> Quản lý KYC </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/transaction">
              <MenuItemContent>
                <HistoryOutlined />
                <span> Transaction History </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/wallet">
              <MenuItemContent>
                <OrderedListOutlined />
                <span> Quản lý Nạp / Rút</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/project">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý dự án BĐS</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/investor">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý NĐT</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/referral">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý Referal</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/voting">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý Voting</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/request-contact">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý yêu cầu liên hệ</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/ranking">
              <MenuItemContent>
                <ProjectOutlined />
                <span> Quản lý Ranking</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Link to="/kyc-detail" />

        </WrapperMenu>
      </Sider>
      <Layout className={`${classname}`}>
        <Header className="headerTop">
          <HeaderDiv />
        </Header>
        <WrapperContent className={collapsed ? 'collapsed mainContent ' : 'mainContent'}>
          {children}
        </WrapperContent>
        <ToastContainer />
      </Layout>
    </>
  )
}

PrivateLayout.propTypes = {
  user: PropTypes.shape(),
  children: PropTypes.shape(),
  active: PropTypes.bool,
  classname: PropTypes.shape(),
  getInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
  signout: () => dispatch(userAction.signout()),
})

export default connect(
  (state) => ({
    user: getUserData(state),
  }),
  mapDispatchToProps,
)(PrivateLayout)
