import React from 'react'
import { Layout, Menu } from 'antd'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const PublicLayout = ({
  classname,
  children,
}) => (
  <>
    <div className={`${classname}`}>{children}</div>
    <ToastContainer />
  </>
)

export default PublicLayout
