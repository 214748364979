import * as userApi from '../../provider/user'
import { USER_ACTION } from '../type'

export const login = ({ username, password }) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.LOGIN,
  })
  try {
    const user = await userApi.login(username, password)
    dispatch({
      type: USER_ACTION.LOGIN_OK,
      payload: user,
    })
  } catch (e) {
    dispatch({
      type: USER_ACTION.LOGIN_ERR,
      payload: user,
      message: 'Error',
    })
  }
}

export const get = () => async (dispatch) => {
  dispatch({
    type: USER_ACTION.GET_INFO,
  })
  try {
    const user = await userApi.get()
    dispatch({
      type: USER_ACTION.GET_INFO_OK,
      payload: user,
    })
  } catch (e) {
    window.location.replace('/sign-in')
    dispatch({
      type: USER_ACTION.GET_INFO_ERR,
      message: '',
    })
  }
}

export const signout = () => {
  userApi.signout()
  return {
    type: USER_ACTION.LOGOUT,
  }
}

export const checkReferrer = ({ username, password }) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.LOGIN,
  })
  try {
    const user = await userApi.login(username, password)
    dispatch({
      type: USER_ACTION.LOGIN_OK,
      payload: user,
    })
  } catch (e) {
    dispatch({
      type: USER_ACTION.LOGIN_ERR,
      message: '',
    })
  }
}

export const register = ({
  name, email, username, password, referrer,
}, callback) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.REGISTER,
  })
  try {
    const resp = await userApi.register(name, email, username, password, referrer)
    if (callback) {
      callback(resp)
    }
    dispatch({
      type: USER_ACTION.REGISTER_OK,
      payload: resp,
    })
  } catch (e) {
    dispatch({
      type: USER_ACTION.REGISTER_ERR,
      message: '',
    })
  }
}

export const resendVerifyEmail = ({ email }, callback) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.RESEND_OTP,
  })
  try {
    const resendOTP = await userApi.resendOtpByEmail(email)
    dispatch({
      type: USER_ACTION.RESEND_OTP_OK,
      payload: resendOTP,
    })
    if (callback) {
      callback(resendOTP)
    }
  } catch (e) {
    dispatch({
      type: USER_ACTION.RESEND_OTP_ERR,
      message: '',
    })
  }
}

export const verifyUser = ({ userId, email, otp }, callback) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.VERIFY_USER,
  })
  try {
    const resp = await userApi.verifyUserByOtp(email, userId, otp)
    dispatch({
      type: USER_ACTION.VERIFY_USER_OK,
      payload: resp,
    })
    if (callback) {
      callback(resp)
    }
  } catch (e) {
    dispatch({
      type: USER_ACTION.VERIFY_USER_ERR,
      message: '',
    })
  }
}
