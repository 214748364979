/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, {
  useCallback, useMemo, useState,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber, DatePicker,
  Modal, Spin,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import {
  useCreateProject, usePublishProject, useProjectList,
} from 'hooks/project'
import { PROJECT_CATE_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { DateTime } from 'luxon'
import moment from 'moment'
import styled from 'styled-components'
import { useCreateVoting } from 'hooks/voting'
import RichText from '../../components/RichText'
import { addToQueryString, fromQueryString } from '../../utils/url'
// import Links from './components/Links'

const Required = styled.div`
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
`

const VotingCreate = () => {
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()
  const [form] = Form.useForm()
  const mutationCreate = useCreateVoting()
  const [sellingProduct, setSellingProduct] = useState(null)
  const [timeSale, setTimeSale] = useState([
    moment(DateTime.now().plus({ day: 1 }).toJSDate().toISOString()),
    moment(DateTime.now().toJSDate().toISOString()),
  ])

  const [timeSurvey, setSurvey] = useState([
    moment(DateTime.now().minus({ day: 5 }).toJSDate().toISOString()),
    moment(DateTime.now().toJSDate().toISOString()),
  ])

  const defaultData = useMemo(() => ({
    startTimeSecond: DateTime.now().plus({ day: 1 }).toJSDate().toISOString(),
    endTimeSecond: DateTime.now().toJSDate().toISOString(),
  }), [])

  const { data, isFetching, refetch } = useProjectList({
    limit: parsedQueryString.limit || 100,
    page: parsedQueryString.page || 1,
  })

  const products = data?.projects

  const onChangeSellingProduct = (newVal) => {
    setSellingProduct(newVal)
  }

  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
    offerValue: parseFloat(values.offerValue),
    buyer: '0x0000000000000000000000000000000000000000',
    startTimeSecond: Math.round(new Date(timeSale[0]).getTime() / 1000),
    endTimeSecond: Math.round(new Date(timeSale[1]).getTime() / 1000),
  })

  const onSubmit = (vote) => {
    const mapData = mapSubmitData(vote)
    console.log(mapData)
    mutationCreate.mutate(mapData, {
      onSuccess: (p) => {
        Modal.success({
          content: 'Tạo bài vote thành công',
          onOk: () => history.replace('/voting'),
        })
      },
    })
  }

  const onChangeTimeSale = ([start, end]) => {
    setTimeSale([start, end])
  }

  const onChangeInvest = ([start, end]) => {
    if (!timeSurvey) return
    setSurvey([start, end])
  }

  return (
    <Spin size="large" spinning={mutationCreate.isLoading}>
      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý Voting</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Tạo bài khảo sát</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Tạo bài khảo sát
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultData}
        autoComplete="off"
        scrollToFirs
        tError
        onFinish={onSubmit}
        onFinishFailed={(v) => {
          Modal.error({
            content: JSON.stringify(v),
          })
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Chọn sản phẩm muốn bán"
              name="symbol"
              rules={[{ required: true, message: 'Vui lòng lựa chọn!' }]}
            >
              <Select
                placeholder="Vui lòng lựa chọn"
                optionFilterProp="children"
                onChange={onChangeSellingProduct}

              >
                {products?.map((p) => (
                  <Select.Option value={p.no}>{p.no}</Select.Option>
                ))}
              </Select>
              {/* <Input placeholder="Vui lòng nhập" /> */}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Nhập giá trị muốn bán"
              name="offerValue"
              rules={[{ required: true, message: 'Vui lòng nhập giá trị muốn bán!' }]}
            >
              <Input type="number" placeholder="Vui lòng nhập" />
            </Form.Item>
          </Col>
        </Row>

        <div className="w-100 d-flex align-items-center pb-2">
          <Required>*</Required>
          {' '}
          Chọn mốc giá trị cho khảo sát
        </div>
        <Row className="mb-4">
          <Col span={24}>
            <DatePicker.RangePicker
              format="MM/DD/YYYY"
              style={{ width: '100%' }}
              value={timeSale}
              onChange={onChangeTimeSale}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="Nhập giá trị muốn bán"
              name="survey"
              rules={[{ required: true, message: 'Vui lòng nhập mốc giá trị cho khảo sát!' }]}
            >
              <Input placeholder="Vui lòng nhập" />
            </Form.Item>
          </Col>
        </Row>

        <div className="w-100 d-flex align-items-center pb-2">
          <Required>*</Required>
          {' '}
          Chọn khoảng thời gian cho khảo sát
        </div>
        <Row>
          <Col span={24}>
            <DatePicker.RangePicker
              format="MM/DD/YYYY"
              style={{ width: '100%' }}
              value={timeSurvey}
              onChange={onChangeInvest}
            />
          </Col>
        </Row> */}

        {/* <div className="w-100">
          <h5 className="m-t-20">Mô tả</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item name="describe">
              <RichText />
            </Form.Item>
          </Col>
        </Row> */}

        <Row>
          <Form.Item className="block text-center">
            <Button onClick={history.goBack} className="mr-3">
              Quay lại
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              Tạo
            </Button>
            {/* <Button
              type="primary"
              className="m-t-15"
              onClick={publishProject}
            >
              Đăng dự án
            </Button> */}
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  )
}

export default VotingCreate
