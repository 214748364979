import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  Checkbox,
} from 'antd'
import {
  DiffOutlined,
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  IdcardOutlined,
  ReadOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  HourglassOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Link, BrowserRouter as Router, useHistory } from 'react-router-dom'
import './index.css'
import moment from 'moment'
import kyc from '../../../provider/kyc'

const { Step } = Steps
const { Option } = Select

const steps = [
  {
    title: 'Tên, Quốc Tịch',
    description: 'Đang thực hiện',
    content: 'Hình ảnh CMND/Passport',
    subcontent: 'Nhập địa chỉ email được dùng để đăng ký tài khoản BRE Real Estate',
  },
  {
    title: 'CMND / Passport',
    description: 'Đang chờ',
    content: 'Xác minh CMND/Passport',
    subcontent: 'Chọn một loại giấy tờ cần xác minh',
  },
  {
    title: 'Hình ảnh',
    description: 'Đang chờ',
    content: 'Xác minh hình ảnh',
    subcontent: 'Cung cấp ảnh chụp khuôn mặt cùng với giấy tờ xác minh',
  },
  {
    title: 'Địa chỉ hóa đơn',
    description: 'Đang chờ',
    content: 'Xác minh địa chỉ, hóa đơn',
    subcontent: 'Thông tin liên hệ',
  },
  {
    title: 'Hoàn tất',
    description: 'Đang chờ',
    content: '',
    subcontent: '',
  },
]

const verifyOptions = [
  { label: 'Định dạng tải lên: JPEG, PNG, PDF', value: '1' },
  { label: 'Ảnh chụp rõ ràng, không bị mờ', value: '2' },
  { label: 'Ảnh chụp không bị che khuất, mất góc', value: '3' },
]

const KYCDetail = ({ id }) => {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState(0)
  const [checked, setChecked] = useState({})
  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
    step3: '',
    step4: '',
    step5: '',
  })
  const [descriptonStep, setDescriptonStep] = useState({
    step1: 'Đang thực hiện',
    step2: 'Đang chờ',
    step3: 'Đang chờ',
    step4: 'Đang chờ',
    step5: 'Đang chờ',
  })

  const [showIDcard, setShowIDcard] = useState(false)
  const [showPassport, setShowPassport] = useState(false)
  const [fileList, setFileList] = useState([])
  const [imageUrlFront, setImageUrlFront] = useState('')
  const [fileIdFront, setFileIdFront] = useState(null)
  const [imageUrlBack, setImageUrlBack] = useState('')
  const [fileIdBack, setFileIdBack] = useState(null)
  const [imageUrlPassportFront, setImageUrlPassportFront] = useState('')
  const [imageUrlPassportBack, setImageUrlPassportBack] = useState('')
  const [imageUrlTarget, setImageUrlTarget] = useState('')
  const [imageUrlBill, setImageUrlBill] = useState('')
  const [filePassportFront, setFilePassportFront] = useState(null)
  const [filePassportBack, setFilePassportBack] = useState(null)
  const [fileTarget, setFileTarget] = useState(null)
  const [fileBill, setFileBill] = useState(null)
  const [country, setCountry] = useState('VN')
  const [nameget, setName] = useState('')
  const [birthday, setBirthday] = useState('')
  const [typeDoc, setTypeDoc] = useState('')
  const [zipCode, setZipcode] = useState('')
  const [address, setAddress] = useState('')
  const [additional, setAdditional] = useState('')

  const [dataInfo, setDataInfo] = useState({})

  const [verifyStep1, setVerifyStep1] = useState(0)
  const [verifyFrontStep2, setVerifyFrontStep2] = useState(0)
  const [verifyBackStep2, setVerifyBackStep2] = useState(0)
  const [verifyStep3, setVerifyStep3] = useState(0)
  const [verifyStep4, setVerifyStep4] = useState(0)
  const [rejectReasonsFrontStep2, setRejectReasonsFrontStep2] = useState([])
  const [rejectReasonsBackStep2, setRejectReasonsBackStep2] = useState([])
  const [rejectReasonsStep3, setRejectReasonsStep3] = useState([])
  const [rejectReasonsStep4, setRejectReasonsStep4] = useState([])

  const [form] = Form.useForm()
  const [form1] = Form.useForm()

  const [defaultValuesStep1, setDefaultValuesStep1] = useState({
    country: 'VN',
    name: '',
    birthday: moment(),
  })

  const [defaultValuesSelectStep2, setDefaultValuesSelectStep2] = useState(null)

  const [defaultValuesStep4, setDefaultValuesStep4] = useState({
    province: '',
    district: '',
    sub_district: '',
    address: '',
    zip: '',
  })

  const [district, setDistrict] = useState([])
  const [provinces, setProvince] = useState([])
  const [ward, setWard] = useState([])
  const [CurrentProvince, setCurrentProvince] = useState('')
  const [statusStep1, setStatusStep1] = useState('')
  const onChange = (e) => {
    const { value } = e.target
    console.log('radio checked', value)
    setChecked({
      ...checked,
      checked: value,
    })
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const onChangeStep = (current) => {
    setCurrentStep(current)
  }

  const getProvince = async () => {
    const resp = await kyc.getAddress('')
    setProvince(resp.data)
  }

  const onProvinceSelect = async (value) => {
    setCurrentProvince(value)
    const resp = await kyc.getAddress({ province: value })
    setDistrict(resp.data)
  }

  const onDistrictSelect = async (province, value) => {
    const resp = await kyc.getAddress({ province, district: value })
    setWard(resp.data)
  }

  const getDetail = async (userId) => {
    try {
      const resp = await kyc.getKycDetail({ userId })
      console.log(resp.data)
      setDataInfo(resp.data)
      await onProvinceSelect(resp.data?.add_info?.province)
      await onDistrictSelect(resp.data?.add_info?.province, resp.data?.add_info?.district)
      // if (resp.data?.add_info !== null) {

      // }

      if (resp.data?.step_1?.status === 'C') {
        setVerifyStep1(1)
      } else if (resp.data?.step_1?.status === 'RJ') {
        setVerifyStep1(2)
      } else {
        setVerifyStep1(0)
      }

      if (resp.data?.step_2?.status === 'C') {
        setVerifyFrontStep2(1)
      } else if (resp.data?.step_2?.status === 'RJ') {
        setVerifyFrontStep2(2)
      } else {
        setVerifyFrontStep2(0)
      }

      if (resp.data?.step_3?.status === 'C') {
        setVerifyStep3(1)
      } else if (resp.data?.step_3?.status === 'RJ') {
        setVerifyStep3(2)
      } else {
        setVerifyStep3(0)
      }

      if (resp.data?.step_4?.status === 'C') {
        setVerifyStep4(1)
      } else if (resp.data?.step_4?.status === 'RJ') {
        setVerifyStep4(2)
      } else {
        setVerifyStep4(0)
      }
      setDefaultValuesStep1({
        ...defaultValuesStep1,
        ...{ name: resp.data.name },
        ...{ country: resp.data.country },
        ...{ birthday: moment(resp.data.birthday) },
      })

      setDefaultValuesStep4({
        ...setDefaultValuesStep4,
        ...{ province: resp.data?.add_info?.province },
        ...{ district: resp.data?.add_info?.district },
        ...{ sub_district: resp.data?.add_info?.sub_district },
        ...{ address: resp.data?.add_info?.address },
        ...{ zip: resp.data?.add_info?.zip },
      })

      if (resp.data?.step_2) {
        setImageUrlFront(resp.data?.step_2?.front_doc_img)
        setImageUrlBack(resp.data.step_2?.behind_doc_img)
        setDefaultValuesSelectStep2(1)
        const rejectRes = resp.data.step_2?.note?.split(',') || []
        setRejectReasonsFrontStep2(rejectRes)
        console.log('rejectRes', rejectRes)
        if (resp.data?.step_2?.type === 'ID') {
          setShowIDcard(true)
          setShowPassport(false)
        }
        if (resp.data?.step_2?.type === 'PP') {
          setShowPassport(true)
          setShowIDcard(false)
        }
      }

      if (resp.data?.step_3) {
        const rejectRes = resp.data?.step_3?.note?.split(',') || []
        console.log('step_3', rejectRes)
        setRejectReasonsStep3(rejectRes)
        if (resp.data?.step_3?.type === 'PE') {
          setAdditional(resp.data?.step_3?.additional)
          setImageUrlTarget(resp.data?.step_3?.img_location)
        }
      }

      if (resp.data?.step_4) {
        const rejectRes = resp.data?.step_4?.note?.split(',') || []
        setRejectReasonsStep4(rejectRes)
        console.log('step_4', rejectRes)
        if (resp.data?.step_4?.type === 'IV') {
          setImageUrlBill(resp.data?.step_4?.img_location)
        }
      }
      // setData(resp.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getDetail(id)
    getProvince()
    return () => { }
  }, [])

  useEffect(() => {
    form1.setFieldsValue(defaultValuesStep1)
  }, [form1, defaultValuesStep1])

  useEffect(() => {
    form.setFieldsValue(defaultValuesStep4)
  }, [form, defaultValuesStep4])

  const onChangeVerifyStep1 = (e) => {
    setVerifyStep1(e.target.value)
  }

  const onChangeVerifyFrontStep2 = (e) => {
    setVerifyFrontStep2(e.target.value)
  }

  const onChangeVerifyBackStep2 = (e) => {
    setVerifyBackStep2(e.target.value)
  }

  const onChangeVerifyStep3 = (e) => {
    setVerifyStep3(e.target.value)
  }

  const onChangeVerifyStep4 = (e) => {
    setVerifyStep4(e.target.value)
  }

  const onChangeRejectReasonsFrontStep2 = (e) => {
    setRejectReasonsFrontStep2(e)
    console.log('Front', e)
  }

  const onChangeRejectReasonsBackStep2 = (e) => {
    setRejectReasonsBackStep2(e)
    console.log('Back', e)
  }

  const onChangeRejectReasonsStep3 = (e) => {
    setRejectReasonsStep3(e)
    console.log('step3 reject', e)
  }

  const onChangeRejectReasonsStep4 = (e) => {
    setRejectReasonsStep4(e)
    console.log('step4 reject', e)
  }

  const verfiAction = async (step, status, note) => {
    if (status === 1) {
      try {
        const resp = await kyc.confirm({ step }, { userId: id })
        console.log(resp)
      } catch (error) {
        console.log(error)
      }
    }

    if (status === 2) {
      try {
        const resp = await kyc.reject({ step, note }, { userId: id })
        console.log(resp)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const actionStep1 = async () => {
    if (dataInfo.step_1?.status !== 'C') {
      verfiAction(1, verifyStep1)
    }
    setCurrentStep(1)
  }

  const actionStep2 = async () => {
    if (dataInfo.step_2?.status !== 'C') {
      if (verifyFrontStep2 === 1) {
        verfiAction(2, verifyFrontStep2, '')
      }
      if (verifyFrontStep2 === 2) {
        console.log(rejectReasonsFrontStep2)
        verfiAction(2, verifyFrontStep2, rejectReasonsFrontStep2.toString())
      }
    }
    setCurrentStep(2)
  }

  const actionStep3 = async () => {
    if (dataInfo.step_3?.status !== 'C') {
      if (verifyStep3 === 1) {
        verfiAction(3, verifyStep3, '')
      }
      if (verifyStep3 === 2) {
        verfiAction(3, verifyStep3, rejectReasonsStep3.toString())
      }
    }
    setCurrentStep(3)
  }

  const actionStep4 = async () => {
    if (dataInfo.step_4?.status !== 'C') {
      if (verifyStep4 === 1) {
        verfiAction(4, verifyStep4, '')
      }
      if (verifyStep4 === 2) {
        verfiAction(4, verifyStep4, rejectReasonsStep4.toString())
      }
    }
    history.push('/kyc')
  }

  const stepContent = () => (
    <div className="mt-4">
      <div className="">
        <h4>{steps[currentStep].content}</h4>
      </div>
      {
        (() => {
          switch (currentStep) {
            case 0:
              return (
                <>
                  <Row className="m-t-10">
                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt trước CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Bìa hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}

                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlFront}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt sau CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Trang hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}
                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlBack}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                  </Row>

                  <h5 className="m-t-20">Xác minh Tên, Quốc tịch</h5>
                  <Row className="m-t-10 mb-3">
                    <Col span={12} className="p-r-10 border-right">
                      <Form
                        form={form1}
                        layout="vertical" className="w-75"
                        initialValues={defaultValuesStep1}
                      >
                        <Form.Item name="country" className="item-form m-t-10" label="Quốc tịch">
                          <Select disabled>
                            <Option value="VN">Việt Nam</Option>
                            <Option value="NN">Nước Ngoài</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item name="name" className="item-form m-t-10" label="Họ và tên">
                          <Input
                            disabled
                          />
                        </Form.Item>

                        <Form.Item name="birthday" className="item-form m-t-10" label="Ngày tháng năm sinh">
                          <DatePicker disabled style={{ width: '100%' }} />
                        </Form.Item>

                      </Form>
                    </Col>
                    <Col span={12} className="p-r-10 d-flex align-items-center">

                      <Radio.Group onChange={onChangeVerifyStep1} value={verifyStep1} className="ml-5">
                        <Space direction="vertical">
                          <Radio value={1}>Chấp thuận</Radio>
                          <Radio value={2}>Không chấp thuận</Radio>
                        </Space>
                      </Radio.Group>

                    </Col>
                  </Row>
                  <Form.Item className="block text-center">
                    <Button style={{ margin: '0 8px' }} onClick={() => history.push('/kyc')}>
                      Hủy bỏ
                    </Button>
                    <Button
                      type="primary"
                      className=" m-t-15"
                      onClick={actionStep1}
                    >
                      Tiếp tục
                    </Button>
                  </Form.Item>
                </>
              )

            case 1:
              return (
                <div className="w-100">
                  <Row className="m-t-10">

                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt trước CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Bìa hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}
                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlFront}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10 d-flex align-items-center">
                      <div>
                        <Radio.Group onChange={onChangeVerifyFrontStep2} value={verifyFrontStep2} className="ml-3">
                          <Space direction="vertical">
                            <Radio value={1}>Chấp thuận</Radio>
                            <Radio value={2}>Không chấp thuận</Radio>
                          </Space>

                        </Radio.Group>
                        <Checkbox.Group disabled={verifyFrontStep2 === 1} defaultValue={rejectReasonsFrontStep2} options={verifyOptions} onChange={onChangeRejectReasonsFrontStep2} style={{ display: 'grid' }} className="ml-5 mt-1" />
                      </div>

                    </Col>
                  </Row>

                  <Row className="m-t-10">

                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt sau CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Trang hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}
                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlBack}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10 d-flex align-items-center">
                      <div>
                        <Radio.Group onChange={onChangeVerifyBackStep2} value={verifyBackStep2} className="ml-3">
                          <Space direction="vertical">
                            <Radio value={1}>Chấp thuận</Radio>
                            <Radio value={2}>Không chấp thuận</Radio>
                          </Space>

                        </Radio.Group>
                        <Checkbox.Group disabled={verifyBackStep2 === 1} options={verifyOptions} defaultValue={rejectReasonsBackStep2} onChange={onChangeRejectReasonsBackStep2} style={{ display: 'grid' }} className="ml-5 mt-1" />
                      </div>

                    </Col>

                  </Row>
                  <Form.Item className="block text-center">
                    <Button style={{ margin: '0 8px' }} onClick={prev}>
                      Quay lại
                    </Button>
                    <Button
                      type="primary"
                      className=" m-t-15"
                      onClick={actionStep2}
                    >
                      Tiếp tục
                    </Button>
                  </Form.Item>
                </div>

              )

            case 2:
              return (
                <>
                  <h5 className="m-b-20">Loại giấy tờ</h5>
                  {showIDcard ? (
                    <>
                      <Button style={{ width: 200, alignItems: 'center', display: 'flex' }} className="btn-block active">
                        <IdcardOutlined />
                        <span className="m-l-10">CMND / CCCD </span>
                      </Button>
                    </>
                  ) : showPassport ? (
                    <>
                      <Button style={{ width: 200, alignItems: 'center', display: 'flex' }} className="btn-block active">
                        <IdcardOutlined />
                        <span className="m-l-10">Passport</span>
                      </Button>
                    </>
                  ) : <></>}
                  <Row className="m-t-10">
                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt trước CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Bìa hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}
                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlFront}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10">
                      {showIDcard ? (
                        <>
                          <div>
                            <span>Mặt sau CMND / CCCD</span>
                          </div>
                        </>
                      ) : showPassport ? (
                        <>
                          <div>
                            <span>Trang hộ chiếu</span>
                          </div>
                        </>
                      ) : <></>}
                      <div className="border-card-upload m-t-10">
                        <img src={`data:image/jpeg;base64,${imageUrlBack}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                  </Row>
                  <h5 className="m-t-20">Xác minh hình ảnh</h5>
                  <Row className="m-t-10 mb-3">
                    <Col span={12} className="p-r-10 border-right">
                      <div className="border-card-upload">
                        <img src={`data:image/jpeg;base64,${imageUrlTarget}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10 d-flex align-items-center">
                      <div>
                        <Radio.Group onChange={onChangeVerifyStep3} value={verifyStep3} className="ml-3">
                          <Space direction="vertical">
                            <Radio value={1}>Chấp thuận</Radio>
                            <Radio value={2}>Không chấp thuận</Radio>
                          </Space>

                        </Radio.Group>
                        <Checkbox.Group disabled={verifyStep3 === 1} options={verifyOptions} defaultValue={rejectReasonsStep3} onChange={onChangeRejectReasonsStep3} style={{ display: 'grid' }} className="ml-5 mt-1" />
                      </div>

                    </Col>
                  </Row>

                  <Form.Item className="block text-center">
                    <Button style={{ margin: '0 8px' }} onClick={prev}>
                      Quay lại
                    </Button>
                    <Button
                      type="primary"
                      className=" m-t-15"
                      onClick={actionStep3}
                    >
                      Tiếp tục
                    </Button>
                  </Form.Item>
                </>

              )

            case 3:
              return (
                <div className="w-100">
                  <h5 className="m-t-20">Thông tin liên hệ</h5>
                  <Row className="m-t-10">
                    <Col span={12} className="p-r-10 border-right">
                      <Form
                        form={form}
                        layout="vertical" className="w-100"
                        initialValues={defaultValuesStep4}
                      >
                        <Row>
                          <Col span={24} className="p-r-10">
                            <Form.Item name="zip" className="item-form" label="Mã bưu điện">
                              <Input
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24} className="p-r-10">
                            <Form.Item name="province" className="item-form" label="Tỉnh/ Thành phố">
                              <Select
                                disabled
                              >
                                {provinces?.map((option) => (
                                  <Option key={option.codename} value={option.codename}>
                                    {option.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="p-r-10">
                            <Form.Item name="district" className="item-form" label="Quận/ Huyện">
                              <Select
                                disabled
                              >
                                {district?.map((option) => (
                                  <Option key={option.codename} value={option.codename}>
                                    {option.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="p-r-10">
                            <Form.Item name="sub_district" className="item-form" label="Phường/ Xã">
                              <Select
                                disabled
                              >
                                {ward?.map((option) => (
                                  <Option key={option.codename} value={option.codename}>
                                    {option.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="p-r-10">
                            <Form.Item name="address" className="item-form" label="Địa chỉ cụ thể">
                              <Input
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <h5 className="m-t-20">Xác minh hóa đơn</h5>
                      <div className="border-card-upload m-t-20">
                        <img src={`data:image/jpeg;base64,${imageUrlBill}`} alt="avatar" className="img-upload" />
                      </div>
                    </Col>
                    <Col span={12} className="p-r-10 d-flex">
                      <div>
                        <Radio.Group onChange={onChangeVerifyStep4} value={verifyStep4} className="ml-3">
                          <Space direction="vertical">
                            <Radio value={1}>Chấp thuận</Radio>
                            <Radio value={2}>Không chấp thuận</Radio>
                          </Space>

                        </Radio.Group>
                        <Checkbox.Group disabled={verifyStep4 === 1} options={verifyOptions} defaultValue={rejectReasonsStep4} onChange={onChangeRejectReasonsStep4} style={{ display: 'grid' }} className="ml-5 mt-1" />
                      </div>

                    </Col>
                  </Row>

                  <Form.Item className="block text-center">
                    <Button style={{ margin: '0 8px' }} onClick={prev}>
                      Quay lại
                    </Button>
                    <Button
                      type="primary"
                      className=" m-t-15"
                      onClick={actionStep4}
                    >
                      Xác nhận
                    </Button>
                  </Form.Item>
                </div>
              )
            default:
              return null
          }
        })()
      }
    </div>
  )
  return (
    <>
      <Row>
        <Col span={24} className="loginForm">
          <div className="d-flex  justify-content-center flex-column m-t-30">
            <Steps
              current={currentStep}
              onChange={onChangeStep}
            >
              <Step status={statusStep?.step1} title="Tên, Quốc Tịch" description={descriptonStep.step1} />
              <Step status={statusStep?.step2} title="CMND / Passport" description={descriptonStep.step2} />
              <Step status={statusStep?.step3} title="Hình ảnh" description={descriptonStep.step3} />
              <Step status={statusStep?.step4} title="Địa chỉ hóa đơn" description={descriptonStep.step4} />
            </Steps>
            <div className="w-100 m-t-40">
              {stepContent()}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default KYCDetail
