import { useQuery } from 'react-query'
import investorApi from '../provider/investor'

export const useInvestorList = (params = { page: 1, limit: 10 }, statusKyc, statusInvestor) => useQuery({
  queryKey: ['getInvestorList', params.key_search, params.page, params.limit, statusKyc, statusInvestor],
  queryFn: () => investorApi.getList(params, statusKyc, statusInvestor),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (data) => data?.data,
})
