export const PROJECT_STATUS = {
  W: 'W',
  PC: 'PC',
  P: 'P',
  FN: 'FN',
  CL: 'CL',
  RF: 'RF',
  F: 'F',
}

export const PROJECT_STATUS_NAMES = {
  [PROJECT_STATUS.W]: 'Đang xử lý',
  [PROJECT_STATUS.PC]: 'Đang mở bán',
  [PROJECT_STATUS.P]: 'Đang khởi tạo',
  [PROJECT_STATUS.FN]: 'Kết thúc mở bán',
  [PROJECT_STATUS.CL]: 'Huỷ project',
  [PROJECT_STATUS.RF]: 'Huỷ project',
  [PROJECT_STATUS.F]: 'Tạo dự án lỗi',
}

export const PROJECT_TYPES = {
  GROUND: 'dat_nen',
  TOWNHOUSE: 'nha_pho',
  APARTMENT: 'nha_chung_cu',
  GARDEN: 'dat_vuon',
}

export const PROJECT_TYPE_NAMES = {
  [PROJECT_TYPES.GROUND]: 'Đất nền',
  [PROJECT_TYPES.TOWNHOUSE]: 'Nhà phố',
  [PROJECT_TYPES.APARTMENT]: 'Chung cư',
  [PROJECT_TYPES.GARDEN]: 'Đất vườn',
}

export const PROJECT_CATES = {
  HAS_LEGAL: 'co_so_do',
  NO_HAS_LEGAL: 'khong_so_do',
}

export const PROJECT_CATE_NAMES = {
  [PROJECT_CATES.HAS_LEGAL]: 'BĐS có sổ đỏ',
  [PROJECT_CATES.NO_HAS_LEGAL]: 'BĐS không có sổ đỏ',
}
