import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber, DatePicker,
  Modal, Typography, Upload,
} from 'antd'
import {
  UserOutlined,
  WalletOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import {
  Link, Switch, Route, Redirect,
} from 'react-router-dom'
import { matchPath, useLocation } from 'react-router'
import styled from 'styled-components'
import UploadFile from 'components/upload'
import withdraw from 'provider/withdraw'
import moment from 'moment'
import { toast } from 'react-toastify'

const { Title } = Typography

const DetailWithdraw = ({ withdrawId }) => {
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [defaultWithdraw, setDefaultWithdraw] = useState({
    currency: '', no: '', bank_type: '', number_card: '', quantity: 0, createdAt: moment(),
  })
  const [defaultRefuse, setDefaultRefuse] = useState({
    note: '',
  })
  const [modalConfirm, setModalConfirm] = useState(false)
  const [modalRefuse, setModalRefuse] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataWithdraw, setDataWithdraw] = useState({})
  const [imageUrlPaymentProof, setImageUrlPaymentProof] = useState('')
  const [evidence, setEvidence] = useState(null)

  const getInfoWithdraw = async (id) => {
    setLoading(true)
    try {
      setLoading(false)
      const resp = await withdraw.getInfoWithdraw({ currency: 'VND', id })
      setDataWithdraw(resp.data)
      setDefaultWithdraw({
        ...defaultWithdraw,
        ...{ currency: resp.data.currency },
        ...{ no: resp.data.no },
        ...{ bank_type: resp.data.to.bank_type },
        ...{ number_card: resp.data.to.number_card },
        ...{ quantity: resp.data.quantity },
        ...{ createdAt: moment(resp.data.createdAt) },
      })
      setImageUrlPaymentProof(resp.data.confirmation.verification_screenshot)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSubmit = async () => {
    const data = {
      currency: dataWithdraw.currency, no: dataWithdraw.no, evidence, status: 'S',
    }
    try {
      if (evidence !== null) {
        const resp = await withdraw.confirmWithdraw(data)
        if (resp.code === 200) {
          toast.success('Xác nhận giao dịch thành công')
          setModalConfirm(false)
        } else {
          toast.error(resp.msg)
        }
      } else {
        setModalConfirm(false)
        toast.warning('Vui lòng kiểm tra input')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onRefuse = async (values) => {
    const data = {
      currency: dataWithdraw.currency, no: dataWithdraw.no, status: 'F', note: values.note,
    }
    try {
      const resp = await withdraw.confirmWithdraw(data)
      if (resp.code === 200) {
        toast.success('Từ chối giao dịch thành công')
        setModalRefuse(false)
      } else {
        toast.error(resp.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const imageUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      toast.warning('You can only upload JPG/PNG file!')
    }
    // const isLt2M = file.size / 1024 / 1024 < 2
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!')
    // }
    return isJpgOrPng
  }

  const handleChangePaymentProof = async (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrlPaymentProof(imageUrl))
    const resp = await withdraw.uploadImage({ no: dataWithdraw.no }, info.file.originFileObj)
    setEvidence(resp.data.location)
  }

  useEffect(() => {
    getInfoWithdraw(withdrawId)
    return () => {
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(defaultWithdraw)
  }, [form, defaultWithdraw])

  useEffect(() => {
    form1.setFieldsValue(defaultRefuse)
  }, [form1, defaultRefuse])

  return (
    <>
      {/* Modal Confirm */}
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalConfirm}
        onOk={() => setModalConfirm(false)}
        onCancel={() => setModalConfirm(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Xác nhận giao dịch
        </Title>
        <p>
          Xác nhận đã nhận tiền từ người dùng và thực hiện
          lệnh nạp tiền vào tài khoản của người dùng.
        </p>
        <div className="block right">
          <Button
            className=" m-t-15 mr-2"
            htmlType="submit"
            onClick={() => setModalConfirm(false)}
          >
            Hủy
          </Button>
          <Button
            type="primary"
            className=" m-t-15"
            htmlType="button"
            onClick={onSubmit}
          >
            Xác nhận
          </Button>
        </div>
      </Modal>

      {/* Modal Refuse */}
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalRefuse}
        onOk={() => setModalRefuse(false)}
        onCancel={() => setModalRefuse(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Từ chối giao dịch
        </Title>
        <Form
          form={form1}
          layout="vertical"
          // className="login-form"
          initialValues={defaultRefuse}
          onFinish={onRefuse}
        >
          <Form.Item name="note">
            <Input.TextArea placeholder="Nhập lý do từ chối giao dịch" />
          </Form.Item>
          <Form.Item className="block right">
            <Button
              className=" m-t-15 mr-2"
              htmlType="button"
              onClick={() => setModalRefuse(false)}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              className=" m-t-15"
              htmlType="submit"
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Form>

      </Modal>

      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý dự án bất động sản</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Chi tiết giao dịch</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Chi tiết giao dịch
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultWithdraw}
        autoComplete="off"
        scrollToFirs
        tError
        // onFinish={onSubmit}
      >

        <Row gutter={32}>
          <Col span={12}>
            {/* <Form.Item
              label="Loại giao dịch"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Người gửi"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Số tài khoản người gửi"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Ngân hàng"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item> */}
            {/* <Form.Item
              label="Người nhận"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" />
            </Form.Item> */}
            <Form.Item
              label="Số tài khoản người nhận"
              name="number_card"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Ngân hàng"
              name="bank_type"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            {/* <Form.Item
              label="Nội dung giao dịch"
              name="note"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item> */}
            <Form.Item
              label="Số tiền"
              name="quantity"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Thời gian giao dịch"
              name="createdAt"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="w-100">
              <h5>Bằng chứng thanh toán</h5>
            </div>
            <div name="avatar">
              <div className="border-card-upload">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // customRequest={dummyRequest}
                  beforeUpload={imageUpload}
                  onChange={handleChangePaymentProof}
                >
                  <div>
                    <img src={imageUrlPaymentProof} alt="avatar" className="img-upload" />
                  </div>

                </Upload>

              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Form.Item className="block text-center">
            <Button style={{ margin: '0 8px' }} htmlType="button" onClick={() => setModalRefuse(true)}>
              Quay lại
            </Button>
            <Button
              htmlType="button"
              type="primary"
              className="m-t-15"
              onClick={() => setModalConfirm(true)}
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Row>
      </Form>

    </>
  )
}

export default DetailWithdraw
