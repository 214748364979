import React from 'react'
import {
  Row, Col, Typography, Table,
} from 'antd'

const Wallet = () => {
  const columns = [{
    title: 'Tài sản',
    dataIndex: 'no',
  }, {
    title: 'Số dư',
    dataIndex: 'no',
  }, {
    title: 'Giá trị quy đổi sang BRE',
    dataIndex: 'no',
  }]
  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3">
          <Typography.Title level={4}>
            Ví tài khoản
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
          />
        </Col>
      </Row>
    </>
  )
}

export default Wallet
