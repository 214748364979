/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber, DatePicker,
  Modal, Spin, Tabs, Checkbox,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import {
  useRankDetail, useCreateRanking, useUpdateRanking,
} from 'hooks/rank'
import { PROJECT_CATE_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { DateTime } from 'luxon'
import moment from 'moment'
import styled from 'styled-components'
import { RANKINGTYPE, DISCOUNTTYPE, STATUS } from 'constants/ranking'
import RichText from '../../components/RichText'
import { addToQueryString, fromQueryString } from '../../utils/url'
// import Links from './components/Links'
const { TabPane } = Tabs
const RankDetail = ({ rankingId }) => {
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const { data } = useRankDetail(rankingId, () => {
    history.replace('/ranking')
  })
  const mutationCreate = useCreateRanking()
  const mutationUpdate = useUpdateRanking()
  const [discountChecked, setDiscountChecked] = useState(false)
  const [limitChecked, setLimitChecked] = useState(false)
  const mapSubmitData = ({
    ...values
  }) => ({ ...values })

  const onSubmit = (rank) => {
    rank.discount.is_applied = discountChecked
    rank.limit.is_applied = limitChecked
    rank.config.amount_from = 0
    rank.status = 'ACTIVE'
    const mapData = mapSubmitData(rank)
    // eslint-disable-next-line no-debugger
    debugger
    if (rankingId === undefined) {
      mutationCreate.mutate(mapData, {
        onSuccess: (p) => {
          Modal.success({
            content: 'Tạo bài hạng thành công',
            onOk: () => history.replace('/ranking'),
          })
        },
      })
    } else {
      mapData.rank_id = rankingId
      mutationUpdate.mutate(mapData, {
        onSuccess: (p) => {
          Modal.success({
            content: 'Cập nhật hạng thành công',
            onOk: () => history.replace('/ranking'),
          })
        },
      })
    }
  }
  const onChangeDiscount = (e) => {
    setDiscountChecked(e.target.checked)
  }
  const onChangeLimit = (e) => {
    setLimitChecked(e.target.checked)
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  useEffect(() => {
    setDiscountChecked(data?.discount.is_applied)
    setLimitChecked(data?.limit.is_applied)
    form.resetFields()
  }, [data])
  return (
    <Spin size="large" spinning={mutationCreate.isLoading}>
      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Danh sách cơ chế xếp hạng</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Tạo cơ chế xếp hạng</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Tạo cơ chế xếp hạng
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        autoComplete="off"
        scrollToFirs
        tError
        onFinish={onSubmit}
        onFinishFailed={(v) => onFinishFailed(v)}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Tên hạng"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên hạng!' }]}
            >
              <Input type="text" placeholder="Vui lòng nhập" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Nhập giá trị thứ tự hạng"
              name="no"
              rules={[{ required: true, message: 'Vui lòng nhập thứ tự!' }]}
            >
              <InputNumber defaultValue={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Mô tả hạng"
              name="description"
            >
              <RichText />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Cơ chế xếp hạng" key="1" style={{ width: '100%' }}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Chọn kiểu xếp hạng"
                      name={['config', 'type']}
                      rules={[{ required: true, message: 'Vui lòng lựa chọn!' }]}
                    >
                      <Select
                        placeholder="Vui lòng lựa chọn"
                        optionFilterProp="children"
                      >
                        {Object.keys(RANKINGTYPE).map((p) => (
                          <Select.Option value={p}>{RANKINGTYPE[p]}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Nhập giá trị"
                      name={['config', 'amount_to']}
                      rules={[{ required: true, message: 'Vui lòng nhập giá trị!' }]}
                    >
                      <InputNumber placeholder="Nhập giá trị" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Quyền lợi" key="2">
                <Row>
                  <Form.Item
                    name={['discount', 'is_applied']}
                  >
                    <Checkbox checked={discountChecked} onChange={onChangeDiscount}>Ưu đãi giảm chiếc khấu</Checkbox>
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Chọn loại giảm"
                      name={['discount', 'type']}
                      rules={[discountChecked ? { required: true, message: 'Vui lòng lựa chọn!' } : null]}
                    >
                      <Select
                        placeholder="Vui lòng lựa chọn"
                        optionFilterProp="children"
                        disabled={!discountChecked}
                      >
                        {Object.keys(DISCOUNTTYPE).map((p) => (
                          <Select.Option value={p}>{DISCOUNTTYPE[p]}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Giá trị giảm"
                      name={['discount', 'value']}
                      rules={[discountChecked ? { required: true, message: 'Vui lòng lựa chọn!' } : null]}
                    >
                      <InputNumber disabled={!discountChecked} placeholder="Nhập giá trị" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    name={['limit', 'is_applied']}
                  >
                    <Checkbox checked={limitChecked} onChange={onChangeLimit}>Tăng hạn mức</Checkbox>
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Giá trị tăng"
                      name={['limit', 'value']}
                      rules={[limitChecked ? { required: true, message: 'Vui lòng lựa chọn!' } : null]}
                    >
                      <InputNumber disabled={!limitChecked} placeholder="Nhập giá trị" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Form.Item className="block text-center">
            <Button onClick={history.goBack} className="mr-3">
              Quay lại
            </Button>
            <Button
              type="default"
              htmlType="submit"
              className="mr-3"
            >
              Lưu nháp
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              Lưu
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  )
}

export default RankDetail
