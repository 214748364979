export const RANKINGTYPE = {
  STORED: 'Tài sản lưu trữ',
}

export const DISCOUNTTYPE = {
  AMOUNT: 'Giảm cố định',
  PERCENT: 'Giảm %',
}

export const STATUS = {
  ACTIVE: 'Hoạt động',
  SAVE_DRAFT: 'Lưu rác',
}
