import React, { useEffect, useState } from 'react'
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Breadcrumb,
  PageHeader,
  Tabs,
  Radio,
  Table,
  Tag,
  Pagination,
  Select,
} from 'antd'
import PropTypes from 'prop-types'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import Layouts from '../../components/Layouts'
import './index.css'
import TetherLogo from '../../static/svg/tether.svg'
import ArrowLeft from '../../static/svg/arrow-left.svg'
import ArrowRight from '../../static/svg/arrow-right.svg'
import Clock from '../../static/svg/clock.svg'
import ClockSelected from '../../static/svg/clock-selected.svg'
import Check from '../../static/svg/check.svg'
import CheckSelected from '../../static/svg/check-selected.svg'
import Cancel from '../../static/svg/cancel.svg'
import CancelSelected from '../../static/svg/cancel-selected.svg'

const { Option } = Select

const columns = [
  {
    title: 'Tài sản',
    dataIndex: 'asset',
    render: (asset) => (
      <div className="d-flex flex-row justify-content-center align-items-center">
        <img src={TetherLogo} />
        <div className="ml-2">
          <div className="text-amount" style={{ fontWeight: 600 }}>USDT</div>
          <div className="text-unit">{asset}</div>
        </div>
      </div>
    ),
    sorter: {
      compare: (a, b) => a.asset - b.asset,
      multiple: 6,
    },

  },
  {
    title: 'Hình thức',
    dataIndex: 'form',
    render: (form) => (
      <span>
        <Tag
          key={form}
          color="#ffffff"
          style={{
            height: 32,
            width: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: '#11C55D',
            color: '#121212',
          }}
        >
          {form}
        </Tag>
      </span>
    ),
    sorter: {
      compare: (a, b) => a.form - b.form,
      multiple: 5,
    },
  },
  {
    title: 'Số lượng',
    dataIndex: 'amount',
    render: (amount) => (
      <div>
        <span className="text-amount">
          {amount}
        </span>
        <span className="text-unit"> USTD</span>
      </div>
    ),
    sorter: {
      compare: (a, b) => a.amount - b.amount,
      multiple: 4,
    },
  },
  {
    title: 'SL Token',
    dataIndex: 'tokenAmount',
    render: (tokenAmount) => (
      <span className="text-amount">
        {tokenAmount}
      </span>
    ),
    sorter: {
      compare: (a, b) => a.tokenAmount - b.tokenAmount,
      multiple: 3,
    },
  },
  {
    title: 'Đơn giá Token',
    dataIndex: 'tokenPrice',
    render: (tokenPrice) => (
      <div>
        <span className="text-amount">
          {tokenPrice}
        </span>
        <span className="text-unit"> VNĐ</span>
      </div>
    ),
    sorter: {
      compare: (a, b) => a.tokenPrice - b.tokenPrice,
      multiple: 2,
    },
  },
  {
    title: 'Thời gian',
    dataIndex: 'time',
    render: (time) => (
      <div>
        <span className="text-amount">
          {time}
        </span>
        <span className="text-unit"> 20/12/2022</span>
      </div>
    ),
    sorter: {
      compare: (a, b) => a.time - b.time,
      multiple: 1,
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => (
      <span className="text-success">
        {status}
      </span>
    ),
  },
]

const dataExample = []
for (let i = 0; i < 104; i += 1) {
  dataExample.push({
    id: i,
    asset: 'Tether',
    form: 'Nạp',
    amount: '0.005483',
    tokenAmount: '250,000',
    tokenPrice: '999,000,000',
    time: '23:59',
    status: 'Hoàn tất',
  })
}

const TransactionHistory = (props) => {
  const [tabActive, setTabActive] = useState('depositWithdrawalVND')
  const [data, setData] = useState(dataExample.slice(0, 10))
  const [isProcessed, setIsProcessed] = useState(false)
  const [isAccomplished, setIsAccomplished] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [transactionTime, setTransactionTime] = useState(null)

  const onChangeTab = (e) => {
    setTabActive(e.target.value)
    return e
  }

  const getData = (current, pageSize) => {
    const dataSlice = dataExample.slice((current - 1) * pageSize, current * pageSize)
    setData(dataSlice)
    return dataSlice
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="pagination-link">
          <img src={ArrowLeft} />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="pagination-link">
          <img src={ArrowRight} />
        </div>
      )
    }
    return originalElement
  }

  const onSelectedFilter = (value, valueSelected = null) => {
    switch (value) {
      case 'processed':
        setIsProcessed(!isProcessed)
        break
      case 'accomplished':
        setIsAccomplished(!isAccomplished)
        break
      case 'canceled':
        setIsCanceled(!isCanceled)
        break
      case 'transactionTime':
        setTransactionTime(valueSelected)
        break
      default:
        break
    }
    return value
  }

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="text-muted">Sàn P2P</Breadcrumb.Item>
        <Breadcrumb.Item className="text-black">Lịch sử giao dịch</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Lịch sử giao dịch
        </Col>
      </Row>
      <Radio.Group defaultValue={tabActive} onChange={onChangeTab} size="large" className="mb-3 ml-3">
        <Radio.Button value="depositWithdrawalVND">Nạp/ Rút VND</Radio.Button>
        <Radio.Button value="depositWithdrawalUSDT">Nạp/Rút USDT</Radio.Button>
        <Radio.Button value="swapConvertBRE">Swap/Convert BRE</Radio.Button>
        <Radio.Button value="investmentProjects">Đầu tư dự án</Radio.Button>
        <Radio.Button value="transferOfShares">Chuyển nhượng cổ phần</Radio.Button>
      </Radio.Group>
      <div className="d-flex flex-row mb-4 mt-3">
        <Button
          onClick={() => onSelectedFilter('processed')}
          className={`mr-3 button-section-filter ' + ${(isProcessed ? 'button-section-filter-selected ' : '')}`}
        >
          <img src={isProcessed ? ClockSelected : Clock} className="mr-2" />
          <span>Giao dịch đang chờ xử lý</span>
        </Button>
        <Button
          onClick={() => onSelectedFilter('accomplished')}
          className={`mr-3 button-section-filter ' + ${(isAccomplished ? 'button-section-filter-selected ' : '')}`}
        >
          <img src={isAccomplished ? CheckSelected : Check} className="mr-2" />
          Giao dịch đã hoàn thành
        </Button>
        <Button
          onClick={() => onSelectedFilter('canceled')}
          className={`mr-3 button-section-filter ' + ${(isCanceled ? 'button-section-filter-selected ' : '')}`}
        >
          <img src={isCanceled ? CancelSelected : Cancel} className="mr-2" />
          Giao dịch bị huỷ
        </Button>
        <Select
          defaultValue={null}
          showSearch
          className="mr-3 button-section-filter"
          optionFilterProp="children"
          onSelect={(value) => onSelectedFilter('transactionTime', value)}
        >
          <Option value={null}>Thời gian</Option>
          <Option value="1">12:00 - 14:00</Option>
          <Option value="2">14:00 - 16:00</Option>
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <Row className="d-flex justify-content-center align-items-center">
        <Pagination
          showSizeChanger={false}
          onChange={getData}
          defaultCurrent={1}
          total={dataExample.length}
          itemRender={itemRender}
          className="mt-4 pt-3 pagination"
        />
      </Row>
    </>
  )
}

export default TransactionHistory
