import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (page, limit, search, statusReferral, typeReferral) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.REFERRAL_GET_LIST, {
      filter: { status: statusReferral, type: typeReferral },
      search,
      page,
      limit,
    }, {

      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getInfoReferral = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.REFERRAL_INFO, { filter: { status: 'C', type: 'R' } }, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const referralConfig = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.REFERRAL_CONFIG, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getList, referralConfig, getInfoReferral,
}
