import React from 'react'
import {
  Row, Col, Typography, Button,
} from 'antd'
import styled from 'styled-components'

const Avatar = styled.div`
  border: 1px solid black;
  margin: 0px 32px 0px 16px;
  padding: 16px;
  display: flex;
  with: 100%;
  align-items: center;
  min-height: 200px;
  justify-content: center;
`

const Content = styled.div`
  margin: 0px 16px 0px 16px;
  padding: 0px 16px;
`
const Line = styled.div`
  border-bottom: 1px solid #b8b8b8;
  display: block;
  padding: 10px 0px;
  width: 100%;
  max-width: 400px;
`
const Label = styled.div`
  display: inline-block;
  min-width: 150px;
`

const AccountInfo = ({ detailInvestor }) => {
  console.log(detailInvestor)
  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3">
          <Typography.Title level={4}>
            Thông tin tài khoản
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Avatar>
            Profile picture
          </Avatar>
        </Col>
        <Col span={14} className="p-r-10">
          <Content>
            <Line>
              <Label>Tên tài khoản</Label>
              <span>{detailInvestor?.username}</span>
            </Line>
            <Line>
              <Label>Địa chỉ email</Label>
              <span>{detailInvestor?.email}</span>
            </Line>
            {/* <Line>
              <Label>Số điện thoại</Label>
              <span>{detailInvestor?.phoneNumber}</span>
            </Line> */}
            <Line>
              <Label>Mã giới thiệu</Label>
              <span>{detailInvestor?.referral?.code}</span>
            </Line>
            <Line>
              <Label>KYC</Label>
              <span>{detailInvestor?.kyc?.status}</span>
            </Line>
            {/* <Line>
              <Label>Trạng thái tài khoản</Label>
              <span>{detailInvestor?.username}</span>
            </Line> */}
            <Button className="m-t-10" type="primary">
              Đổi lại mật khẩu
            </Button>
          </Content>
        </Col>
      </Row>
    </>
  )
}

export default AccountInfo
