/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Breadcrumb,
  PageHeader,
  Tabs,
  Radio,
  Table,
  Tag,
  Pagination,
  Select, Card,
  Modal, Typography,
  InputNumber,
} from 'antd'
import {
  EyeOutlined, EditOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  Route, Link, BrowserRouter as Router, useHistory,
} from 'react-router-dom'
import moment from 'moment'
import deposit from 'provider/deposit'
import withdraw from 'provider/withdraw'
import { toast } from 'react-toastify'
import Layouts from '../../components/Layouts'
import './index.css'
import Eye from '../../static/svg/eye.svg'
import ArrowLeft from '../../static/svg/arrow-left.svg'
import ArrowRight from '../../static/svg/arrow-right.svg'
import kyc from '../../provider/kyc'

const { TabPane } = Tabs
const { Title } = Typography
const { Option } = Select
const { Search } = Input

const WalletList = () => {
  const history = useHistory()

  const gotoDetailDeposit = (id) => {
    history.push(`wallet/deposit/${id}`)
  }

  const gotoDetailWithdraw = (id) => {
    history.push(`wallet/withdraw/${id}`)
  }
  const columnsDeposit = [
    {
      title: 'ID NĐT',
      dataIndex: '_id',
      render: (id) => (
        <div>
          <span className="text-row">
            {id || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (username) => (
        <div>
          <span className="text-row">
            {username || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'no',
      render: (no) => (
        <div>
          <span className="text-row">
            {no || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'type',
      render: (type) => (
        <span className="text-row">
          {type || '-'}
        </span>
      ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'quantity',
      render: (quantity) => (
        <span className="text-row">
          {quantity || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <div>
          <span className="text-row">
            {moment(createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      ),
      sorter: {
        compare: (a, b) => a.time - b.time,
        multiple: 1,
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (text) => text === 'S' ? (
        <div className="text-right">
          <span className="success">
            Thành công
          </span>
        </div>
      ) : text === 'F' ? (
        <div className="text-right">
          <span className="fail">
            Thất bại
          </span>
        </div>
      ) : text === 'WSV' ? (
        <div className="text-right">
          <span className="waiting">
            Chờ xác nhận
          </span>
        </div>
      ) : '-',
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      render: (id) => (
        // <img src={Eye} className="icon-button" />
        <>
          <Button icon={<EyeOutlined />} type="text" onClick={() => gotoDetailDeposit(id)} />
        </>
      ),
    },
  ]

  const columnsWithdraw = [
    {
      title: 'ID NĐT',
      dataIndex: '_id',
      render: (id) => (
        <div>
          <span className="text-row">
            {id || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (username) => (
        <div>
          <span className="text-row">
            {username || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'no',
      render: (no) => (
        <div>
          <span className="text-row">
            {no || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'type',
      render: (type) => (
        <span className="text-row">
          {type || '-'}
        </span>
      ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'quantity',
      render: (quantity) => (
        <span className="text-row">
          {quantity || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <div>
          <span className="text-row">
            {moment(createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      ),
      sorter: {
        compare: (a, b) => a.time - b.time,
        multiple: 1,
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (text) => text === 'S' ? (
        <div className="text-right">
          <span className="success">
            Thành công
          </span>
        </div>
      ) : text === 'F' ? (
        <div className="text-right">
          <span className="fail">
            Thất bại
          </span>
        </div>
      ) : text === 'WSV' ? (
        <div className="text-right">
          <span className="waiting">
            Chờ xác nhận
          </span>
        </div>
      ) : '-',
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      render: (id) => (
        // <img src={Eye} className="icon-button" />
        <>
          <Button icon={<EyeOutlined />} type="text" onClick={() => gotoDetailWithdraw(id)} />
        </>
      ),
    },
  ]
  const [form] = Form.useForm()
  const [dataDeposit, setDataDeposit] = useState([])
  const [dataWithdraw, setDataWithdraw] = useState([])
  const [info, setInfo] = useState(null)
  const [statusSelected, setStatusSelected] = useState(null)
  const [total, setTotal] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [modalRatioReferral, setModalRatioReferral] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const [queryDeposit, setQueryDeposit] = useState({
    currency: 'VND',
    page: 1,
    limit: 10,
    userId: 'all',
  })
  const [queryWithdraw, setQueryWithdraw] = useState({
    currency: 'VND',
    page: 1,
    limit: 10,
    userId: 'all',
  })

  const getListDeposit = async (params) => {
    setLoading(true)
    try {
      const resp = await deposit.getListDeposit(params)
      setLoading(false)
      setDataDeposit(resp.data)
      setQueryDeposit({ page: 1, limit: 10 })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getListWithdraw = async (params) => {
    setLoading(true)
    try {
      const resp = await withdraw.getListWithdraw(params)
      setLoading(false)
      setDataWithdraw(resp.data)
      setQueryWithdraw({ page: 1, limit: 10 })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getListDeposit(queryDeposit)
    getListWithdraw(queryWithdraw)
    return () => {
    }
  }, [])

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="pagination-link">
          <img src={ArrowLeft} />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="pagination-link">
          <img src={ArrowRight} />
        </div>
      )
    }
    return originalElement
  }

  const onSelectStatus = (value) => {
    setStatusSelected(value)
    getListDeposit(1, 10, value)
    console.log(value)
    return value
  }

  const onSearch = (value) => {
    getListDeposit(1, 10, statusSelected, value)
    console.log(1, 10, statusSelected, value)
  }

  const onSubmitFailed = (error) => {
  }

  const clearRatioReferral = () => {
    setDefaultRatioReferral({
      registration: '',
      // ido_investment: 0,
    })
    setModalRatioReferral(false)
  }
  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý Nạp/ Rút
        </Col>
      </Row>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Nạp" key="1">

          <div className="d-flex flex-row mb-4 mt-3">
            <Search placeholder="Vui lòng nhập từ khóa" onSearch={onSearch} className="mr-5" />
            <Select
              defaultValue=""
              optionFilterProp="children"
              onSelect={(value) => onSelectStatus(value)}
              style={{ width: 300 }}
              className="mr-5"
            >
              <Option value="">Tất cả</Option>
              <Option value="C">Đã cộng</Option>
              <Option value="P">Chờ cộng</Option>

            </Select>
          </div>
          <Table
            columns={columnsDeposit}
            dataSource={dataDeposit}
            pagination={false}
            loading={loading}
          />
          <Row className="d-flex justify-content-center align-items-center">
            <Pagination
              showSizeChanger={false}
              onChange={getListDeposit}
              defaultCurrent={currentPage}
              total={total}
              itemRender={itemRender}
              className="mt-4 pt-3 pagination"
            />
          </Row>
        </TabPane>
        <TabPane tab="Rút" key="2">
          <div className="d-flex flex-row mb-4 mt-3">
            <Search placeholder="Vui lòng nhập từ khóa" onSearch={onSearch} className="mr-5" />
            <Select
              defaultValue=""
              optionFilterProp="children"
              onSelect={(value) => onSelectStatus(value)}
              style={{ width: 300 }}
              className="mr-5"
            >
              <Option value="">Tất cả</Option>
              <Option value="C">Đã cộng</Option>
              <Option value="P">Chờ cộng</Option>

            </Select>
          </div>
          <Table
            columns={columnsWithdraw}
            dataSource={dataWithdraw}
            pagination={false}
            loading={loading}
          />
          <Row className="d-flex justify-content-center align-items-center">
            <Pagination
              showSizeChanger={false}
              onChange={getListWithdraw}
              defaultCurrent={currentPage}
              total={total}
              itemRender={itemRender}
              className="mt-4 pt-3 pagination"
            />
          </Row>
        </TabPane>
      </Tabs>

    </>
  )
}

export default WalletList
