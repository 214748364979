/* eslint-disable no-underscore-dangle */
import React from 'react'
import {
  Row, Col, Input, Select, Button, Table, Image, Modal, Avatar,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import { useDeleteProject, useProjectList } from 'hooks/project'
import { PROJECT_STATUS, PROJECT_STATUS_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { EyeOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Duration } from 'luxon'
import { addToQueryString, fromQueryString } from '../../utils/url'
import noImage from '../../static/images/no-image.png'

const STATUS_COLORS = {
  [PROJECT_STATUS.PC]: 'success',
  [PROJECT_STATUS.P]: 'pending',
  [PROJECT_STATUS.FN]: 'fail',
  [PROJECT_STATUS.CL]: 'waiting',
  [PROJECT_STATUS.RF]: 'fail',
  [PROJECT_STATUS.F]: 'fail',
}

const ProjectList = () => {
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()

  const { data, isFetching, refetch } = useProjectList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    keywords: parsedQueryString.keywords || '',
    type: parsedQueryString.type || '',
    status: parsedQueryString.status || '',
  })

  const mutationDelete = useDeleteProject()

  const onSearch = (keywords) => {
    history.replace({
      search: addToQueryString(location.search, { keywords }),
    })
  }

  const onChangeType = (type) => {
    history.replace({
      type: addToQueryString(location.type, { type }),
    })
  }

  const onChangeStatus = (status) => {
    history.replace({
      status: addToQueryString(location.status, { status }),
    })
  }

  const onView = (id) => {
    history.push(`/project/view/${id}`)
  }

  const onCreateNew = () => history.push('/project/create')

  const onDelete = (projectId) => {
    Modal.confirm({
      content: 'Bạn có muốn xoá dự án này không?',
      onOk: () => mutationDelete.mutate(projectId, {
        onSuccess: () => {
          Modal.success({
            content: 'Xoá dự án thành công',
            onOk: () => refetch(),
          })
        },
      }),
    })
  }

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  const columns = [
    {
      title: 'Ảnh đại diện',
      dataIndex: 'avatar',
      render: (id) => (
        <Image src={id} width={70} height={55} fallback={noImage} style={{ borderRadius: 4 }} />
      ),
    },
    {
      title: 'Mã BĐS',
      dataIndex: 'no',
      render: (id) => (
        <div>
          <span className="text-row">
            {id}
          </span>
        </div>
      ),
    },
    {
      title: 'Loại BĐS',
      dataIndex: 'classification',
      render: (id) => (
        <div>
          <span className="text-row">
            {PROJECT_TYPE_NAMES[id] || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Địa chỉ',
      dataIndex: ['project_info', 'address'],
      render: (address) => (
        <span className="text-row">
          {[address?.line1, address?.line2, address?.district, address?.city].filter((s) => !!s).join(', ') || '-'}
        </span>
      ),
    },
    {
      title: 'Vốn đầu tư(tỷ)',
      dataIndex: ['stock_info'],
      render: (stock) => (
        <span className="text-row">
          {stock ? stock?.ido_price * stock?.total_supply / 1000000000 || '-' : '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian đầu tư(tháng)',
      dataIndex: ['time_config', 'invest_duration'],
      render: (id) => (
        <div>
          <span className="text-row">
            {Duration.fromMillis(id || 0).toFormat('M', {
              floor: false,
            })}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (id) => (
        <div>
          <span className={`text-row ${STATUS_COLORS[id]}`}>
            {PROJECT_STATUS_NAMES[id] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      render: (id) => (
        <>
          {/* <Button icon={<EyeOutlined />} type="text" onClick={() => onView(id)} /> */}
          <Button icon={<EditOutlined />} type="text" onClick={() => onView(id)} />
          <Button icon={<DeleteFilled />} type="text" onClick={() => onDelete(id)} />
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý dự án Bất động sản
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5" onSearch={onSearch} />
        <Select
          placeholder="Loại BĐS"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeType}
          allowClear
        >
          {Object.keys(PROJECT_TYPE_NAMES).map((p) => (
            <Select.Option value={p}>{PROJECT_TYPE_NAMES[p]}</Select.Option>
          ))}
        </Select>
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(PROJECT_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{PROJECT_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-row mb-4 mt-3">
        <Button
          className="mr-3 button-section-filter"
          type="primary"
          onClick={onCreateNew}
        >
          <span>Thêm dự án mới</span>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data?.projects || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: data?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetching || mutationDelete.isLoading}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default ProjectList
