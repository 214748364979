import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { Route, Link, useHistory } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout, Modal, Typography,
} from 'antd'
import OtpInput from 'react-otp-input'
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons'

import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'
import Header from '../../layout/header'
import emailSuccess from '../../../static/images/email-success.png'

const { Title } = Typography

const SignIn = ({
  user,
  getInfo,
  login,
  register,
  verifyOTP,
  resendOTP,
}) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [defaultEmail, setDefaultEmail] = useState({ email: '' })
  const [modalResendEmail, setModalResendEmail] = useState(false)
  const [modalOtp, setModalOtp] = useState(false)
  const [modalOtpSuccess, setModalOtpSuccess] = useState(false)
  const [otp, setOTP] = useState('')
  // const [email, setEmail] = useState('')
  const [userId, setUserId] = useState('')

  const handleChangeOtp = (otpStr) => {
    setOTP(otpStr)
  }

  const handleVerifyOtp = () => {
    const { email } = defaultEmail
    verifyOTP({ otp, email, userId }, () => {
      setModalOtp(false)
      setModalOtpSuccess(true)
    })
  }

  const clearOTP = () => {
    setModalOtp(false)
    setOTP('')
  }

  const handleResendOtp = (data) => {
    resendOTP(data, (resp) => {
      setUserId(resp?.data?.user_id)
      setModalResendEmail(false)
      setModalOtp(true)
    })
    setDefaultEmail({ email: data.email })
  }

  const handleResendOtpLink = () => {
    const { email } = defaultEmail
    resendOTP({ email })
  }

  useEffect(() => {
    if (user?.data?.username) {
      history.push('/kyc')
    }
  }, [user?.data])

  const clearEmail = () => {
    setDefaultEmail({ email: '' })
    setModalResendEmail(false)
  }

  const onSubmit = (data) => {
    login(data)
  }
  useEffect(() => {
    form.setFieldsValue(defaultEmail)
  }, [form, defaultEmail])

  useEffect(() => {
    if (user?.data?.status === 406) {
      setModalResendEmail(true)
    }
  }, [user])

  const onSubmitFailed = (error) => {
  }

  return (
    <Layout title="login" className="login">
      {/* <Header /> */}
      {/* Modal Otp Success */}
      <Modal
        footer={null}
        className="modalOtp"
        visible={modalOtpSuccess}
        onCancel={() => setModalOtpSuccess(false)}
        onOk={() => setModalOtpSuccess(false)}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Xác minh thông tin thành công!
        </Title>
        <p style={{ marginBottom: 32 }}>
          Bạn đã tạo thành công tài khoản tại Quesera, vui lòng đăng nhập để
          tiếp tục
        </p>
        <p style={{ marginBottom: 32, textAlign: 'center' }}>
          <img src={emailSuccess} />
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => console.log()}
          >
            <Link to="/">Đăng nhập tài khoản</Link>
          </Button>
        </p>
        <p>
          <Button
          // type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => console.log()}
          >
            Trở về trang chủ
          </Button>
        </p>
      </Modal>

      {/* Modal Resend Email */}
      <Modal
        className="modalResendEmail"
        footer={null}
        visible={modalResendEmail}
        onCancel={() => clearEmail()}
        onOk={() => clearEmail()}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Nhập email để nhận mã OTP
        </Title>
        <Form
          form={form}
          layout="vertical"
          // className="login-form"
          initialValues={defaultEmail}
          onFinish={handleResendOtp}
          onFinishFailed={onSubmitFailed}
        >
          <Form.Item className="item-form" name="email" label="Email" rules={[{ required: true }]}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Nhập Email"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block m-t-15"
            size="large"
          >
            Resend OTP
          </Button>
        </Form>
      </Modal>

      {/* Modal Otp */}
      <Modal
        className="modalOtp"
        footer={null}
        visible={modalOtp}
        onCancel={() => clearOTP()}
        onOk={() => clearOTP()}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Xác minh địa chỉ email
        </Title>
        <p style={{ marginBottom: 32 }}>
          Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
        </p>
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          separator=""
          inputStyle={{
            height: 60,
            width: 50,
            marginRight: 4,
            marginLeft: 4,
            background: '#F5F5F5',
            border: '2px solid #F5F5F5',
            borderRadius: 4,
            marginBottom: 32,
          }}
          containerStyle={{ justifyContent: 'center' }}
          focusStyle={{ border: '2px solid #cf9900' }}
        />
        <p style={{ marginBottom: 32, color: '#121212' }}>
          Nhập mã xác minh gồm 6 chữ số được gửi đến địa chỉ email:
          {' '}
          <span style={{ fontWeight: 500 }}>{defaultEmail.email}</span>
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            disabled={otp.length < 6}
            onClick={handleVerifyOtp}
          >
            Xác nhận
          </Button>
        </p>
        <p style={{ marginBottom: 5, textAlign: 'center' }}>
          Bạn chưa nhận được email?
        </p>
        <div className="text-center">
          <a href="javascript:void(0)" onClick={handleResendOtpLink}>
            Gửi lại mã xác nhận
          </a>
        </div>
      </Modal>

      <Row>
        <Col md={{ span: 12, order: 1 }} span={24} order={2} className="loginForm">
          <div className="login-container">
            <div className="text-left">
              {/* <img src={logo} /> */}
              <h1 className="m-b-15 m-t-15">
                <FormattedMessage id="signin_title" />
              </h1>
            </div>
            <Form
              layout="vertical"
              className="login-form"
              initialValues={{
                username: '',
                password: '',
              }}
              onFinish={onSubmit}
              onFinishFailed={onSubmitFailed}
            >
              <Form.Item className="item-form" name="username" label="Tên đăng nhập" rules={[{ required: true }]}>
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Nhập tên đăng nhập"
                />
              </Form.Item>
              <Form.Item className="item-form" name="password" label="Mật khẩu" rules={[{ required: true }]}>

                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Nhập mật khẩu"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox>Ghi nhớ</Checkbox>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-block m-t-15"
                  size="large"
                >
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col md={{ span: 12, order: 2 }} span={24} order={1}>
          <div className="banner-container" />
        </Col>
      </Row>
    </Layout>
  )
}

SignIn.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
  login: PropTypes.func,
  register: PropTypes.func,
  verifyOTP: PropTypes.func,
  resendOTP: PropTypes.func,
}

export default SignIn
