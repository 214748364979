import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (page, limit, symbolVoting) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.VOTING_GET_LIST, {
      page,
      limit,
      query: { symbol: symbolVoting },
      history: true,
    }, {
      // params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getDetailVoting = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.REFERRAL_INFO, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const createVoting = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.VOTING_CREATE, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const cancelVoting = async (voteId) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.VOTING_CANCEL, {
      index: voteId,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getList, createVoting, getDetailVoting, cancelVoting,
}
