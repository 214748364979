/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import {
  Row, Col, Input, Select, Button, Table,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import { useInvestorList } from 'hooks/investor'
import { EyeOutlined } from '@ant-design/icons'
import { DateTime } from 'luxon'
import {
  INVESTOR_STATUS, INVESTOR_STATUS_NAMES, KYC_STATUS, KYC_STATUS_NAMES, STATUS_KYC_INVESTOR_COLORS, STATUS_INVESTOR_COLORS,
} from 'constants/investor'
import { addToQueryString, fromQueryString } from '../../../utils/url'

const InvestorList = () => {
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const statusKycInvestor = parsedQueryString.statusKyc
  const investorStatus = parsedQueryString.statusInvestor
  const history = useHistory()

  const onView = (id) => {
    history.push(`/investor/${id}`)
  }

  const { data, isFetching } = useInvestorList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    key_search: parsedQueryString.search || '',
  }, statusKycInvestor, investorStatus)

  const onSearch = (search) => {
    history.replace({
      search: addToQueryString(location.search, { search }),
    })
  }

  const onChangeStatusKyc = (statusKyc) => {
    history.replace({
      search: addToQueryString(location.search, { statusKyc }),
    })
  }

  const onChangeStatus = (statusInvestor) => {
    history.replace({
      search: addToQueryString(location.search, { statusInvestor }),
    })
  }

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (id) => (
        <span className="text-row">
          {id}
        </span>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (username) => (
        <span className="text-row">
          {username}
        </span>
      ),
    },
    {
      title: 'Địa chỉ email',
      dataIndex: 'email',
      render: (email) => (
        <span className="text-row">
          {email}
        </span>
      ),
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'referral',
      render: (referral) => (
        <span className="text-row">
          {referral?.code}
        </span>
      ),
    },
    {
      title: 'Thời gian đăng ký',
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <span className="text-row">
          {createdAt ? DateTime.fromISO(createdAt).toFormat('HH:mm dd/MM/yyyy') : ''}
        </span>
      ),
    },
    {
      title: 'KYC',
      dataIndex: 'kyc',
      render: (kyc) => (
        <div>
          <span className={`text-row ${STATUS_KYC_INVESTOR_COLORS[kyc.status]}`}>
            {KYC_STATUS_NAMES[kyc.status] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${STATUS_INVESTOR_COLORS[status]}`}>
            {INVESTOR_STATUS_NAMES[status] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      render: (id) => (
        <>
          <Button icon={<EyeOutlined />} type="text" onClick={() => onView(id)} />
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý Nhà đầu tư
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5" onSearch={onSearch} />
        <Select
          placeholder="KYC"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatusKyc}
          allowClear
        >
          {Object.keys(KYC_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{KYC_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(INVESTOR_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{INVESTOR_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data?.users || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: data?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetching}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default InvestorList
