import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber, DatePicker,
  Modal, Typography,
} from 'antd'
import {
  UserOutlined,
  WalletOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import {
  Link, Switch, Route, Redirect,
} from 'react-router-dom'
import { matchPath, useLocation } from 'react-router'
import styled from 'styled-components'
import UploadFile from 'components/upload'
import deposit from 'provider/deposit'
import moment from 'moment'
import { toast } from 'react-toastify'

const { Title } = Typography

const DetailDeposit = ({ depositId }) => {
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [defaultDeposit, setDefaultDeposit] = useState({
    currency: '', no: '', bank_type: '', number_card: '', quantity: 0, createdAt: moment(),
  })
  const [defaultRefuse, setDefaultRefuse] = useState({
    note: '',
  })
  const [modalConfirm, setModalConfirm] = useState(false)
  const [modalRefuse, setModalRefuse] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataDeposit, setDataDeposit] = useState({})

  const getInfoDeposit = async (id) => {
    setLoading(true)
    try {
      setLoading(false)
      const resp = await deposit.getInfoDeposit({ currency: 'VND', id })
      setDataDeposit(resp.data)
      setDefaultDeposit({
        ...defaultDeposit,
        ...{ currency: resp.data.currency },
        ...{ no: resp.data.no },
        ...{ bank_type: resp.data.to.bank_type },
        ...{ number_card: resp.data.to.number_card },
        ...{ quantity: resp.data.quantity },
        ...{ createdAt: moment(resp.data.createdAt) },

      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSubmit = async () => {
    const data = { currency: dataDeposit.currency, no: dataDeposit.no, status: 'S' }
    try {
      const resp = await deposit.confirmDeposit(data)
      if (resp.code === 200) {
        toast.success('Xác nhận giao dịch thành công')
        setModalConfirm(false)
      } else {
        toast.error(resp.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onRefuse = async (values) => {
    const data = {
      currency: dataDeposit.currency, no: dataDeposit.no, status: 'F', note: values.note,
    }
    try {
      const resp = await deposit.confirmDeposit(data)
      if (resp.code === 200) {
        toast.success('Từ chối giao dịch thành công')
        setModalRefuse(false)
      } else {
        toast.error(resp.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInfoDeposit(depositId)
    return () => {
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(defaultDeposit)
  }, [form, defaultDeposit])

  useEffect(() => {
    form1.setFieldsValue(defaultRefuse)
  }, [form1, defaultRefuse])

  return (
    <>
      {/* Modal Confirm */}
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalConfirm}
        onOk={() => setModalConfirm(false)}
        onCancel={() => setModalConfirm(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Xác nhận giao dịch
        </Title>
        <p>
          Xác nhận đã nhận tiền từ người dùng và thực hiện
          lệnh nạp tiền vào tài khoản của người dùng.
        </p>
        <div className="block right">
          <Button
            className=" m-t-15 mr-2"
            htmlType="submit"
            onClick={() => setModalConfirm(false)}
          >
            Hủy
          </Button>
          <Button
            type="primary"
            className=" m-t-15"
            htmlType="button"
            onClick={onSubmit}
          >
            Xác nhận
          </Button>
        </div>
      </Modal>

      {/* Modal Refuse */}
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalRefuse}
        onOk={() => setModalRefuse(false)}
        onCancel={() => setModalRefuse(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Từ chối giao dịch
        </Title>
        <Form
          form={form1}
          layout="vertical"
          // className="login-form"
          initialValues={defaultRefuse}
          onFinish={onRefuse}
        >

          <Form.Item name="note" className="mb-0">
            <Input.TextArea placeholder="Nhập lý do từ chối giao dịch" />
          </Form.Item>
          <Form.Item className="block right">
            <Button
              className=" m-t-15 mr-2"
              htmlType="button"
              onClick={() => setModalRefuse(false)}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              className=" m-t-15"
              htmlType="submit"
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Form>

      </Modal>

      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý dự án bất động sản</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Chi tiết giao dịch</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Chi tiết giao dịch
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultDeposit}
        autoComplete="off"
        scrollToFirs
        tError
        // onFinish={onSubmit}
      >

        <Row gutter={32}>
          <Col span={12}>
            {/* <Form.Item
              label="Loại giao dịch"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Người gửi"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Số tài khoản người gửi"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Ngân hàng"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item> */}
            {/* <Form.Item
              label="Người nhận"
              name="timeSale"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" />
            </Form.Item> */}
            <Form.Item
              label="Số tài khoản người nhận"
              name="number_card"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Ngân hàng"
              name="bank_type"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            {/* <Form.Item
              label="Nội dung giao dịch"
              name="note"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item> */}
            <Form.Item
              label="Số tiền"
              name="quantity"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
            <Form.Item
              label="Thời gian giao dịch"
              name="createdAt"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian mở bán cổ phần!' }]}
            >
              <Input placeholder="" className="w-100" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <div className="w-100">
              <h5>Bằng chứng thanh toán</h5>
            </div>
            <Form.Item name="avatar">
              <UploadFile
                accept="image/*"
                // customRequest={(options) => projectAPIs.updateAvatar(projectId, options)}
                listType="picture-card"
                className="image-upload-grid"
              />
            </Form.Item> */}

          </Col>
        </Row>

        <Row>
          <Form.Item className="block text-center">
            <Button style={{ margin: '0 8px' }} htmlType="button" onClick={() => setModalRefuse(true)}>
              Từ chối
            </Button>
            <Button
              htmlType="button"
              type="primary"
              className="m-t-15"
              onClick={() => setModalConfirm(true)}
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  )
}

export default DetailDeposit
