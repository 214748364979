import React from 'react'

import UploadFile from 'components/upload'
import { Space, Image } from 'antd'
import projectAPIs from '../../../provider/project'

const UploadFiles = ({
  value, onChange, projectNo, disabled, ...props
}) => {
  const onUploadImages = ({ fileList }) => {
    const responses = (fileList || []).map((f) => f.response?.data).filter((f) => f?.code === 200).map((f) => f.msg)
    onChange((value || []).concat(responses))
  }
  const images = value || []
  return (
    <>
      <Space direction="horizontal" className="m-b-10">
        {images.map((img, idx) => (
          <div
            className={idx > 0 ? 'm-l-20' : ''}
            key={`${img}${idx}`}
          >
            <Image
              height={200}
              width={300}
              src={img}
              preview={{ visible: false }}
              style={{ objectFit: 'contain' }}
            />
          </div>
        ))}
      </Space>
      {!disabled ? (
        <div className="m-b-5">
          <UploadFile
            {...props}
            multiple
            accept="image/*"
            customRequest={(options) => projectAPIs.updateDoc(projectNo, options)}
            listType="picture-card"
            className="image-upload-grid"
            showUploadList={false}
            onChange={onUploadImages}
          />
        </div>
      ) : ''}
    </>
  )
}

export default UploadFiles
