/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Breadcrumb,
  PageHeader,
  Tabs,
  Radio,
  Table,
  Tag,
  Pagination,
  Select, Card,
  Modal, Typography,
  InputNumber,
} from 'antd'
import {
  EyeOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  Route, Link, BrowserRouter as Router, useHistory, useLocation,
} from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import { REFERRAL_STATUS_NAMES, STATUS_REFERRAL_COLORS } from 'constants/referral'
import { useConfigReferral, useReferralDetail, useReferralList } from 'hooks/referral'
import Layouts from '../../components/Layouts'
import './index.css'
import { addToQueryString, fromQueryString } from '../../utils/url'

const { Title } = Typography
const { Option } = Select
const { Search } = Input

const ReferralList = () => {
  const history = useHistory()
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const mutationConfig = useConfigReferral()

  const { data: dataListReferral, isFetching: isFetchingListReferral } = useReferralList({
    page: parsedQueryString.page || 1,
    limit: parsedQueryString.limit || 10,
    status: parsedQueryString.status,
    type: parsedQueryString.type,
    search: parsedQueryString.search,
  })

  const { data: dataInfoReferral } = useReferralDetail()

  const onSearch = (search) => {
    history.replace({
      search: addToQueryString(location.search, { search }),
    })
  }

  const onChangeStatus = (status) => {
    history.replace({
      search: addToQueryString(location.search, { status }),
    })
  }

  const onChangeType = (type) => {
    history.replace({
      search: addToQueryString(location.search, { type }),
    })
  }

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }
  const gotoDetail = (referralId) => {
    console.log('user', referralId)
    history.push(`/referral/${referralId}`)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (id) => (
        <div>
          <span className="text-row">
            {id || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'to',
      render: (to) => (
        <div>
          <span className="text-row">
            {to?.username || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Địa chỉ email',
      dataIndex: 'to',
      render: (to) => (
        <div>
          <span className="text-row">
            {to?.email || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'to',
      render: (to) => (
        <span className="text-row">
          {to?.phone || '-'}
        </span>
      ),
    },
    {
      title: 'Số token BRE nhận được',
      dataIndex: 'amount',
      render: (amount) => (
        <span className="text-row">
          {amount || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <div>
          <span className="text-row">
            {moment(createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      ),
      sorter: {
        compare: (a, b) => a.time - b.time,
        multiple: 1,
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${STATUS_REFERRAL_COLORS[status]}`}>
            {REFERRAL_STATUS_NAMES[status] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'to',
      render: (to) => (
        // <img src={Eye} className="icon-button" />
        <Button shape="circle" icon={<EyeOutlined />} onClick={() => gotoDetail(to._id)} />
      ),
    },
  ]
  const [defaultRatioReferral, setDefaultRatioReferral] = useState({
    registration: 0,
    // ido_investment: 0,
  })
  const [form] = Form.useForm()
  const [modalRatioReferral, setModalRatioReferral] = useState(false)

  useEffect(() => {
    form.setFieldsValue(defaultRatioReferral)
  }, [form, defaultRatioReferral])

  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
  })

  const configReferralSuccess = () => {
    setModalRatioReferral(false)
    toast.success('Nhập tỉ lệ referral thành công')
  }

  const onSubmit = (referral) => {
    const mapData = mapSubmitData(referral)
    mutationConfig.mutate(mapData, {
      onSuccess: (p) => {
        if (p.code === 200) {
          configReferralSuccess()
        } else { toast.error(p.msg) }
      },
    })
  }

  return (
    <>
      <Modal
        className="modalResendEmail"
        footer={null}
        visible={modalRatioReferral}
        onCancel={() => setModalRatioReferral(false)}
        onOk={() => setModalRatioReferral(false)}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Nhập tỉ lệ referral
        </Title>
        <Form
          form={form}
          layout="vertical"
          // className="login-form"
          initialValues={defaultRatioReferral}
          onFinish={onSubmit}
        >
          <Form.Item className="item-form" name="registration" label="Tỉ lệ referral" rules={[{ required: true }]}>
            <InputNumber min={1} max={10} placeholder="Nhập tỉ lệ referral" className="w-100" />
          </Form.Item>
          <Form.Item className="text-end mb-0">
            <Button
              type="primary"
              className="m-t-15 mr-2"
              htmlType="submit"
            >
              Xác nhận
            </Button>
            <Button
              className=" m-t-15"
              htmlType="submit"
              onClick={() => setModalRatioReferral(false)}
            >
              Hủy
            </Button>
          </Form.Item>

        </Form>
      </Modal>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý Referral
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card bordered={false}>
            <div className="card">
              <div className="card-info">
                <p className="text-center mb-auto">
                  Số Token nhận được khi giới thiệu thành công
                </p>
                <h3 className="number">
                  {dataInfoReferral?.totalRewardedBre}
                </h3>
              </div>
            </div>
          </Card>
        </Col>

        <Col span={8}>
          <Card bordered={false}>
            <div className="card">
              <div className="card-info">
                <p className="text-center mb-auto">
                  Tổng số NĐT đã nhận BRE
                </p>
                <h3 className="number">
                  {dataInfoReferral?.totalRewardedUsers}
                </h3>
              </div>
            </div>
          </Card>
        </Col>

        <Col span={8}>
          <Card bordered={false}>
            <div className="card">
              <div className="card-info">
                <p className="text-center mb-auto">
                  Tổng số token BRE đã tặng
                </p>
                <h3 className="number">
                  {dataInfoReferral?.totalRewardedBre}
                </h3>
              </div>
            </div>
          </Card>

        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Search placeholder="Vui lòng nhập từ khóa" onSearch={onSearch} className="mr-5" />
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          style={{ width: 300 }}
          className="mr-5"
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(REFERRAL_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{REFERRAL_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
        {/* <Select
          defaultValue=""
          optionFilterProp="children"
          onSelect={(value) => onChangeStatus(value)}
          style={{ width: 300 }}
          className="mr-5"
        >
          <Option value="">Tất cả</Option>
          <Option value="C">Đã cộng</Option>
          <Option value="P">Chờ cộng</Option>
        </Select> */}
        <Button type="primary" onClick={() => setModalRatioReferral(true)}>Tỷ lệ Referral</Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataListReferral?.rewards || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: dataListReferral?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetchingListReferral}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default ReferralList
