import axios from 'axios'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (param, statusKyc, statusInvestor) => {
  const { KEYS } = storage
  const resp = await axios.put(ENDPOINT.INVESTOR_GET_LIST, { filter: { 'kyc.status': statusKyc, status: statusInvestor } }, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const getDetail = async (param) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_DETAIL, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const getListWalletInvestor = async (userId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_LIST_WALLET, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListProductInvestor = async (data) => {
  const { KEYS } = storage

  const resp = await axios.put(ENDPOINT.INVESTOR_LIST_PRODUCT, {
    query: {
      'project.status': data.statusProject,
    },
  }, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: data.investorId,
    },
  })
  return resp.data
}

const getListBankAccountInvestor = async (userId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_LIST_BANK_ACCOUNT, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListTransactionInvestorP2P = async (param, userId, statusTransaction) => {
  const { KEYS } = storage
  const resp = await axios.put(ENDPOINT.INVESTOR_LIST_TRANSACTION_P2P, {
    query: {
      status: statusTransaction,
    },
  },
  {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListTransactionInvestorIdo = async (param, userId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_LIST_TRANSACTION_IDO, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListTransactionInvestorSwap = async (param, userId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_LIST_TRANSACTION_SWAP, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListActivityInvestor = async (userId) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.INVESTOR_LIST_ACTIVITY, {
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

const getListReferralInvestor = async (param, userId, statusKyc, statusInvestor) => {
  const { KEYS } = storage
  const resp = await axios.put(ENDPOINT.INVESTOR_LIST_REFERRAL, { filter: { 'kyc.status': statusKyc, status: statusInvestor } }, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      as_user: userId,
    },
  })
  return resp.data
}

export default {
  getList,
  getDetail,
  getListWalletInvestor,
  getListProductInvestor,
  getListBankAccountInvestor,
  getListTransactionInvestorP2P,
  getListTransactionInvestorIdo,
  getListTransactionInvestorSwap,
  getListActivityInvestor,
  getListReferralInvestor,
}
