/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import {
  Row, Col, Input, Select, Button, Table, Image, Modal, Avatar, Typography,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import {
  useVotingList, useVotingDetail, useCancelVoting,
} from 'hooks/voting'
import { VOTING_STATUS_NAMES, STATUS_VOTING_COLORS } from 'constants/voting'
import { EyeOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons'
import { Duration } from 'luxon'
import styled from 'styled-components'
import moment from 'moment'
import { addToQueryString, fromQueryString } from '../../utils/url'
import noImage from '../../static/images/no-image.png'

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`
const Voting = styled.span`
  color: rgb(229, 57, 53);
  background-color: rgba(38, 50, 56, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.1);
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 400;
  word-break: break-word;
`

const { Title } = Typography

const VotingList = () => {
  const [modalVotingDetail, setModalVotingDetail] = useState(false)
  const [infoDetailVoting, setInfoDetailVoting] = useState({})
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()
  const mutationCancel = useCancelVoting()

  const { data, isFetching } = useVotingList({
    page: parsedQueryString.page || 1,
    limit: parsedQueryString.limit || 10,
    symbol: parsedQueryString.symbol,
    // keywords: parsedQueryString.keywords || '',
    // status: parsedQueryString.status || '',
  })

  const onSearch = (symbol) => {
    history.replace({
      search: addToQueryString(location.search, { symbol }),
    })
  }

  const onChangeStatus = (status) => {
    history.replace({
      search: addToQueryString(location.search, { status }),
    })
  }

  const onCreateVote = () => history.push('/voting/create')

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  const onCancelVoting = (voteId) => {
    Modal.confirm({
      content: 'Bạn có muốn hủy bài vote này không?',
      onOk: () => mutationCancel.mutate(voteId, {
        onSuccess: () => {
          Modal.success({
            content: 'Hủy bài vote thành công',
            onOk: () => refetch(),
          })
        },
      }),
    })
  }

  const columns = [
    {
      title: 'ID bài vote',
      dataIndex: 'index',
      render: (id) => (
        <div>
          <span className="text-row">
            {id || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Sản phẩm được vote',
      dataIndex: 'symbol',
      render: (symbol) => (
        <div>
          <span className="text-row">
            {symbol || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Thời gian bán',
      render: (row) => (
        <div>
          <span className="text-row">
            {`${moment.unix(row?.start).format('MM/DD/YYYY HH:mm:ss')} -> ${moment.unix(row?.end).format('MM/DD/YYYY HH:mm:ss')}` || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Giá trị bán',
      dataIndex: 'offerValue',
      render: (offerValue) => (
        <div>
          <span className="text-row">
            {offerValue || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Mốc giá trị',
      dataIndex: 'value',
      render: (stock) => (
        <span className="text-row">
          {stock || '-' }
        </span>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: ['stockInfo', 'createdAt'],
      render: (createdAt) => (
        <div>
          <span className="text-row">
            {moment(createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isCanceled',
      render: (isCanceled) => (
        <div>
          <span className={`text-row ${STATUS_VOTING_COLORS[isCanceled]}`}>
            {VOTING_STATUS_NAMES[isCanceled] || '-'}
          </span>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      render: (row) => (
        <>
          {/* <Button icon={<EyeOutlined />} type="text" onClick={() => onView(id)} /> */}
          <Button icon={<EyeOutlined />} type="text" onClick={() => goToDetailVoting(row)} />
          <Button icon={<DeleteFilled />} type="text" onClick={() => onCancelVoting(row?.index)} />
        </>
      ),
    },
  ]

  const goToDetailVoting = (currentData) => {
    console.log(currentData)
    setInfoDetailVoting(currentData)
    setModalVotingDetail(true)
  }

  return (
    <>
      <Modal
        footer={null}
        visible={modalVotingDetail}
        onOk={() => setModalVotingDetail(false)}
        onCancel={() => setModalVotingDetail(false)}
      >
        <>
          <Title level={3} className="font-weight-500 mb-3">
            Chi tiết giao dịch
          </Title>
          <DescriptionText>
            <DescriptionTextLeft>ID bài vote:</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2">1</span>
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Sản phẩm được vote:</DescriptionTextLeft>
            <DescriptionTextRight>
              SPA
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Thời gian dự tính bán:</DescriptionTextLeft>
            <DescriptionTextRight>
              30/10/2022
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Giá trị dự tính bán:</DescriptionTextLeft>
            <DescriptionTextRight>
              500.000.000 VND
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Mốc giá trị:</DescriptionTextLeft>
            <DescriptionTextRight>
              60%
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Ngày tạo:</DescriptionTextLeft>
            <DescriptionTextRight>
              1/10/2022
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Tỷ lệ vote:</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-3">
                <span className="mr-2">Yes</span>
                <Voting>30%</Voting>
              </span>
              <span>
                <span className="mr-2">No</span>
                <Voting>70%</Voting>
              </span>
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionTextEnd>
            <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2 fail">Đã kết thúc</span>
            </DescriptionTextRight>
          </DescriptionTextEnd>
          <p>
            <Button
          // type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              onClick={() => setModalVotingDetail(false)}
            >
              Đóng
            </Button>
          </p>
        </>
      </Modal>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Quản lý dự án Bất động sản
        </Col>
      </Row>
      <div className="d-flex flex-row mb-4 mt-3">
        <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5" onSearch={onSearch} />
        <Select
          placeholder="Trạng thái"
          optionFilterProp="children"
          style={{ width: 200 }}
          onChange={onChangeStatus}
          allowClear
        >
          {Object.keys(VOTING_STATUS_NAMES).map((p) => (
            <Select.Option value={p}>{VOTING_STATUS_NAMES[p]}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="d-flex flex-row mb-4 mt-3">
        <Button
          className="mr-3 button-section-filter"
          type="primary"
          onClick={onCreateVote}
        >
          <span>Tạo bài vote</span>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data?.offers || []}
        pagination={{
          position: ['bottomCenter'],
          pageSize: parsedQueryString.limit || 10,
          total: data?.total || 0,
          onChange: onChangePage,
        }}
        loading={isFetching}
        rowKey={(record) => record._id}
      />
    </>
  )
}

export default VotingList
