/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import {
  Row, Col, Breadcrumb, Menu, Tabs, Typography, Button, Table, Select, Form, Input, Avatar,
} from 'antd'
import {
  UserOutlined,
  WalletOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import {
  Link, Switch, Route, Redirect,
} from 'react-router-dom'
import { matchPath, useLocation, useHistory } from 'react-router'
import styled from 'styled-components'
import investor from 'provider/investor'
import { DateTime } from 'luxon'
import {
  INVESTOR_STATUS, INVESTOR_STATUS_NAMES, KYC_STATUS, KYC_STATUS_NAMES, STATUS_KYC_INVESTOR_COLORS, STATUS_INVESTOR_COLORS,
} from 'constants/investor'
import {
  REFERRAL_INVESTOR_STATUS_NAMES, REFERRAL_KYC_STATUS_NAMES, STATUS_REFERRAL_KYC_INVESTOR_COLORS, STATUS_REFERRAL_INVESTOR_COLORS,
} from 'constants/referral'
import {
  TRANSACTION_SWAP_STATUS_NAMES, TRANSACTION_IDO_STATUS_NAMES, TRANSACTION_P2P_STATUS_NAMES, TRANSACTION_P2P_STATUS_COLORS, TRANSACTION_IDO_STATUS_COLORS, TRANSACTION_SWAP_STATUS_COLORS,
} from 'constants/transaction'
import { useReferralInvestorList } from '../../../hooks/referral'
import { useTransactionP2PList, useTransactionIdoList, useTransactionSwapList } from '../../../hooks/transaction'
import AccountInfo from './AccountInfo'
import Activity from './Activity'
import BankAccount from './BankAccount'
import Invite from './Invite'
import Product from './Product'
import Transaction from './Transaction'
import Wallet from './Wallet'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import tokenUSDRIcon from '../../../static/images/TokenUSDR.png'
import { addToQueryString, fromQueryString } from '../../../utils/url'

const { TabPane } = Tabs

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`
const Content = styled.div`
padding: 0px 10px;
`

const ImageInvestor = styled.div`
  border: 1px solid black;
  margin: 0px 32px 0px 16px;
  padding: 16px;
  display: flex;
  with: 100%;
  align-items: center;
  min-height: 300px;
  justify-content: center;
`
const ContentUser = styled.div`
  margin: 0px 16px 0px 16px;
  padding: 0px 16px;
`
const Line = styled.div`
  border-bottom: 1px solid #b8b8b8;
  display: block;
  padding: 10px 0px;
  width: 100%;
  max-width: 400px;
`
const Label = styled.div`
  display: inline-block;
  min-width: 150px;
`
// const Images = {
//   '/thong-tin-tai-khoan': UserOutlined,
//   '/vi-tai-khoan': WalletOutlined,
//   '/san-pham-dau-tu': WalletOutlined,
//   '/tai-khoan-ngan-hang': WalletOutlined,
//   '/lich-su-giao-dich': HistoryOutlined,
//   '/lich-su-hoat-dong': HistoryOutlined,
//   '/danh-sach-gioi-thieu': UserOutlined,
// }

// const MenuItem = ({
//   data, investorId, ...props
// }) => {
//   const Icon = Images[data.path]
//   return (
//     <Menu.Item key={data.path} {...props}>
//       <Link to={`/investor/${investorId}${data.path}`}>
//         <MenuItemContent>
//           <Icon />
//           <span>{data.label}</span>
//         </MenuItemContent>
//       </Link>
//     </Menu.Item>
//   )
// }

// const MENU = [{
//   path: '/thong-tin-tai-khoan',
//   label: 'Thông tin tài khoản',
//   component: AccountInfo,
// }, {
//   path: '/vi-tai-khoan',
//   label: 'Ví tài khoản',
//   component: Wallet,
// }, {
//   path: '/san-pham-dau-tu',
//   label: 'Sản phẩm đầu tư',
//   component: Product,
// }, {
//   path: '/tai-khoan-ngan-hang',
//   label: 'Tài khoản ngân hàng',
//   component: BankAccount,
// }, {
//   path: '/lich-su-giao-dich',
//   label: 'Lịch sử giao dịch',
//   component: Transaction,
// }, {
//   path: '/lich-su-hoat-dong',
//   label: 'Lịch sử hoạt động',
//   component: Activity,
// }, {
//   path: '/danh-sach-gioi-thieu',
//   label: 'Danh sách giới thiệu',
//   component: Invite,
// }]

const InvestorDetail = ({ investorId }) => {
  const dataWallet = [
    {
      key: '1',
      name: 'vnd',
      balance: '',
      exchange: '23k',
    },
    {
      key: '2',
      name: 'usdt',
      balance: '',
      exchange: '23k',
    },
    {
      key: '3',
      name: 'bre',
      balance: '',
      exchange: '23k',

    },
    {
      key: '4',
      name: 'usdr',
      balance: '',
      exchange: '23k',

    },
  ]

  // List ví NĐT
  const wallet = [
    {
      title: 'Tài sản',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        text === 'usdt' ? (
          <div className="d-flex align-items-center">

            <span className="mr-2">
              <Avatar src={tokenUsdtIcon} />
            </span>
            <span className="mr-1 font-weight-500">USDT</span>
            <span className="mr-1">Tether</span>

          </div>
        ) : text === 'bre' ? (
          <div className="d-flex align-items-center">

            <span className="mr-2">
              <Avatar src={tokenBreIcon} />
            </span>
            <span className="mr-1 font-weight-500">BRE</span>
            <span className="mr-1">Coin</span>

          </div>
        )
          : text === 'vnd' ? (
            <div className="d-flex align-items-center">

              <span className="mr-2">
                <Avatar src={tokenVndIcon} />
              </span>
              <span className="mr-1 font-weight-500">VND</span>

            </div>
          )
            : text === 'usdr' ? (
              <div className="d-flex align-items-center">

                <span className="mr-2">
                  <Avatar src={tokenUSDRIcon} />
                </span>
                <span className="mr-1 font-weight-500">USDR</span>
                <span className="mr-1">Coin</span>
              </div>
            )
              : '-'
      ),
    },
    {
      title: 'Số dư',
      key: 'balance',
      dataIndex: 'balance',
      render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    },
    {
      title: 'Giá trị quy đổi ra BRE',
      key: 'exchange',
      dataIndex: 'exchange',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{text || '-'}</span>
          {/* <span>VND</span> */}
        </div>
      ),
    },

  ]

  // List sản phẩm NĐT
  const product = [
    {
      title: 'Mã dự án',
      dataIndex: 'project',
      render: (text) => (
        <span className="text-row">
          {text.no || '-'}
        </span>
      ),
    },
    {
      title: 'Tên dự án',
      dataIndex: 'project',
      render: (text) => (
        <span className="text-row">
          {text.name || '-'}
        </span>
      ),
    },
    {
      title: 'Địa chỉ dự án',
      dataIndex: ['project_info', 'address'],
      render: (text) => (
        <span className="text-row">
          {[text?.line1, text?.line2, text?.district, text?.city].filter((s) => !!s).join(', ') || '-'}
        </span>
      ),
    },
    {
      title: 'Số lượng cổ phần',
      dataIndex: 'amount',
      render: (text) => (
        <span className="text-row">
          {text}
        </span>
      ),
    },
    {
      title: 'Số lượng BRE/CP',
      dataIndex: 'stock_info',
      render: (text) => (
        <span className="text-row">
          {text.ido_price}
        </span>
      ),
    },
    {
      title: 'Trạng thái dự án',
      dataIndex: 'project',
      render: (text) => (
        text.status === 'PC' ? (
          <span className="text-row success">
            Đang đầu tư
          </span>
        ) : text.status === 'W' ? (
          <span className="text-row waiting">
            Đang mở bán
          </span>
        ) : text.status === 'F' ? (
          <span className="text-row fail">
            Đã kết thúc
          </span>
        ) : '-'
      ),
    },
  ]

  // List tài khoản ngân hàng NĐT
  const bankAccount = [
    {
      title: 'Tên ngân hàng',
      dataIndex: 'bank_type',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'full_name',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'number_card',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
  ]

  // List lịch sử giao dịch P2P NĐT
  const transactionP2P = [
    {
      title: 'ID giao dịch',
      dataIndex: '_id',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Mã cổ phần',
      dataIndex: 'order_no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      render: (text) => (
        <span className="text-row">
          {`${text} SPA` || '-'}
        </span>
      ),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      render: (text) => (
        <span className="text-row">
          {`${text} BRE/SPA` || '-'}
        </span>
      ),
    },
    {
      title: 'Phí chuyển đổi',
      dataIndex: 'gas',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (text) => (
        <span className="text-row">
          {DateTime.fromISO(text).toFormat('HH:mm dd/MM/yyyy') || '-'}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${TRANSACTION_P2P_STATUS_COLORS[status]}`}>
            {TRANSACTION_P2P_STATUS_NAMES[status] || ''}
          </span>
        </div>
      ),
    },
  ]

  // List lịch sử giao dịch Ido NĐT
  const transactionIdo = [
    {
      title: 'ID giao dịch',
      dataIndex: '_id',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Mã cổ phần',
      dataIndex: 'order_no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      render: (text) => (
        <span className="text-row">
          {`${text} SPA` || '-'}
        </span>
      ),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      render: (text) => (
        <span className="text-row">
          {`${text} BRE/SPA` || '-'}
        </span>
      ),
    },
    {
      title: 'Phí chuyển đổi',
      dataIndex: 'gas',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (text) => (
        <span className="text-row">
          {DateTime.fromISO(text).toFormat('HH:mm dd/MM/yyyy') || '-'}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${TRANSACTION_IDO_STATUS_COLORS[status]}`}>
            {TRANSACTION_IDO_STATUS_NAMES[status] || ''}
          </span>
        </div>
      ),
    },
  ]

  // List lịch sử giao dịch Swap NĐT
  const transactionSwap = [
    {
      title: 'ID giao dịch',
      dataIndex: '_id',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Tài sản nguồn',
      dataIndex: 'order_no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      render: (text) => (
        <span className="text-row">
          {`${text} BRE` || '-'}
        </span>
      ),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      render: (text) => (
        <span className="text-row">
          {`${text} BRE/VND` || '-'}
        </span>
      ),
    },
    {
      title: 'Phí chuyển đổi',
      dataIndex: 'gas',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (text) => (
        <span className="text-row">
          {DateTime.fromISO(text).toFormat('HH:mm dd/MM/yyyy') || '-'}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div>
          <span className={`text-row ${TRANSACTION_SWAP_STATUS_COLORS[status]}`}>
            {TRANSACTION_SWAP_STATUS_NAMES[status] || ''}
          </span>
        </div>
      ),
    },
  ]

  // List lịch sử hoạt động NĐT
  const activity = [
    {
      title: 'Thời gian',
      dataIndex: 'no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Loại hoạt động',
      dataIndex: 'no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Thiết bị',
      dataIndex: 'no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'no',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
  ]

  // List danh sách giới thiệu NĐT
  const invite = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (text) => (
        <span className="text-row">
          {text || '-'}
        </span>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'user',
      render: (text) => (
        <span className="text-row">
          {text?.username || '-'}
        </span>
      ),
    },
    {
      title: 'Địa chỉ email',
      dataIndex: 'user',
      render: (text) => (
        <span className="text-row">
          {text?.email || '-'}
        </span>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      render: (text) => (
        <span className="text-row">
          {text?.phone || '-'}
        </span>
      ),
    },
    {
      title: 'Trạng thái KYC',
      dataIndex: 'kyc',
      render: (text) => (
        <div>
          <span className={`text-row ${STATUS_REFERRAL_KYC_INVESTOR_COLORS[text?.status]}`}>
            {REFERRAL_KYC_STATUS_NAMES[text?.status] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Trạng thái tài khoản',
      dataIndex: 'user',
      render: (text) => (
        <div>
          <span className={`text-row ${STATUS_REFERRAL_INVESTOR_COLORS[text?.status]}`}>
            {REFERRAL_INVESTOR_STATUS_NAMES[text?.status] || ''}
          </span>
        </div>

      ),
    },
    {
      title: 'Số token BRE nhận được',
      dataIndex: 'register_reward',
      render: (text) => (
        <span className="text-row">
          {text}
        </span>
      ),
    },
  ]

  // const location = useLocation()
  // const matchedPath = MENU.find((m) => matchPath(location.pathname, {
  //   path: `/investor/:investorId${m.path}`,
  // }))
  const location = useLocation()
  const history = useHistory()
  const [detailInvestor, setDetailInvestor] = useState({})
  const [walletInvestor, setWalletInvestor] = useState(null)
  const [bankAccountInvestor, setBankAccountInvestor] = useState(null)
  const [productInvestor, setProductInvestor] = useState(null)
  // const [transactionInvestorP2P, setTransactionInvestorP2P] = useState(null)
  // const [transactionInvestorIdo, setTransactionInvestorIdo] = useState(null)
  // const [transactionInvestorSwap, setTransactionInvestorSwap] = useState(null)
  const [referralInvestor, setReferralInvestor] = useState(null)
  const [statusProject, setStatusProject] = useState('PC')
  const [statusTransaction, setStatusTransaction] = useState('0')

  const parsedQueryString = fromQueryString(location.search)
  const statusKycInvestor = parsedQueryString.statusKyc
  const investorStatus = parsedQueryString.statusInvestor

  // dánh sách referral của NĐT
  const { data: dataReferral, isFetching: isFetchingReferral } = useReferralInvestorList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    key_search: parsedQueryString.search || '',
  }, investorId, statusKycInvestor, investorStatus)

  const onSearch = (search) => {
    history.replace({
      search: addToQueryString(location.search, { search }),
    })
  }

  const onChangeStatusKyc = (statusKyc) => {
    history.replace({
      search: addToQueryString(location.search, { statusKyc }),
    })
  }

  const onChangeStatus = (statusInvestor) => {
    history.replace({
      search: addToQueryString(location.search, { statusInvestor }),
    })
  }

  const onChangePage = (page) => {
    history.replace({
      search: addToQueryString(location.search, { page }),
    })
  }

  // dánh sách transaction P2P của NĐT
  const { data: dataTransactionP2P, isFetching: isFetchingTransactionP2P } = useTransactionP2PList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
  }, investorId, statusTransaction)

  // dánh sách transaction Ido của NĐT
  const { data: dataTransactionIdo, isFetching: isFetchingTransactionIdo } = useTransactionIdoList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    status: parsedQueryString.status || '',
  }, investorId)

  const onChangeStatusTransactionIdo = (status) => {
    history.replace({
      search: addToQueryString(location.search, { status }),
    })
  }

  // dánh sách transaction Swap của NĐT
  const { data: dataTransactionSwap, isFetching: isFetchingTransactionSwap } = useTransactionSwapList({
    limit: parsedQueryString.limit || 10,
    page: parsedQueryString.page || 1,
    status: parsedQueryString.status || '',
  }, investorId)

  const onChangeStatusTransactionSwap = (status) => {
    history.replace({
      search: addToQueryString(location.search, { status }),
    })
  }

  // thông tin của NĐT
  const fetchDetailInvestor = async () => {
    try {
      const resp = await investor.getDetail({ user_id: investorId })
      setDetailInvestor(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  // danh sách wallet của NĐT
  const fetchListWalletInvestor = async () => {
    try {
      const resp = await investor.getListWalletInvestor(investorId)
      setWalletInvestor(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  // dánh sách bank account của NĐT
  const fetchListBankAccountInvestor = async () => {
    try {
      const resp = await investor.getListBankAccountInvestor(investorId)
      setBankAccountInvestor(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  // dánh sách product của NĐT
  const fetchListProductInvestor = async () => {
    const dataObj = {
      investorId,
      statusProject,
    }
    try {
      const resp = await investor.getListProductInvestor(dataObj)
      setProductInvestor(resp.data.shares)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchDetailInvestor()
    fetchListWalletInvestor()
    fetchListBankAccountInvestor()
    fetchListProductInvestor()
    return () => {
    }
  }, [])

  return (
    <>
      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý nhà đầu tư</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Chi tiết nhà đầu tư</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Chi tiết nhà đầu tư
        </Col>
      </Row>
      {/* <Row>
        <Col span={5}>
          <Menu defaultSelectedKeys={['/thong-tin-tai-khoan']} selectedKeys={matchedPath?.path ? [matchedPath?.path] : []}>
            {MENU.map((m) => (
              <MenuItem data={m} key={m.path} investorId={investorId} />
            ))}
          </Menu>
        </Col>
        <Col span={19}>
          <Content>
            <Switch>
              {MENU.map((m) => (
                <Route key={m.path} path={`/investor/${investorId}${m.path}`} component={m.component} />
              ))}
              <Redirect from="/" to={`/investor/${investorId}/thong-tin-tai-khoan`} />
            </Switch>
          </Content>
        </Col>
      </Row> */}
      <Tabs tabPosition="left">
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Thông tin tài khoản
            </span>
      )}
          key="1"
        >
          <>
            <Row>
              <Col span={24} className="mt-2 mb-3">
                <Typography.Title level={4}>
                  Thông tin tài khoản
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <ImageInvestor>
                  Profile picture
                </ImageInvestor>
              </Col>
              <Col span={14} className="p-r-10">
                <ContentUser>
                  <Line>
                    <Label>Tên tài khoản</Label>
                    <span>{detailInvestor?.username}</span>
                  </Line>
                  <Line>
                    <Label>Địa chỉ email</Label>
                    <span>{detailInvestor?.email}</span>
                  </Line>
                  <Line>
                    <Label>Số điện thoại</Label>
                    <span>{detailInvestor?.phone}</span>
                  </Line>
                  <Line>
                    <Label>Mã giới thiệu</Label>
                    <span>{detailInvestor?.referral?.code}</span>
                  </Line>
                  <Line>
                    <Label>KYC</Label>
                    <span>
                      <span className={`text-row ${STATUS_KYC_INVESTOR_COLORS[detailInvestor?.kyc?.status] ? STATUS_KYC_INVESTOR_COLORS[detailInvestor?.kyc?.status] : 'fail'}`}>
                        {KYC_STATUS_NAMES[detailInvestor?.kyc?.status] || 'Chưa xác minh'}
                      </span>

                    </span>
                  </Line>
                  <Line>
                    <Label>Trạng thái tài khoản</Label>

                    <span className={`text-row ${STATUS_INVESTOR_COLORS[detailInvestor?.status] ? STATUS_INVESTOR_COLORS[detailInvestor?.status] : 'fail'}`}>
                      {INVESTOR_STATUS_NAMES[detailInvestor?.status] || ''}
                    </span>

                  </Line>
                  <Button className="m-t-10" type="primary">
                    Đổi lại mật khẩu
                  </Button>
                </ContentUser>
              </Col>
            </Row>
          </>
        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Ví tài khoản
            </span>
      )}
          key="2"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Ví tài khoản
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={wallet}
                dataSource={dataWallet}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Sản phẩm đầu tư
            </span>
      )}
          key="3"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Sản phẩm đầu tư
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={product}
                dataSource={productInvestor}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Tài khoản ngân hàng
            </span>
      )}
          key="4"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Danh sách tài khoản ngân hàng
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={bankAccount}
                dataSource={bankAccountInvestor}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Lịch sử giao dịch
            </span>
      )}
          key="5"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Lịch sử giao dịch
              </Typography.Title>
            </Col>
          </Row>
          <Tabs defaultActiveKey="p2p">
            <TabPane tab="P2P" key="p2p">
              <Form layout="vertical">
                <div className="d-flex flex-row">
                  {/* <Form.Item label="Loại giao dịch" className="m-r-10">
                <Select
                  placeholder="Nạp"
                  optionFilterProp="children"
                  style={{ width: 140 }}
                  allowClear
                />
              </Form.Item> */}
                  <Form.Item label="Loại tài sản" className="m-r-10">
                    <Select
                      placeholder="Tài sản"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item label=" " className="m-r-10">
                    <Select
                      placeholder="Thời gian"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      // onChange={onChangeStatus}
                    >
                      {' '}

                    </Select>
                  </Form.Item>
                  <Form.Item label="Trạng thái" className="m-r-10">
                    <Select
                      placeholder="Trạng thái"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                      onChange={onChangeStatusTransactionSwap}
                    >
                      {' '}
                      {Object.keys(TRANSACTION_P2P_STATUS_NAMES).map((p) => (
                        <Select.Option value={p}>{TRANSACTION_P2P_STATUS_NAMES[p]}</Select.Option>
                      ))}

                    </Select>
                  </Form.Item>
                </div>
              </Form>
              <Row>
                <Col span={24}>
                  <Table
                    columns={transactionP2P}
                    dataSource={dataTransactionP2P || []}
                    pagination={{
                      position: ['bottomCenter'],
                      pageSize: parsedQueryString.limit || 10,
                      total: dataTransactionP2P?.total || 0,
                      onChange: onChangePage,
                    }}
                    loading={isFetchingTransactionP2P}
                    rowKey={(record) => record._id}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Ido" key="ido">
              <Form layout="vertical">
                <div className="d-flex flex-row">
                  <Form.Item label="Loại tài sản" className="m-r-10">
                    <Select
                      placeholder="Tài sản"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item label=" " className="m-r-10">
                    <Select
                      placeholder="Thời gian"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                       // onChange={onChangeStatus}
                    >
                      {' '}

                    </Select>

                  </Form.Item>
                  <Form.Item label="Trạng thái" className="m-r-10">
                    <Select
                      placeholder="Trạng thái"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                      onChange={onChangeStatusTransactionIdo}
                    >
                      {' '}
                      {Object.keys(TRANSACTION_IDO_STATUS_NAMES).map((p) => (
                        <Select.Option value={p}>{TRANSACTION_IDO_STATUS_NAMES[p]}</Select.Option>
                      ))}

                    </Select>
                  </Form.Item>
                </div>
              </Form>
              <Row>
                <Col span={24}>
                  <Table
                    columns={transactionIdo}
                    dataSource={dataTransactionIdo || []}
                    pagination={{
                      position: ['bottomCenter'],
                      pageSize: parsedQueryString.limit || 10,
                      total: dataTransactionIdo?.total || 0,
                      onChange: onChangePage,
                    }}
                    loading={isFetchingTransactionIdo}
                    rowKey={(record) => record._id}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Swap" key="swap">
              <Form layout="vertical">
                <div className="d-flex flex-row">
                  <Form.Item label="Loại tài sản" className="m-r-10">
                    <Select
                      placeholder="Tài sản"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item label=" " className="m-r-10">
                    <Select
                      placeholder="Thời gian"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      // onChange={onChangeStatus}
                    >
                      {' '}

                    </Select>
                  </Form.Item>
                  <Form.Item label="Trạng thái" className="m-r-10">
                    <Select
                      placeholder="Trạng thái"
                      optionFilterProp="children"
                      style={{ width: 140 }}
                      allowClear
                      onChange={onChangeStatusTransactionSwap}
                    >
                      {' '}
                      {Object.keys(TRANSACTION_SWAP_STATUS_NAMES).map((p) => (
                        <Select.Option value={p}>{TRANSACTION_SWAP_STATUS_NAMES[p]}</Select.Option>
                      ))}

                    </Select>
                  </Form.Item>
                </div>
              </Form>
              <Row>
                <Col span={24}>
                  <Table
                    columns={transactionSwap}
                    dataSource={dataTransactionSwap || []}
                    pagination={{
                      position: ['bottomCenter'],
                      pageSize: parsedQueryString.limit || 10,
                      total: dataTransactionSwap?.total || 0,
                      onChange: onChangePage,
                    }}
                    loading={isFetchingTransactionSwap}
                    rowKey={(record) => record._id}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>

        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Lịch sử hoạt động
            </span>
      )}
          key="6"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Lịch sử hoạt động
              </Typography.Title>
            </Col>
          </Row>
          <div className="d-flex flex-row mb-4">
            <Select
              placeholder="Thời gian"
              optionFilterProp="children"
              style={{ width: 140 }}
              allowClear
              className="m-r-10"
            />
            <Select
              placeholder="Loại hoạt động"
              optionFilterProp="children"
              style={{ width: 140 }}
              allowClear
              className="m-r-10"
            />
            <Select
              placeholder="Trạng thái"
              optionFilterProp="children"
              style={{ width: 140 }}
              allowClear
            />
          </div>
          <Row>
            <Col span={24}>
              <Table
                columns={activity}
                // dataSource={detailInvestor}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={(
            <span>
              <UserOutlined />
              Dánh sách giới thiệu
            </span>
      )}
          key="7"
        >
          <Row>
            <Col span={24} className="mt-2 mb-3">
              <Typography.Title level={4}>
                Danh sách giới thiệu
              </Typography.Title>
            </Col>
          </Row>
          <div className="d-flex flex-row mb-4">
            <Input.Search placeholder="Vui lòng nhập từ khóa" className="mr-5" onSearch={onSearch} />
            <Select
              placeholder="Trạng thái tài khoản"
              optionFilterProp="children"
              style={{ width: 140 }}
              allowClear
              className="m-r-10"
              onChange={onChangeStatus}
            >
              {' '}
              {Object.keys(REFERRAL_KYC_STATUS_NAMES).map((p) => (
                <Select.Option value={p}>{REFERRAL_KYC_STATUS_NAMES[p]}</Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Trạng thái KYC"
              optionFilterProp="children"
              style={{ width: 140 }}
              allowClear
              onChange={onChangeStatusKyc}
            >
              {' '}
              {Object.keys(REFERRAL_INVESTOR_STATUS_NAMES).map((p) => (
                <Select.Option value={p}>{REFERRAL_INVESTOR_STATUS_NAMES[p]}</Select.Option>
              ))}
            </Select>
          </div>
          <Row>
            <Col span={24}>
              <Table
                columns={invite}
                dataSource={dataReferral?.refs || []}
                pagination={{
                  position: ['bottomCenter'],
                  pageSize: parsedQueryString.limit || 10,
                  total: dataReferral?.total || 0,
                  onChange: onChangePage,
                }}
                loading={isFetchingReferral}
                rowKey={(record) => record._id}
              />

            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  )
}

export default InvestorDetail
