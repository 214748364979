export const USER_ACTION = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  LOGIN_OK: 'LOGIN_OK',
  LOGIN_ERR: 'LOGIN_ERR',
  REGISTER: 'REGISTER',
  REGISTER_OK: 'REGISTER_OK',
  REGISTER_ERR: 'REGISTER_ERR',
  RESEND_OTP: 'RESEND_OTP',
  RESEND_OTP_OK: 'RESEND_OTP_OK',
  RESEND_OTP_ERR: 'RESEND_OTP_ERR',
  VERIFY_USER: 'VERIFY_USER',
  VERIFY_USER_OK: 'VERIFY_USER_OK',
  VERIFY_USER_ERR: 'VERIFY_USER_ERR',
  GET_INFO: 'GET_INFO',
  GET_INFO_OK: 'GET_INFO_OK',
  GET_INFO_ERR: 'GET_INFO_ERR',
}
