export const VOTING_STATUS = {
  true: 'true',
  false: 'false',
}

export const VOTING_STATUS_NAMES = {
  [VOTING_STATUS.true]: 'Đang vote',
  [VOTING_STATUS.false]: 'Đã kết thúc',
}

export const STATUS_VOTING_COLORS = {
  [VOTING_STATUS.true]: 'success',
  [VOTING_STATUS.false]: 'fail',
}
