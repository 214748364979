/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, {
  useEffect, useMemo,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber,
  Modal, Spin, Table,
} from 'antd'
import { useHistory } from 'react-router'
import {
  useEditProject, useProjectDetail, usePublishProject,
} from 'hooks/project'
import {
  PROJECT_CATE_NAMES, PROJECT_STATUS, PROJECT_STATUS_NAMES, PROJECT_TYPE_NAMES,
} from 'constants/project'
import { DateTime } from 'luxon'
import RichText from '../../components/RichText'
import dvhcvn from '../../static/city.json'
import Links from './components/Links'
import UploadFiles from './components/UploadFiles'
import './index.css'
import TimeConfig from './components/TimeConfig'
import Address from './components/Address'

const ProjectDetail = ({ projectId }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { data, isFetching, mutate: fetchDetail } = useProjectDetail(projectId, () => {
    history.replace('/project')
  })
  const mutationPublish = usePublishProject()

  const isDaft = useMemo(() => data?.status === PROJECT_STATUS.W, [data?.status])

  const mutationEdit = useEditProject()
  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
    short_description: '',
    ...(projectId ? { project_id: projectId } : {}),
  })

  const onSubmit = (project) => {
    if (!isDaft) {
      // TODO: update status project
    } else {
      const mapData = mapSubmitData(project)
      mutationEdit.mutate(mapData, {
        onSuccess: (p) => {
          Modal.success({
            content: 'Sửa dự án thành công',
            onOk: () => history.go(0),
          })
        },
      })
    }
  }

  const publishProject = () => {
    Modal.confirm({
      content: 'Bạn thật sự muốn đăng dự án này?',
      onOk: () => mutationPublish.mutate(projectId, {
        onSuccess: () => {
          Modal.success({
            content: 'Đăng dự án thành công',
            onOk: () => history.replace(`/project/view/${projectId}`),
          })
        },
      }),
    })
  }

  useEffect(() => {
    form.resetFields()
  }, [data])

  const columns = [{
    title: 'Lãi cần trả',
    dataIndex: 'no',
  }, {
    title: 'Ngày trả lãi',
    dataIndex: 'no',
  }, {
    title: 'Loại trả lãi',
    dataIndex: 'no',
  }, {
    title: 'Tổng lãi (VND)',
    dataIndex: 'no',
  }, {
    title: 'Số cổ phần BRE trả lãi',
    dataIndex: 'no',
  }, {
    title: 'Danh sách NĐT',
    dataIndex: 'no',
  }]

  const shareProfit = (id) => {
    history.push(`/project/profit-sharing/${id}`)
  }

  return (
    <Spin size="large" spinning={isFetching || mutationEdit.isLoading}>
      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý dự án bất động sản</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">{`Dự án ${data?.name || ''}`}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={16} className="mt-2 mb-3 text-header">
          {`${data?.name || ''}`}
        </Col>
        <Col span={8} className="pjo-status">
          Trạng thái:
          {' '}
          <b>{` ${PROJECT_STATUS_NAMES[data?.status] || ''}`}</b>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        autoComplete="off"
        scrollToFirs
        tError
        onFinish={onSubmit}
        onFinishFailed={(v) => {
          Modal.error({
            content: JSON.stringify(v),
          })
        }}
      >
        <div className="w-100">
          <h5 className="m-t-20">Thông tin chung</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Tên dự án"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
            >
              <Input placeholder="Nhập tên dự án" disabled={!isDaft} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Mã BĐS"
              name="no"
              rules={[{ required: true, message: 'Vui lòng nhập mã dự án!' }]}
            >
              <Input placeholder="Nhập mã dự án" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loại BĐS"
              name="classification"
              rules={[{ required: true, message: 'Vui lòng nhập kiểu BĐS!' }]}
            >
              <Select
                placeholder="Chọn loại BĐS"
                optionFilterProp="children"
                disabled={!isDaft}
              >
                {Object.keys(PROJECT_TYPE_NAMES).map((p) => (
                  <Select.Option value={p}>{PROJECT_TYPE_NAMES[p]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Kiểu BĐS"
              name="type"
            >
              <Select
                placeholder="Chọn kiểu BĐS"
                optionFilterProp="children"
                disabled={!isDaft}
              >
                {Object.keys(PROJECT_CATE_NAMES).map((p) => (
                  <Select.Option value={p}>{PROJECT_CATE_NAMES[p]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Giá trị(VND)"
              name={['stock_info', 'ido_price']}
              rules={[{ required: true, message: 'Vui lòng nhập Giá trị dự án!' }]}
            >
              <InputNumber placeholder="Nhập giá trị dự án" min={0} style={{ width: '100%' }} disabled={!isDaft} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Số lượng cổ phần"
              name={['stock_info', 'total_supply']}
              rules={[{ required: true, message: 'Vui lòng nhập số lượng cổ phần!' }]}
            >
              <InputNumber placeholder="Nhập số lượng cổ phần" min={0} style={{ width: '100%' }} disabled={!isDaft} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Lãi suất dự kiến(%)"
              name="expected_interest_rate"
              rules={[{ type: 'number', required: true, message: 'Vui lòng nhập lãi suất dự kiến!' }]}
            >
              <InputNumber
                placeholder="Nhập lãi suất dự kiến"
                min={0}
                max={100}
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                disabled={!isDaft}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="time_config" noStyle>
          <TimeConfig disabled={!isDaft} />
        </Form.Item>
        <div className="w-100">
          <h5 className="m-t-20">Hình ảnh dự án</h5>
        </div>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item name={['project_info', 'images']}>
              <UploadFiles
                disabled={!isDaft}
                projectNo={data?.no}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Giới thiệu chung</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item name={['project_info', 'general_doc']}>
              <RichText readOnly={!isDaft} />
            </Form.Item>
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Vị trí dự án</h5>
        </div>
        <Form.Item noStyle name={['project_info', 'address']}>
          <Address disabled={!isDaft} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item name={['project_info', 'address', 'note']}>
              <RichText readOnly={!isDaft} />
            </Form.Item>
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Pháp lý</h5>
          <Row>
            <Col span={24}>
              <Form.Item name={['project_info', 'legal_doc']}>
                <RichText readOnly={!isDaft} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="w-100">
          <h5 className="m-t-20">Thông tin tham khảo dự án</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item name={['project_info', 'ref_doc']}>
              <RichText readOnly={!isDaft} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={['project_info', 'ref_links']}>
              <Links disabled={!isDaft} />
            </Form.Item>
          </Col>
        </Row>
        {isDaft ? '' : (
          <>
            <div className="w-100">
              <h5 className="m-t-20">Cập nhập trạng thái dự án</h5>
            </div>
            <Row>
              <Col span={24}>
                <Form.Item name="status">
                  <Select optionFilterProp="children">
                    {Object.values(PROJECT_STATUS).filter((p) => p !== PROJECT_STATUS.W).map((p) => (
                      <Select.Option value={p}>{PROJECT_STATUS_NAMES[p]}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Form.Item className="block text-center">
            <Button style={{ margin: '0 8px' }} onClick={history.goBack}>
              Quay lại
            </Button>
            <Button
              type={isDaft ? 'default' : 'primary'}
              htmlType="submit"
              className="m-t-15"
              style={{ margin: '0 8px' }}
            >
              {isDaft ? 'Lưu nháp' : 'Lưu'}
            </Button>
            {isDaft ? (
              <Button
                type="primary"
                className="m-t-15"
                onClick={publishProject}
              >
                Đăng dự án
              </Button>
            ) : (
              <Button
                type="primary"
                className="m-t-15"
                onClick={() => shareProfit(data?._id)}
              >
                Chia lãi cho NĐT
              </Button>
            )}
          </Form.Item>
        </Row>
      </Form>
      {isDaft ? '' : (
        <Row>
          <Col span={24}>
            <h5 className="m-t-20">Lịch sử trả lãi cho nhà đầu tư</h5>
            <Table columns={columns} />
          </Col>
        </Row>
      )}
    </Spin>
  )
}

export default ProjectDetail
