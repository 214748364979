import React from 'react'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Dragger } = Upload

const UploadFile = (props) => (
  <Dragger {...props}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
  </Dragger>
)

export default UploadFile
