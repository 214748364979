export const INVESTOR_STATUS = {
  C: 'C',
  PC: 'PC',
}

export const INVESTOR_STATUS_NAMES = {
  [INVESTOR_STATUS.C]: 'Hoạt động',
  [INVESTOR_STATUS.PC]: 'Không hoạt động',
}

export const STATUS_INVESTOR_COLORS = {
  [INVESTOR_STATUS.C]: 'success',
  [INVESTOR_STATUS.PC]: 'fail',
}

export const KYC_STATUS = {
  C: 'C',
  PC: 'PC',
  RJ: 'RJ',
  P: 'P',
}

export const KYC_STATUS_NAMES = {
  [KYC_STATUS.C]: 'Đã xác minh',
  [KYC_STATUS.PC]: 'Chờ phê duyệt',
  [KYC_STATUS.RJ]: 'Đã từ chối',
  [KYC_STATUS.P]: 'Pending',
}

export const STATUS_KYC_INVESTOR_COLORS = {
  [KYC_STATUS.C]: 'success',
  [KYC_STATUS.PC]: 'waiting',
  [KYC_STATUS.RJ]: 'fail',
  [KYC_STATUS.P]: 'fail',
}
