/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react'
import {
  Row, Col, Input, Select, Breadcrumb, Table, Form, InputNumber, Button, Modal,
} from 'antd'
import { useHistory, useLocation } from 'react-router'
import { EyeOutlined } from '@ant-design/icons'
import { DateTime } from 'luxon'
import { useDistributeProject, useProjectDetail } from 'hooks/project'
import { addToQueryString, fromQueryString } from '../../utils/url'

const ProfitSharing = ({ projectId }) => {
  const location = useLocation()
  const parsedQueryString = fromQueryString(location.search)
  const history = useHistory()
  const [form] = Form.useForm()
  const mutationDistribute = useDistributeProject()
  const { data, isFetching, mutate: fetchDetail } = useProjectDetail(projectId)

  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
    symbol: data?.no,
  })

  const defaultData = useMemo(() => ({

  }), [])

  const onSubmit = (distribute) => {
    const mapData = mapSubmitData(distribute)
    console.log(mapData)
    mutationDistribute.mutate(mapData, {
      onSuccess: (p) => {
        Modal.success({
          content: 'Chia lãi cho NĐT thành công',
          onOk: () => history.replace('/voting'),
        })
      },
    })
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'no',
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'no',
    },
    {
      title: 'Địa chỉ email',
      dataIndex: 'no',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'no',
    },
    {
      title: 'Số cổ phần đầu tư',
      dataIndex: 'no',
    },
    {
      title: 'Số token REX lãi nhận được',
      dataIndex: 'no',
    },
  ]

  return (
    <>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item className="text-muted">Quản lý dự án bất động sản</Breadcrumb.Item>
            <Breadcrumb.Item className="text-black">Thêm mới dự án</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Trả lãi cho nhà đầu tư
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultData}
        autoComplete="off"
        scrollToFirs
        tError
        onFinish={onSubmit}
        onFinishFailed={(v) => {
          Modal.error({
            content: JSON.stringify(v),
          })
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Số tiền lãi(VND)"
              name="usdtrexRatio"
              rules={[{ required: true, message: 'Vui lòng lựa chọn!' }]}
            >
              <InputNumber
                placeholder="Nhập số tiền lãi"
                style={{ width: '100%' }}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="block text-center">
          <Button
            type="primary"
            htmlType="submit"
            className="m-t-15"
          >
            Chia lãi
          </Button>
          {/* <Button
              type="primary"
              className="m-t-15"
              onClick={publishProject}
            >
              Đăng dự án
            </Button> */}
        </Form.Item>
      </Form>
      {/* <div className="mb-2">
        <div className="mb-1">Chọn kiểu trả lãi</div>
        <Select
          placeholder="Chọn kiểu trả lãi"
          optionFilterProp="children"
          style={{ width: '100%' }}
          allowClear
        > */}
      {/* {Object.keys(PROJECT_TYPE_NAMES).map((p) => (
            <Select.Option value={p}>{PROJECT_TYPE_NAMES[p]}</Select.Option>
          ))} */}
      {/* </Select>
      </div> */}
      {/* <div className="mb-4">
        <div className="mb-1">Số tiền lãi(VND)</div>
        <InputNumber
          placeholder="Nhập số tiền lãi"
          style={{ width: '100%' }}
          allowClear
        />
      </div> */}

      <Table
        columns={columns}
        // dataSource={data?.projects || []}
        // pagination={{
        //   pageSize: parsedQueryString.limit || 20,
        //   total: data?.total || 0,
        //   onChange: onChangePage,
        // }}
        // loading={isFetching}
        // rowKey={(record) => record._id}
      />
    </>
  )
}

export default ProfitSharing
