import React, { useMemo } from 'react'
import {
  Row, Col, Select, Input,
} from 'antd'
import dvhcvn from '../../../static/city.json'

const Address = ({ value, onChange, disabled }) => {
  const districts = useMemo(() => dvhcvn.find((c) => c.value === value?.city)?.level2s || [], [value?.city])
  const communes = useMemo(() => districts.find((d) => d.value === value?.district)?.level3s || [], [value?.district, districts])

  const onChangeProvince = (newVal) => {
    if (newVal === value?.city) return
    onChange({
      ...value,
      city: newVal,
      district: '',
      line2: '',
    })
  }
  const onChangeDistrict = (newVal) => {
    if (newVal === value?.district) return
    onChange({
      ...value,
      district: newVal,
      line2: '',
    })
  }
  const setCommune = (newVal) => {
    if (newVal === value?.line2) return
    onChange({
      ...value,
      line2: newVal,
    })
  }
  const onChangeLine1 = (e) => {
    onChange({
      ...value,
      line1: e.target.value,
    })
  }
  return (
    <>
      <Row gutter={32} className="m-b-10">
        <Col span={12}>
          <div className="m-b-5">Tỉnh/Thành phố</div>
          <Select
            optionFilterProp="children"
            placeholder="-- Chọn Tỉnh/Thành Phố --"
            value={value?.city}
            onChange={onChangeProvince}
            disabled={disabled}
          >
            {dvhcvn.map((p) => (
              <Select.Option value={p.value}>{p.label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <div className="m-b-5">Quận/Huyện</div>
          <Select
            optionFilterProp="children"
            placeholder="-- Chọn Quận/Huyện --"
            value={value?.district}
            onChange={onChangeDistrict}
            disabled={disabled}
          >
            {districts.map((p) => (
              <Select.Option value={p.value}>{p.label}</Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={32} className="m-b-10">
        <Col span={12}>
          <div className="m-b-5">Phường/Xã</div>
          <Select
            optionFilterProp="children"
            placeholder="-- Chọn Phường/Xã --"
            value={value?.line2}
            onChange={setCommune}
            disabled={disabled}
          >
            {communes.map((p) => (
              <Select.Option value={p.value}>{p.label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <div className="m-b-5">Địa chỉ cụ thể</div>
          <Input placeholder="Địa chỉ cụ thể" style={{ width: '100%' }} disabled={disabled} value={value?.line1} onChange={onChangeLine1} />
        </Col>
      </Row>
    </>
  )
}

Address.defaultProps = {
  disabled: false,
}

export default Address
