/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, {
  useCallback, useMemo, useState,
} from 'react'
import {
  Row, Col, Breadcrumb, Form, Button,
  Input, Select, InputNumber, DatePicker,
  Modal, Spin,
} from 'antd'
import { useHistory } from 'react-router'
import {
  useCreateProject, usePublishProject,
} from 'hooks/project'
import { PROJECT_CATE_NAMES, PROJECT_TYPE_NAMES } from 'constants/project'
import { DateTime } from 'luxon'
import moment from 'moment'
import RichText from '../../components/RichText'
import dvhcvn from '../../static/city.json'
import Links from './components/Links'

const ProjectCreate = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [province, setProvince] = useState()
  const [district, setDistrict] = useState()
  const [districts, setDistricts] = useState([])
  const [commune, setCommune] = useState([])
  const [communes, setCommunes] = useState([])
  const mutationCreate = useCreateProject()
  const mutationPublish = usePublishProject()
  const [url, setUrl] = useState('')

  const [timeSale, setTimeSale] = useState([
    moment(DateTime.now().minus({ day: 5 }).toJSDate().toISOString()),
    moment(DateTime.now().toJSDate().toISOString()),
  ])

  const [timeInvest, setInvest] = useState([
    moment(DateTime.now().minus({ day: 5 }).toJSDate().toISOString()),
    moment(DateTime.now().toJSDate().toISOString()),
  ])

  const defaultData = useMemo(() => ({
    time_config: {
      open: DateTime.now().minus({ day: 5 }).toJSDate().toISOString(),
      close: DateTime.now().toJSDate().toISOString(),
      investEnd: DateTime.now().plus({ day: 5 }).toJSDate().toISOString(),
    },
    stock_info: {},
    ref_links: [],
  }), [])

  const onChangeProvince = useCallback((newVal) => {
    setProvince(newVal)
    setDistrict(null)
    setDistricts(dvhcvn.find((p) => p.value === newVal)?.level2s || [])
    setCommunes([])
    setCommune(null)
  }, [setDistricts, setCommunes])

  const onChangeDistrict = (newVal) => {
    setDistrict(newVal)
    setCommune(null)
    setCommunes(districts.find((p) => p.value === newVal)?.level3s || [])
  }

  const mapSubmitData = ({
    ...values
  }) => ({
    ...values,
    time_config: {
      open: timeSale[0].format('MM/DD/YYYY HH:mm:ss'),
      close: timeSale[1].format('MM/DD/YYYY HH:mm:ss'),
      invest_duration: DateTime.fromISO(timeInvest[1].toISOString()).diff(DateTime.fromISO(timeInvest[0].toISOString())).toMillis(),
    },
  })

  const onSubmit = (project) => {
    const mapData = mapSubmitData(project)
    mutationCreate.mutate(mapData, {
      onSuccess: (p) => {
        Modal.success({
          content: 'Tạo dự án thành công',
          onOk: () => history.replace(`/project/view/${p?._id}`),
        })
      },
    })
  }

  const onChangeTimeSale = ([start, end]) => {
    if (!timeInvest) return
    if (timeInvest[0].isBefore(end, 'day')) {
      setTimeSale([start, end])
      setInvest([end, end])
    } else {
      setTimeSale([start, end])
      setInvest([end, timeInvest[1]])
    }
  }

  const onChangeTimeInvest = ([start, end]) => {
    if (!timeSale) return
    if (timeSale[0].isAfter(start, 'day')) {
      setTimeSale([start, start])
      setInvest([start, end])
    } else {
      setTimeSale([timeSale[0], start])
      setInvest([start, end])
    }
  }

  return (
    <Spin size="large" spinning={mutationCreate.isLoading}>
      <Row>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item className="text-muted">Quản lý dự án bất động sản</Breadcrumb.Item>
          <Breadcrumb.Item className="text-black">Thêm mới dự án</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col span={24} className="mt-2 mb-3 text-header">
          Thêm mới dự án
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultData}
        autoComplete="off"
        scrollToFirs
        tError
        onFinish={onSubmit}
        onFinishFailed={(v) => {
          Modal.error({
            content: JSON.stringify(v),
          })
        }}
      >
        <div className="w-100">
          <h5 className="m-t-20">Thông tin chung</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Tên dự án"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
            >
              <Input placeholder="Nhập tên dự án" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Mã BĐS"
              name="no"
              rules={[{ required: true, message: 'Vui lòng nhập mã dự án!' }]}
            >
              <Input placeholder="Nhập mã dự án" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loại BĐS"
              name="classification"
              rules={[{ required: true, message: 'Vui lòng nhập kiểu BĐS!' }]}
            >
              <Select
                placeholder="Chọn loại BĐS"
                optionFilterProp="children"
              >
                {Object.keys(PROJECT_TYPE_NAMES).map((p) => (
                  <Select.Option value={p}>{PROJECT_TYPE_NAMES[p]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Kiểu BĐS"
              name="type"
            >
              <Select
                placeholder="Chọn kiểu BĐS"
                optionFilterProp="children"
              >
                {Object.keys(PROJECT_CATE_NAMES).map((p) => (
                  <Select.Option value={p}>{PROJECT_CATE_NAMES[p]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Giá trị(VND)"
              name={['stock_info', 'ido_price']}
              rules={[{ required: true, message: 'Vui lòng nhập Giá trị dự án!' }]}
            >
              <InputNumber placeholder="Nhập giá trị dự án" min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Số lượng cổ phần"
              name={['stock_info', 'total_supply']}
              rules={[{ required: true, message: 'Vui lòng nhập số lượng cổ phần!' }]}
            >
              <InputNumber placeholder="Nhập số lượng cổ phần" min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Lãi suất dự kiến(%)"
              name="expected_interest_rate"
              rules={[{ type: 'number', required: true, message: 'Vui lòng nhập lãi suất dự kiến!' }]}
            >
              <InputNumber
                placeholder="Nhập lãi suất dự kiến"
                min={0}
                max={100}
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <DatePicker.RangePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{
                hideDisabledOptions: true,
              }}
              style={{ width: '100%' }}
              value={timeSale}
              onChange={onChangeTimeSale}
            />
          </Col>
          <Col span={12}>
            <DatePicker.RangePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{
                hideDisabledOptions: true,
              }}
              style={{ width: '100%' }}
              value={timeInvest}
              onChange={onChangeTimeInvest}
            />
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Giới thiệu chung</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item name="general_doc">
              <RichText />
            </Form.Item>
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Vị trí dự án</h5>
        </div>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Tỉnh/Thành phố"
              name={['address', 'city']}
            >
              <Select
                optionFilterProp="children"
                placeholder="-- Chọn Tỉnh/Thành Phố --"
                value={province}
                onChange={onChangeProvince}
              >
                {dvhcvn.map((p) => (
                  <Select.Option value={p.value}>{p.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Quận/Huyện"
              name={['address', 'district']}
            >
              <Select
                optionFilterProp="children"
                placeholder="-- Chọn Quận/Huyện --"
                value={district}
                onChange={onChangeDistrict}
              >
                {districts.map((p) => (
                  <Select.Option value={p.value}>{p.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Phường/Xã" name={['address', 'line2']}>
              <Select
                optionFilterProp="children"
                placeholder="-- Chọn Phường/Xã --"
                value={commune}
                onChange={setCommune}
              >
                {communes.map((p) => (
                  <Select.Option value={p.value}>{p.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Địa chỉ cụ thể" name={['address', 'line1']}>
              <Input placeholder="Địa chỉ cụ thể" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={['address', 'note']}>
              <RichText />
            </Form.Item>
          </Col>
        </Row>
        <div className="w-100">
          <h5 className="m-t-20">Pháp lý</h5>
          <Row>
            <Col span={24}>
              <Form.Item name="legal_doc">
                <RichText />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="w-100">
          <h5 className="m-t-20">Thông tin tham khảo dự án</h5>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item name="ref_doc">
              <RichText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ref_links">
              <Links />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Mô tả ngắn"
              name="short_description"
              rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}
            >
              <Input placeholder="Vui lòng nhập" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item className="block text-center">
            <Button style={{ margin: '0 8px' }} onClick={history.goBack}>
              Quay lại
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="m-t-15"
              style={{ margin: '0 8px' }}
            >
              Lưu nháp
            </Button>
            {/* <Button
              type="primary"
              className="m-t-15"
              onClick={publishProject}
            >
              Đăng dự án
            </Button> */}
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  )
}

export default ProjectCreate
