import React, { useEffect, useState } from 'react'
import { ReactTinyLink } from 'react-tiny-link'
import './index.css'

const PreviewLink = ({ url, onDelete }) => (
  <div className="m-b-5">
    <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
    <div className="m-l-5 preview-link-delete" onClick={() => onDelete(url)} role="presentation">Delete</div>
  </div>
)

export default PreviewLink
