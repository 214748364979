import { useQuery } from 'react-query'
import investorApi from '../provider/investor'

export const useTransactionP2PList = (params = { page: 1, limit: 10 }, userId, statusTransaction) => useQuery({
  queryKey: ['getTransactionP2PList', params.page, params.limit, params.status],
  queryFn: () => investorApi.getListTransactionInvestorP2P(params, userId, statusTransaction),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (dataTransactionP2P) => dataTransactionP2P?.data,
})

export const useTransactionIdoList = (params = { page: 1, limit: 10 }, userId) => useQuery({
  queryKey: ['getTransactionIdoList', params.page, params.limit, params.status],
  queryFn: () => investorApi.getListTransactionInvestorIdo(params, userId),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (dataTransactionIdo) => dataTransactionIdo?.data,
})

export const useTransactionSwapList = (params = { page: 1, limit: 10 }, userId) => useQuery({
  queryKey: ['getTransactionSwapList', params.page, params.limit, params.status],
  queryFn: () => investorApi.getListTransactionInvestorSwap(params, userId),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (dataTransactionSwap) => dataTransactionSwap?.data,
})
