import axios from 'axios'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getList = async (param, rankingType, statusRanking) => {
  // eslint-disable-next-line no-debugger
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.RANKING_GET_LIST, { filter: { type: rankingType, status: statusRanking } }, {
    params: param,
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}

const getDetail = async (rankid) => {
  const { KEYS } = storage
  const resp = await axios.get(ENDPOINT.RANKING_GET_DETAIL, {
    params: {
      rank_id: rankid,
    },
    headers: {
      lang: 'vn',
      Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
    },
  })
  return resp.data
}
const createRanking = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.CREATE_RANKING, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}
const updateRanking = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.UPDATE_RANKING, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}
export default {
  getList,
  getDetail,
  createRanking,
  updateRanking,
}
