import { useMutation, useQuery } from 'react-query'
import investorApi from '../provider/investor'
import referralApi from '../provider/referral'

export const useReferralInvestorList = (params = { page: 1, limit: 10 }, userId, statusKyc, statusInvestor) => useQuery({
  queryKey: ['getReferralInvestorList', params.key_search, params.page, params.limit, statusKyc, statusInvestor],
  queryFn: () => investorApi.getListReferralInvestor(params, userId, statusKyc, statusInvestor),
  enabled: !!params.page && !!params.limit,
  initialData: [],
  select: (dataReferral) => dataReferral?.data,
})

export const useReferralList = ({
  page, limit, search, status, type,
}) => useQuery({
  queryKey: ['getReferralList', page, limit, search, status, type],
  queryFn: () => referralApi.getList(page, limit, search, status, type),
  enabled: !!page && !!limit,
  initialData: [],
  select: (dataReferralList) => dataReferralList?.data,
})

export const useReferralDetail = () => useQuery({
  queryKey: ['getReferralInfo'],
  queryFn: () => referralApi.getInfoReferral(),
  // enabled: !!,
  initialData: {},
  select: (dataReferralInfo) => dataReferralInfo?.data,
})

export const useConfigReferral = () => useMutation({
  mutationFn: referralApi.referralConfig,
  onError: (e) => {
    Modal.error({
      title: 'Lỗi: Không config được tỉ lệ referral',
      content: e?.message,
    })
  },
})
